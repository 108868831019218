import { Component, OnInit, Input } from "@angular/core";
import { NetworkService } from "src/app/services/network.service";
import { CscService } from "src/app/services/csc.service";
import { AppService } from "../../app.service";
import { AppConfigService, DomainConfig } from "src/app/app-config.service";

@Component({
  selector: "app-banners",
  templateUrl: "./banners.component.html",
  styleUrls: ["./banners.component.scss"],
  providers: [NetworkService, CscService],
})
export class BannersComponent implements OnInit {
  @Input("banners") banners: Array<any> = [];

  // @Input() categoryID;

  @Input() LocationResult;

  public currentConfig: DomainConfig;
  public URLpath;

  constructor(
    private appConfigService: AppConfigService,
    private PhapaNetwork: NetworkService,
    private csc: CscService,
    public appService: AppService
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
    this.URLpath = this.currentConfig.dev_url_prefix;
  }
  PhapaNetworkResult: any;
  getPhapaNetworksPrograms: any;
  orgID = null;
  OrganizationDetails: any;
  products: any;
  //  products: any;
  states: {};
  districts: {};
  taluks: {};
  areas: {};
  public count: any;

  isValid: boolean = false;
  // LocationSearch: FormGroup;

  SerachArea: any = {};

  public sidenavOpen: boolean = true;
  public viewType: string = "grid";
  public viewCol: number = 33.3;

  mainCategories;
  SetcategoryValue: number = 11111;

  ngOnInit() {
    this.products = this.banners;

    //  this.GetPhapaNetworks();
    //this.GetAllPrograms();
    //this.getAllProducts();
  }
  public ngDoCheck() {
    if (this.LocationResult != null) {
      this.products = this.banners;
    }

    // if(this.categoryID != this.SetcategoryValue ) {
    //   this.SetcategoryValue = this.categoryID;
    //   this.NetworkPrograms(this.categoryID);
    //  }
  }

  GetPhapaNetworks() {
    this.PhapaNetwork.getPhapaNetworks().subscribe((result) => {
      this.PhapaNetworkResult = result;
    });
  }

  GetAllPrograms() {
    this.PhapaNetwork.GetAllPrograms().subscribe((result) => {
      this.getPhapaNetworksPrograms = result;
    });
  }

  NetworkPrograms(id) {
    this.PhapaNetwork.NetworkUnderPrograms(id).subscribe((result) => {
      this.getPhapaNetworksPrograms = result;
      this.products = this.getPhapaNetworksPrograms;
    });
  }

  public getAllProducts() {
    this.PhapaNetwork.GetAllPrograms().subscribe((result) => {
      this.getPhapaNetworksPrograms = result;
      this.products = this.getPhapaNetworksPrograms;
    });
  }
}
