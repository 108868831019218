import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import {MatListModule} from '@angular/material/list';
import * as $ from 'jquery';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})
export class LandingpageComponent implements OnInit {
  // @Input('slides') slides: Array<any> = [];

  
 public MatListModule;
  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  public slides = [
    { title: 'Group Purchasing', subtitle: 'Special for today', image: 'assets/images/carousel/00.png' },
    { title: 'Govt Services', subtitle: 'New Arrivals On Sale', image: 'assets/images/carousel/01.png' },
    { title: 'Unorganized Construction workers', subtitle: 'Special for today', image: 'assets/images/carousel/02.png' },
    { title: 'Health Care', subtitle: 'New Arrivals On Sale', image: 'assets/images/carousel/03.png' },
    { title: 'Job Placements', subtitle: 'Special for today', image: 'assets/images/carousel/04.png' }
  ];
  
  
  constructor(MatListModule:MatListModule) { }
  public showShortDesciption;

  ngOnInit() { 
    $("#slideshow > div:gt(0)").hide();

setInterval(function() { 
  $('#slideshow > div:first')
    .fadeOut(1000)
    .next()
    .fadeIn(1000)
    .end()
    .appendTo('#slideshow');
},  3000);



  }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,     
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide"
    }
  }
  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption
 }
  

}