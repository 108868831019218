import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Globals } from "../globals";
import { AppConfigService, DomainConfig } from "../app-config.service";

export class Data {
  constructor(
    public wishList: any,
    public cartList: any,
    public totalPrice: number,
    public totalCartCount: number
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class ProductsAndServicesService {
  public Data = new Data(
    [], // wishList
    [], // cartList
    null, //totalPrice,
    0 //totalCartCount
  );

  public currentConfig: DomainConfig;

  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  public member_id = this.globals.MemberID;

  postOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
      // 'data':'form_data',
      "User-ID": this.globals.MemberID,
    }),
  };

  getProductsAndServices(id) {
    //console.log("In console md5id:"+id);
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/getProductsAndServices/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  getProgramsUnderOrg(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/getProgramsUnderOrg/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getBranchesUnderPRogramorOrg(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/getBranchesUnderOrg/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getProgramProductsAndServices(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/getProgramProductsAndServices/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //Add products to wishlist
  wishlistres: any;
  public addToWishList(product: any) {
    //console.log('In service');
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/addToWishlist",
        product,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // getWishListData (member_id) {

  //   return this.http.get(this.currentConfig.dev_url_prefix+'Api/v1/ProductsServices/getWishListData/'+member_id).pipe(map(result => {
  //     return result;
  //   }));
  // }

  //on 19th August
  removeWishlistItem(wishlistId) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/deleteWishlistItem/" +
          wishlistId
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //20th August
  clearWishlist(memobj) {
    // return this.http.get(this.currentConfig.dev_url_prefix+'Api/v1/ProductsServices/clearWishList/'+member_id).pipe(map(result => {
    //   return result;
    // }));
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/clearWishList",
        memobj
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //20th August
  memberCartResult: any;
  addTocart(cartSaveData) {
    // console.log(product);
    let message, status;

    this.Data.totalPrice = null;
    this.Data.totalCartCount = null;

    //   return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/ProductsServices/addToCart',product,this.httpOptions).pipe(map(result => {
    //     // return result;
    //     this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
    // }));

    if (
      this.Data.cartList.filter(
        (item) => item.program_org_product_id == cartSaveData.SOM_PRODUCT_ID
      )[0]
    ) {
      let item = this.Data.cartList.filter(
        (item) => item.program_org_product_id == cartSaveData.SOM_PRODUCT_ID
      )[0];
      item.cartCount = cartSaveData.SOM_QUANTITY;
    } else {
      let form_data = new FormData();
      for (let key in cartSaveData) {
        form_data.append(key, cartSaveData[key]);
      }

      return this.http
        .post(
          this.currentConfig.dev_url_prefix +
            "Api/v1/ProductsServices/addToCart",
          form_data,
          this.httpOptions
        )
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    // message = 'The product ' + product.product_name + ' has been added to cart.';
    // status = 'success';
    // this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
  }

  getMemberCartItems(member_id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/getMemberCartData/" +
          member_id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  removeCartItem(cart_id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/deleteCartItem/" +
          cart_id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  clearCartlist(memobj) {
    let form_data = new FormData();
    for (let key in memobj) {
      form_data.append(key, memobj[key]);
    }

    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/clearCartlist",
        memobj
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateCartQty(cartId) {
    return this.http
      .put(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/updateCartquantity/" +
          cartId,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  decreseCartQty(cartId) {
    return this.http
      .put(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/decreseCartQty/" +
          cartId,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getPurchaseSerDetails(memobj) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/getPurchaseService",
        memobj
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getMemProCartIterms(progobj) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/getMemProCartIterms",
        progobj
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //on 6th Nov '2020
  getMemberProgramWishlistItems(progobj) {
    let form_data = new FormData();
    for (let key in progobj) {
      form_data.append(key, progobj[key]);
    }
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ProductsServices/getMemberProgramWishlistItems",
        form_data
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getEventsUnderOrg(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Events/getEventsUnderOrg/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // public addToCart(product:any){
  //   let message, status;

  //   this.Data.totalPrice = null;
  //   this.Data.totalCartCount = null;

  //   if(this.Data.cartList.filter(item=>item.program_org_product_id == product.program_org_product_id)[0]){
  //       let item = this.Data.cartList.filter(item=>item.program_org_product_id == product.program_org_product_id)[0];
  //       item.cartCount = product.cartCount;
  //   }
  //   else{
  //       this.Data.cartList.push(product);
  //   }
  //   this.Data.cartList.forEach(product=>{
  //       this.Data.totalPrice = this.Data.totalPrice + (product.cartCount * product.Product_price);
  //       this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
  //   });

  //   message = 'The product ' + product.product_name + ' has been added to cart.';
  //   status = 'success';
  //   this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
  // }
}
