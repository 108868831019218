import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { CscService } from 'src/app/services/csc.service';
import { Globals } from 'src/app/globals';
import { InterestService } from 'src/app/services/interest.service';

@Component({
  selector: 'app-ah-interest',
  templateUrl: './ah-interest.component.html',
  styleUrls: ['./ah-interest.component.scss']
})
export class AhInterestComponent implements OnInit {

  @Input('member_id') member_id: any;

  constructor(public intService:InterestService, private csc: CscService,public formBuilder: FormBuilder, private globals: Globals,) { }
  housingForm: FormGroup;
  states: {};
  districts: {};
  taluks: {};
  areas: {};
  //public member_id = this.globals.MemberID;
 

  hformData = {
    GSP_SITE_TYPE:'',
    GSP_BUILD_TYPE:'',
    GSP_HOME_TYPE:'',
    GSP_STATE: '',
    GSP_DISTRICT: '',
    GSP_TALUK: '',
    GSP_AREA:'',
    GSP_MEMBER_INCOME: '',
    GSP_FAMILY_MEMBERS:'',
    GSP_PINCODE:'',
    GSP_PRICE_RANGE:'',
  }

  ngOnInit(): void {

   
     //search fiter
     this.housingForm = this.formBuilder.group({
      GSP_SITE_TYPE:[''],
      GSP_BUILD_TYPE:[''],
      GSP_HOME_TYPE:[''],
      GSP_STATE: [''],
      GSP_DISTRICT: [''],
      GSP_TALUK: [''],
      GSP_AREA: [''],
      GSP_MEMBER_INCOME: [''],
      GSP_FAMILY_MEMBERS:[''],
      GSP_PINCODE:[''],
      GSP_PRICE_RANGE:['']

    });

    this.getStates(); //search filter
    this.getIncomeMaster();
  }

  getStates() {
    this.csc.getALLStates().subscribe(result => {
      this.states = result;
    });
  }

  onChangeState(state) {
    if (state) {
      this.csc.getALLDistrict(state).subscribe(
        data => {
          this.districts = data;
          this.taluks = null;
          this.areas = null;
        }
      );
      //console.log(this.districts);
    } else {
      this.districts = null;
      this.taluks = null;
      this.areas = null;
    }
  }

  onChangeDistrict(district) {
    if (district) {
      this.csc.getALLTaluk(district).subscribe(
        data => {
          this.taluks = data;
          this.areas = null;
        }
      );
    } else {
      this.taluks = null;
      this.areas = null;
    }
  }

  onChangeTaluk(taluk) {
    if (taluk) {
      this.csc.getALLArea(taluk).subscribe(
        data => {
          this.areas = data;
        }
      );
    } else {
      this.areas = null;

    }
  }
public incomeData: any;
  getIncomeMaster () {
    this.intService.getIncomeMaster().subscribe(result => {
      this.incomeData = result;
      this.getAHPreferences();

    });
  }
  

  submitForm () {
   // console.log("form data",this.housingForm.value);

  let housingPreData =   {
    "GSP_MEMBER_ID":this.member_id,
    "GSP_SITE_TYPE":this.housingForm.value.GSP_SITE_TYPE,
    "GSP_HOME_TYPE":this.housingForm.value.GSP_HOME_TYPE,
    "GSP_BUILD_TYPE":this.housingForm.value.GSP_BUILD_TYPE,
    "GSP_FAMILY_MEMBERS":this.housingForm.value.GSP_FAMILY_MEMBERS,
    "GSP_PINCODE":this.housingForm.value.GSP_PINCODE,
    "GSP_AREA":this.housingForm.value.GSP_AREA,
    "GSP_TALUK":this.housingForm.value.GSP_TALUK,
    "GSP_DISTRICT":this.housingForm.value.GSP_DISTRICT,
    "GSP_STATE":this.housingForm.value.GSP_STATE,
    "GSP_COUNTRY":"INDIA",
    "GSP_MEMBER_INCOME":this.housingForm.value.GSP_MEMBER_INCOME,
    "GSP_PRICE_RANGE":this.housingForm.value.GSP_PRICE_RANGE,
    "GSP_MEMBER_OCCUPATION":"",
    "GSP_MEMBER_DOWN_PAYMENT":"",
    "GSP_MEMBER_EMPLOYMENT_TYPE":""
  }
   //pres.presponse=rpres;
  this.intService.saveHousingPreference(housingPreData).subscribe((result: any) => {
     let message = result['data'].presult;
     let strFirstThree = message.substring(0,3);
     if(strFirstThree=='OK-') {
       alert("Preference updated");
     }
     else {
       alert("Ooops, somthing went wrong!! Please try later");
     }

  
  });
}
public memberahPrefdata: any;
getAHPreferences () {

  this.member_id
  this.intService.getAHPreferences(this.member_id).subscribe(result => {
    // console.log("member preferences are:",result);
     this.memberahPrefdata = result[0];
     this.onChangeState(this.memberahPrefdata.gsp_state);
     this.onChangeDistrict(this.memberahPrefdata.gsp_district);
      this.onChangeTaluk(this.memberahPrefdata.gsp_taluk);
    
     this.hformData.GSP_SITE_TYPE=this.memberahPrefdata.gsp_site_type;
     this.hformData.GSP_BUILD_TYPE=this.memberahPrefdata.gsp_build_type;
     this.hformData.GSP_HOME_TYPE=this.memberahPrefdata.gsp_home_type;
     this.hformData.GSP_STATE=this.memberahPrefdata.gsp_state;
     this.hformData.GSP_DISTRICT=this.memberahPrefdata.gsp_district;
     this.hformData.GSP_TALUK=this.memberahPrefdata.gsp_taluk;
     this.hformData.GSP_AREA=this.memberahPrefdata.gsp_area;
     this.hformData.GSP_MEMBER_INCOME=this.memberahPrefdata.gsp_member_income;
     this.hformData.GSP_FAMILY_MEMBERS=this.memberahPrefdata.gsp_family_members;
     this.hformData.GSP_PINCODE=this.memberahPrefdata.gsp_pincode;
     this.hformData.GSP_PRICE_RANGE=this.memberahPrefdata.gsp_price_range;


   });

}


}
