import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true               
};

import { PipesModule } from '../theme/pipes/pipes.module';
import { RatingComponent } from './rating/rating.component';
import { ControlsComponent } from './controls/controls.component';
import { MainCarouselComponent } from './main-carousel/main-carousel.component';
import { BrandsCarouselComponent } from './brands-carousel/brands-carousel.component';
import { ProductsCarouselComponent } from './products-carousel/products-carousel.component';
import { ProductDialogComponent } from './products-carousel/product-dialog/product-dialog.component';
import { BannersComponent } from './banners/banners.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { PincodeComponent } from './pincode/pincode.component';

import { CookieService } from 'ngx-cookie-service';
import {Globals} from '../globals';
import { RazorpaymentComponent } from './razorpayment/razorpayment.component';
import { HomebannerComponent } from './homebanner/homebanner.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { GroupServiceInterestComponent } from './interests/group-service-interest/group-service-interest.component';
import { GroupPurchaseInterestComponent } from './interests/group-purchase-interest/group-purchase-interest.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

import { NewsCategoryListComponent } from './news-category-list/news-category-list.component';
import { NewsMoreComponent } from './news-more/news-more.component';
// import { ReferalnewComponent } from './referalnew/referalnew.component';
import { OtpDialogComponent } from './referalnew/otp-dialog/otp-dialog.component';
import { ProfileComponent } from './profile/profile.component';


import { HealthcareInterestComponent } from './interests/healthcare-interest/healthcare-interest.component';
import { AhInterestComponent } from './interests/ah-interest/ah-interest.component';
import { EducationInterestComponent } from './interests/education-interest/education-interest.component';
import { JobInterestComponent } from './interests/job-interest/job-interest.component';
import { SocialSecurityComponent } from './interests/social-security/social-security.component';
import { OtpVerifyIconComponent } from './otp-verify-icon/otp-verify-icon.component';
import { ServicesComponent } from './services/services.component';
import { ServiceApplyComponent } from './services/service-apply/service-apply.component';
import { MemberDependentsComponent } from './member-dependents/member-dependents.component';
import { EducationHistoryPopupComponent } from './member-dependents/education-history-popup/education-history-popup.component';
import { PrimaryOrgSelectComponent } from './primary-org-select/primary-org-select.component';
import { ServiceStepsDetailsComponent } from './service-steps-details/service-steps-details.component';
import { PaymentReportComponent } from './reports/payment-report/payment-report.component';
import { ServiceTransactionReportComponent } from './reports/service-transaction-report/service-transaction-report.component';
import { MembersReportComponent } from './reports/members-report/members-report.component';
import { CovidNetworkComponent } from './interests/covid-network/covid-network.component';



@NgModule({
  imports: [

  CommonModule,
    RouterModule,
    SwiperModule,
    FlexLayoutModule,
    // MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2SearchPipeModule,
    
  ],
  exports: [
    RouterModule,
    SwiperModule,
    FlexLayoutModule,
    // MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    RatingComponent,
    ControlsComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    ProductsCarouselComponent,
    ProductDialogComponent,
    BannersComponent,
    CategoryListComponent,
    PincodeComponent,
    HomebannerComponent,
    ReadMoreComponent,
    GroupServiceInterestComponent,
    GroupPurchaseInterestComponent,
    ConfirmDialogComponent,
    NewsCategoryListComponent,
    NewsMoreComponent,
    // ReferalnewComponent,
    ProfileComponent,
    OtpDialogComponent,
    HealthcareInterestComponent,
    AhInterestComponent,
    EducationInterestComponent,
    SocialSecurityComponent,
    OtpVerifyIconComponent,
    ServicesComponent,
    ServiceApplyComponent,
    JobInterestComponent,
    ServiceApplyComponent,   
    MemberDependentsComponent,
    PrimaryOrgSelectComponent,
    ServiceStepsDetailsComponent,
    PaymentReportComponent,
    ServiceTransactionReportComponent,
    MembersReportComponent,
    CovidNetworkComponent,

  ],
  declarations: [
    RatingComponent,
    ControlsComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    ProductsCarouselComponent,
    ProductDialogComponent,
    BannersComponent,
    CategoryListComponent,
    PincodeComponent,
    HomebannerComponent,
    RazorpaymentComponent,
    ReadMoreComponent,
    GroupServiceInterestComponent,
    GroupPurchaseInterestComponent,
    ConfirmDialogComponent,
    NewsCategoryListComponent,
    NewsMoreComponent,
    HealthcareInterestComponent,
    AhInterestComponent,
    EducationInterestComponent,
    SocialSecurityComponent,
    // ReferalnewComponent,
    ProfileComponent,
    OtpDialogComponent,
    JobInterestComponent,
    OtpVerifyIconComponent,
    ServicesComponent,
    ServiceApplyComponent,
    MemberDependentsComponent,
    EducationHistoryPopupComponent,
    PrimaryOrgSelectComponent,
    ServiceStepsDetailsComponent,
    PaymentReportComponent,
    ServiceTransactionReportComponent,
    MembersReportComponent,
    CovidNetworkComponent,

    
  ],
  entryComponents:[
    ProductDialogComponent,
    // ReferalnewComponent,
    ProfileComponent,
    OtpDialogComponent,
    OtpVerifyIconComponent,
    ServicesComponent,
    ServiceApplyComponent,
    PrimaryOrgSelectComponent,
    ServiceStepsDetailsComponent
  ],
  providers:[
    CookieService,Globals,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ]
})
export class SharedModule { }
