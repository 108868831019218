import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { MatDialog } from "@angular/material/dialog";
import { ProductDialogComponent } from "./product-dialog/product-dialog.component";
import { Data, AppService } from "../../app.service";
import { Product } from "../../app.models";
import { Settings, AppSettings } from "src/app/app.settings";
import { AppConfigService, DomainConfig } from "src/app/app-config.service";

@Component({
  selector: "app-products-carousel",
  templateUrl: "./products-carousel.component.html",
  styleUrls: ["./products-carousel.component.scss"],
})
export class ProductsCarouselComponent implements OnInit {
  @Input("products") products: Array<Product> = [];
  public currentConfig: DomainConfig;
  public URLpath;
  public config: SwiperConfigInterface = {};
  public settings: Settings;
  constructor(
    private appConfigService: AppConfigService,
    public appSettings: AppSettings,
    public appService: AppService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
    this.URLpath = this.currentConfig.dev_url_prefix;
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    // console.log(this.products);
  }
  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1,
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
        1500: {
          slidesPerView: 5,
        },
      },
    };
  }

  public openProductDialog(product) {
    let dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: "product-dialog",
      direction: this.settings.rtl ? "rtl" : "ltr",
    });
    dialogRef.afterClosed().subscribe((product) => {
      if (product) {
        this.router.navigate(["/products", product.id, product.name]);
      }
    });
  }
}
