import { Injectable, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";
import { Globals } from "../globals";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { throwError } from "rxjs";
import { AppConfigService, DomainConfig } from "../app-config.service";

export class Data {
  constructor(public partnerData: any) {}
}

@Injectable({
  providedIn: "root",
})
export class PartnerService {
  public Data = new Data(
    [] // partner Data
  );
  public currentConfig: DomainConfig;

  constructor(
    private appConfigServive: AppConfigService,
    private http: HttpClient,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigServive.currentConfig;
  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };
  postOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  deleteOptions = {
    headers: new HttpHeaders({
      //  'Host': '/',
      //'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  GetOptions = {
    headers: new HttpHeaders({
      Accept: "application/x-www-form-urlencoded",
      "User-ID": this.globals.MemberID,
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  postOptions1 = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  /**
   Get Assigned List
   * @returns {Observable<any>}
   */

  GetAssignedMember(page, data) {
    // return this.http.get(this.currentConfig.dev_url_prefix+'Api/v1/PartnerAction/getAssignedList/'+page,this.GetOptions).pipe(map(result => {
    //   return result;
    // }));

    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/PartnerAction/getAssignedListPost/" +
          page,
        data,
        this.postOptions1
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  GetMemberServiceDetails(id, StpID) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/PartnerAction/PartnerViewMemberDetails/" +
          id +
          "/" +
          StpID,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  GetMemberDocumentView(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/PartnerAction/PartnerMemberDocumentsViewer/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  uploadDocuments(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/PartnerAction/PartnerMemberDocumentsUpload",
        formData
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  PartnerServiceAction(formdata) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/PartnerAction/PartnerStepActionComplete",
        formdata,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  PartnerPaymentServiceAction(formdata) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/PartnerAction/PartnerPaymentStepActionComplete",
        formdata,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  private getEventMessage(event: HttpEvent<any>, formData) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
        break;
      case HttpEventType.Response:
        return this.apiResponse(event);
        break;
      default:
        return `File "${
          formData.get("AdharDocument").name
        }" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round((100 * event.loaded) / event.total);
    return { status: "progress", message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened. Please try again later.");
  }

  //on 4th Nov

  getOrdersReports(member_id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/PartnerAction/getPartnerOrdersReport/" +
          member_id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  downloadFile(data, filename = "data") {
    let csvData = this.ConvertToCSV(data, [
      "member_fullname",
      "member_mobile_no",
      "partner_name",
      "partner_associate_name",
      "parten_associate_mobile",
      "partner_name",
      "rcd_amt",
      "rcd_product_id",
      "product_name",
      "rcd_qty",
      "rcd_total_amt",
      "rch_order_date",
      "rch_payment_mode_desc",
      "rch_payment_status",
      "rcs_ship_to_name",
      "rcs_ship_mobile_no",
      "rcs_ship_address",
      "rcs_ship_country",
      "rcs_ship_state",
      "rcs_ship_city",
      "rcs_ship_taluk",
    ]);
    //console.log(csvData)
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "S.No,";

    for (let index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];

        line += "," + array[i][head];
      }
      str += line + "\r\n";
    }
    return str;
  }
}
