import { Component, OnInit, Input } from '@angular/core';
import { InterestService } from 'src/app/services/interest.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Globals } from 'src/app/globals';
@Component({
  selector: 'app-group-service-interest',
  templateUrl: './group-service-interest.component.html',
  styleUrls: ['./group-service-interest.component.scss']
})
export class GroupServiceInterestComponent implements OnInit {

  @Input('member_id') member_id: any;

 // public member_id= this.globals.MemberID;
  public serviceCategories: any;
  public memberServiceData: any; 
  public serviceInterests: any;
  public serviceUncheckedInt= [];



  form: FormGroup;
  constructor(public intService:InterestService,private formBuilder: FormBuilder,private globals: Globals) {
 
    this.form = this.formBuilder.group({
      checkArray: this.formBuilder.array([], [])
    })
  }
  ngOnInit(): void {

    this.getAllGroupServiceTypes();
  }

  ngOnDestroy() {
    
  }

  


  getAllGroupServiceTypes () {
    this.intService.getAllGroupServiceTypes().subscribe(result => {
      //console.log("GPSERVICE Result is:",result);
      this.serviceCategories= result;
     // console.log("GPSER Categories:",this.serviceCategories);
     this.getGroupServicePreferences();

    });
  }

  
  
  getGroupServicePreferences() {
    this.intService.getGroupServicePreferences(this.member_id).subscribe(result => {
      this.memberServiceData = result;
       console.log("member preferences are:",this.memberServiceData);

      this.getSelected();
    });

  }



  onCheckboxChange(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
  
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      this.serviceUncheckedInt.push(e.target.value);

      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }


  //public purArray = [];
  submitForm() {
   // console.log(this.form.value);
    this.serviceInterests =this.form.value.checkArray;
    //console.log(this.member_id);

    let mid= this.member_id;


  let  pdata = {
    "MEMBER_ID":mid,
    "CREATED_BY":mid,
    "SERVICE_TYPE_ID_ARY":this.serviceInterests,
    "UNCHECKED_ARY":this.serviceUncheckedInt,
  }

    //console.log("Interest Collection",this.purchaseInterests);

    this.intService.saveGServiceInterest(pdata).subscribe(result => {
      //console.log("Result is:",result);

      let message = result['data'].presult;
      let strFirstThree = message.substring(0,3);
      if(strFirstThree=='OK-') {
        alert("Preference updated");
      } 
      else {
        alert("Ooops, somthing went wrong!! Please try later");
      }
 
    });

  }

  getSelected() {
  
    for(let i=0;i<this.memberServiceData.length; i++){
      for(let j=0;j<this.serviceCategories.length;j++){
        if(this.serviceCategories[j].gss_service_type_id === this.memberServiceData[i].gsp_service_type_id) {
          this.serviceCategories[j].checked = true;
         // console.log("Matched: "+this.memberServiceData[i].gsp_service_type_id);
        }
      }
    }
  }

}
