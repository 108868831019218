import { Component, OnInit, Input } from '@angular/core';
import { InterestService } from 'src/app/services/interest.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Globals } from 'src/app/globals';



@Component({
  selector: 'healthcare-interest',
  templateUrl: './healthcare-interest.component.html',
  styleUrls: ['./healthcare-interest.component.scss']
})
export class HealthcareInterestComponent implements OnInit {
  @Input('member_id') member_id: any;

  HealthCareForm: FormGroup;
  post: any = '';

  
 // public member_id= this.globals.MemberID;

   public healthcareCategories :any;
   public healthcareInterests: any;
   public healthcareUncheckedInt = [];
   public memberPurchaseData:any;
   
  // CONTROLLER STARTS
  constructor(public intService:InterestService,private formBuilder: FormBuilder,private globals: Globals) {

    this.HealthCareForm = this.formBuilder.group({
      checkArray: this.formBuilder.array([], []),
      })

  } // CONTROLLER ENDS


  ngOnInit(): void {

    this.getHealthcareCatogories();
    

  }

  getHealthcareCatogories() {   // GET ALL HEALTHCARE CATEGORIES
    this.intService.getHealthcareCatogories().subscribe(result => {
      this.healthcareCategories= result;
     // console.log("Healthcare Ctgrs:",this.healthcareCategories);
      this.getHealthcarePreferences();
    });
  }

  getHealthcarePreferences() {   // GET ALL SELECTED HEALTHCARE CATEGORIES
    this.intService.getHealthcarePreferences(this.member_id).subscribe(result => {
     //console.log("Healthcare pref:",result);
      this.memberPurchaseData = result;
      this.getSelected();  // MAKE CHOOSEN HEALTHCARE INPUT CHECKED
    });
  }

  getSelected() {
    for(let i=0;i<this.memberPurchaseData.length; i++){
      for(let j=0;j<this.healthcareCategories.length;j++){
        if(this.healthcareCategories[j].hcm_id === this.memberPurchaseData[i].HCP_ID) {
          this.healthcareCategories[j].checked = true;
          // console.log("Matched: "+this.memberPurchaseData[i].HCP_ID);
        }
      }
    }
  }


  onCheckboxChange(e) {
    const checkArray: FormArray = this.HealthCareForm.get('checkArray') as FormArray;
   // const unCheckArray: FormArray = this.form.get('unCheckArray') as FormArray;
  
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
      
    } else {
      this.healthcareUncheckedInt.push(e.target.value);
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {     
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    // console.log(this.healthcareUncheckedInt)
    // console.log(checkArray)
  }


  submitForm() {
    this.healthcareInterests =this.HealthCareForm.value.checkArray;

    let mid= this.member_id;
    let  pdata = {
      "MEMBER_ID":mid,
      "CREATED_BY":mid,
      "healthcare_checked_array":this.healthcareInterests,
      "healthcare_unchecked_array":this.healthcareUncheckedInt,
    }
   // console.log(pdata)
    // return;
    this.intService.saveHealthcareInterest(pdata).subscribe(result => {
     let message = result['data'].presult;
     let strFirstThree = message.substring(0,3);
     if(strFirstThree=='OK-') {
       alert("Preference updated");
     }
     else {
       alert("Ooops, somthing went wrong!! Please try later");
     }

    });

  }

  




}