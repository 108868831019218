import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrasactionService } from 'src/app/admin/ApiServices/trasaction.service';

@Component({
  selector: 'app-service-steps-details',
  templateUrl: './service-steps-details.component.html',
  styleUrls: ['./service-steps-details.component.scss']
})
export class ServiceStepsDetailsComponent implements OnInit {

  public orders = [
    { number: '#3258', date: 'March 29, 2018', status: 'Completed', total: '$140.00 for 2 items', invoice: true },
    { number: '#3145', date: 'February 14, 2018', status: 'On hold', total: '$255.99 for 1 item', invoice: false },
    { number: '#2972', date: 'January 7, 2018', status: 'Processing', total: '$255.99 for 1 item', invoice: true },
    { number: '#2971', date: 'January 5, 2018', status: 'Completed', total: '$73.00 for 1 item', invoice: true },
    { number: '#1981', date: 'December 24, 2017', status: 'Pending Payment', total: '$285.00 for 2 items', invoice: false },
    { number: '#1781', date: 'September 3, 2017', status: 'Refunded', total: '$49.00 for 2 items', invoice: false }
  ]
  steps: [];
  constructor(public dialogRef: MatDialogRef<ServiceStepsDetailsComponent>,  @Inject(MAT_DIALOG_DATA) public data: any,private transaction:TrasactionService) { 
    this.data.data;
  }

  ngOnInit(): void {

    this.getTransactionStepsStatus(this.data.data.txn_id);

  }

  getTransactionStepsStatus(id){
    
    const formData = new FormData();
    formData.append('txn_id',id);
    this.transaction.get_Transaction_Steps_Status(formData).subscribe((result: any) => {
      this.steps = result.data;

        });

  }


}
