import { Injectable, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";
import { Globals } from "../globals";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { throwError } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  public currentConfig: DomainConfig;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private globals: Globals,
    private spinner: NgxSpinnerService
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  // member profile headers

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };
  postOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      //'User-ID' : '100001',
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  deleteOptions = {
    headers: new HttpHeaders({
      //  'Host': '/',
      //'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  GetOptions = {
    headers: new HttpHeaders({
      Accept: "application/x-www-form-urlencoded",
      "User-ID": this.globals.MemberID,
      //'User-ID' : '100001',
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  OrganiationPostGetList(page, type) {
    this.spinner.hide();
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/Member_Related_Organization_Posts/" +
          page +
          "/" +
          type,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  OrganiationPostGetListSingleProgram(page, prgID) {
    this.spinner.hide();
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/Member_Related_Organization_Posts_PerticularProgram/" +
          page +
          "/" +
          prgID,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  upload(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/MemberDocumentsUpload/" +
          this.globals.MemberID,
        formData,
        {
          reportProgress: true,
          observe: "events",
        }
      )
      .pipe(
        map((event) => this.getEventMessage(event, formData)),
        catchError(this.handleError)
      );
  }

  // uploadDocuments(formData) {

  //   return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/Products_and_services_cntrl/postSelectedDocumentsData/'+this.globals.MemberID, formData,  {
  //     reportProgress: true,
  //     observe: 'events'
  //   }).pipe(
  //     map(event => this.getEventMessage(event, formData)),
  //     catchError(this.handleError)
  //   );
  // }

  // uploadDocuments(formData) {

  //   return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/Products_and_services_cntrl/postSelectedDocumentsData/'+this.postOptions, formData,  {
  //     reportProgress: true,
  //     observe: 'events'
  //   }).pipe(
  //     map(event => this.getEventMessage(event, formData,)),
  //     catchError(this.handleError)
  //   );
  // }

  //   /**
  //   member registartion form post
  //  * @param formData - post data
  //  * @returns {Observable<any>}
  //  */

  // uploadDocuments(formData){
  //   let docValue = JSON.stringify(formData);
  //   console.log(docValue);
  //   debugger;

  //   return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/Products_and_services_cntrl/postSelectedDocumentsData', formData, this.postOptions).pipe(map(token => {
  //     return token;
  //   }));
  // }

  uploadDocuments(formData) {
    // let form_data = new FormData();
    // for ( let key in formData ) {
    //   form_data.append(key, formData[key]);
    // }

    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Products_and_services_cntrl/postSelectedDocumentsData/" +
          this.globals.MemberID,
        formData
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  private getEventMessage(event: HttpEvent<any>, formData) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
        break;
      case HttpEventType.Response:
        return this.apiResponse(event);
        break;
      default:
        return `File "${
          formData.get("AdharDocument").name
        }" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round((100 * event.loaded) / event.total);
    return { status: "progress", message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened. Please try again later.");
  }
}
