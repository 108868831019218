import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Input,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { SwiperConfigInterface, SwiperDirective } from "ngx-swiper-wrapper";
import { Data, AppService } from "../../../app.service";
import { Product } from "../../../app.models";
import { emailValidator } from "../../../theme/utils/app-validators";
import { NetworkService } from "src/app/services/network.service";
import { ApplyserviceService } from "src/app/services/applyservice.service";
import { Globals } from "src/app/globals";
import { PartnerService } from "src/app/services/partner.service";
import { AppSettings, Settings } from "src/app/app.settings";
import { ExternalLibraryService } from "src/app/util";
import { PaymentService } from "src/app/services/payment.service";
import { OrganisationsService } from "src/app/services/organisations.service";
import { DocumentViewerDialogComponent } from "src/app/pages/account/partner/document-viewer-dialog/document-viewer-dialog.component";
import { AppConfigService, DomainConfig } from "src/app/app-config.service";

declare let Razorpay: any; //on 19th Nov
@Component({
  selector: "app-service-apply",
  templateUrl: "./service-apply.component.html",
  styleUrls: ["./service-apply.component.scss"],
})
export class ServiceApplyComponent implements OnInit {
  @ViewChild("zoomViewer", { static: true }) zoomViewer;
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;

  @Input("member_id") member_id: any;

  // console.log('mem' + member_id );

  public currentConfig: DomainConfig;
  private RAZORPAY_OPTIONS;
  public config: SwiperConfigInterface = {};
  public product: Product;
  public image: any;
  public zoomImage: any;
  private sub: any;
  public form: FormGroup;
  public relatedProducts: Array<Product>;
  public service: any;

  public URLpath;

  //on 11th july
  public country: string;
  public state: string;
  public district: string;
  public taluk: string;
  public area: string;
  public pincode: any;
  public age: any;
  public community: any;
  public category: any;
  public ration_card_type: any;
  public occupation_group: any;
  public occupation: any;
  public income: any;

  public Gender: string;

  public settings: Settings;

  constructor(
    public appConfigService: AppConfigService,
    public appService: AppService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    private PhapaNetwork: NetworkService,
    private router: Router,
    private globals: Globals,
    public applyser: ApplyserviceService,
    private partnerService: PartnerService,
    public appSettings: AppSettings,
    private razorpayService: ExternalLibraryService,
    private cd: ChangeDetectorRef,
    public payService: PaymentService,
    private orgservices: OrganisationsService
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
    this.URLpath = this.currentConfig.dev_url_prefix;
    this.settings = this.appSettings.settings;
    this.RAZORPAY_OPTIONS = {
      key: this.currentConfig.rpayAuthKey,
      amount: "",
      name: this.currentConfig.rpayOrganizationName,
      order_id: "",
      description: "Service Apply",
      currency_code: "INR",
      netbanking: true,
      // "image": "https://livestatic.novopay.in/resources/img/nodeapp/img/Logo_NP.jpg",
      prefill: {
        name: "",
        email: this.currentConfig.rpayOrganizationEmail,
        contact: "",
        method: "",
      },
      modal: {},
      theme: {
        color: "#0096C5",
      },
    };
  }

  //may 28th
  public isLoggedIn = this.globals.enable;
  // public member_id = this.globals.MemberID;

  //on 4th jan '2020
  joinedproduct: any;
  public jnproduct: any;

  ngOnInit() {
    this.OtherForm = this.formBuilder.group({
      OtherDocument: ["", Validators.required],
    });

    this.sub = this.activatedRoute.params.subscribe((params) => {
      this.getServiceDetails(params["sid"], params["orgid"]);
      // this.OrganizationDetials(params['orgid']);

      if (this.globals.enable == "true") {
        this.checkAppliedorNot(params["sid"], params["orgid"]);
        this.checkJoindorNot(params["orgid"]);
      }

      this.getServiceDocumenst(params["sid"]);
    });

    this.form = this.formBuilder.group({
      review: [null, Validators.required],
      name: [
        null,
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
      email: [null, Validators.compose([Validators.required, emailValidator])],
    });

    this.razorpayService
      .lazyLoadLibrary("https://checkout.razorpay.com/v1/checkout.js")
      .subscribe();
  }

  ngAfterViewInit() {
    this.config = {
      observer: false,
      slidesPerView: 4,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 3,
        },
      },
    };
  }

  newproduct: any;
  joinedService: any;
  productImges: any;
  public jnServcie: any;
  Allimages: any;
  public defaultImages: any;
  programPath: any;
  programMobilePath: any;
  EmptyImages = [
    {
      small: "./assets/no-image.jpg",
      medium: "./assets/no-image.jpg",
      big: "./assets/no-image.jpg",
    },
    {
      small: "./assets/no-image.jpg",
      medium: "./assets/no-image.jpg",
      big: "./assets/no-image.jpg",
    },
    {
      small: "./assets/no-image.jpg",
      medium: "./assets/no-image.jpg",
      big: "./assets/no-image.jpg",
    },
  ];

  public sodata: any;
  public getServiceDetails(sid, orgID) {
    this.sodata = {
      organization_idfk: orgID,
      Service_ID: sid,
    };
    // console.log(sid);
    this.applyser.GetServiceDetials(this.sodata).subscribe((data: any) => {
      this.newproduct = data;
      this.service = Object.assign({}, ...this.newproduct.data);
      // console.log("SER DET",this.service);
      //console.log(this.service.orgLogo);

      //on 11th july
      this.country = this.service.AOO_Country;
      this.state = this.service.AOO_State;
      this.district = this.service.AOO_District;
      this.taluk = this.service.AOO_Taluk;
      this.area = this.service.AOO_area;
      this.pincode = this.service.AOO_pincode;
      this.age = this.service.age;
      this.community = this.service.community_name;
      this.ration_card_type = this.service.ration_card_type;
      this.category = this.service.category_name;
      this.occupation_group = this.service.member_group;
      this.occupation = this.service.occupation_name;
      this.income = this.service.income;
      this.Gender = this.service.Gender;

      if (this.service.orgLogo != "") {
        this.image = this.URLpath + this.service.orgLogo;
        this.zoomImage = this.URLpath + this.service.orgLogo;
      } else {
        this.image = "./assets/no-image.jpg";
        this.zoomImage = "./assets/no-image.jpg";
        this.defaultImages = this.EmptyImages;
      }
      //console.log('image path: '+this.image);
      setTimeout(() => {
        this.config.observer = true;
        // this.directiveRef.setIndex(0);
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public onSubmit(values: Object): void {
    if (this.form.valid) {
      //email sent
    }
  }

  public jdata: any; //12th
  public Joindresupt: any; //12th
  //on 28th May '2020
  public applyService(org_id, service_id, status) {
    // console.log(org_id,service_id,status);
    //console.log(status);

    //Object.keys(this.jnproduct).length!=0
    if (this.isLoggedIn == "true") {
      if (
        Object.keys(this.jnproduct).length != 0 &&
        (this.jnproduct.process_status == "active" ||
          this.jnproduct.process_status == "Active")
      ) {
        if (status == "active") {
          if (confirm("Are you sure, Do you want to apply?")) {
            this.jdata = {
              organization_idfk: org_id,
              Service_ID: service_id,
              iconMember_id: this.member_id,
              syn_status: 0,
              comments: "Servcie applied by member",
            };

            //console.log("In before call"+this.jdata);
            this.applyser
              .IconHelpapplyOrgServices(this.jdata)
              .subscribe((result: any) => {
                this.Joindresupt = result.data;
                // console.log("After Calls",this.Joindresupt);
                // console.log('After Response');
                if (this.Joindresupt.status == 200) {
                  //alert(this.Joindresupt.message);
                  if (this.service.service_fee != 0) {
                    if (
                      confirm(
                        "Applied Successfully!!! Do you want to continue payment?"
                      )
                    ) {
                      this.proceed();
                    } else {
                      this.ngOnInit();
                    }
                  } else {
                    alert("Thank you, Applied Successfully!!!");
                    this.ngOnInit();
                  }
                  //this.OrganizationDetials(mdorgid);
                  // this.ngOnInit();
                } else {
                  alert("Something went wrong please try later!!!");
                }
              });
          }
        } else {
          alert("Service is not yet activated!! Please wait few more days.");
        }
      } else {
        alert("You are not a member of this organisation.");
      }
    } else {
      alert("Please login to apply");
    }
  }
  //28th may '2020
  public sdata: any;
  public checkAppliedorNot(sid, org_ID) {
    this.sdata = {
      organization_idfk: org_ID,
      Service_ID: sid,
      IconMemberID: this.member_id,
    };

    this.applyser.IconcheckAppliedorNot(this.sdata).subscribe((result: any) => {
      this.joinedService = result;
      this.jnServcie = Object.assign({}, ...this.joinedService.data);
    });
  }

  //on 12th july guna
  public MemberDocumentsCheck: any;
  public serviceDocs: any;
  public getServiceDocumenst(sid) {
    const formData = new FormData();

    formData.append("service_id", sid);
    formData.append("IconsMemberID", this.member_id);

    this.applyser.IcongetServiceDocument(formData).subscribe((result: any) => {
      this.serviceDocs = result;
      // console.log(this.serviceDocs);
    });
  }

  OtherForm: FormGroup;
  error: string;
  NA: string = "NA";
  onSelectedOtherDocument(event, DocID) {
    const formData = new FormData();
    formData.append("DocumentID", DocID);
    formData.append("Document", event.target.files[0]);
    formData.append("Member_Id", this.member_id);

    //formData.append('TransactionID', this.TXnID);
    // console.log( formData );
    this.applyser.uploadServiceDocuments(formData).subscribe(
      (res: any) => {},
      (err) => (this.error = err)
    );
    this.ngOnInit();
  }

  public imagePath: any;
  DocumentView(id) {
    this.partnerService.GetMemberDocumentView(id).subscribe((result: any) => {
      this.imagePath = result.filePath;

      let dialogRef = this.dialog.open(DocumentViewerDialogComponent, {
        data: this.imagePath,
        panelClass: "product-dialog",
        width: "800px",
        height: "500px",
        direction: this.settings.rtl ? "rtl" : "ltr",
      });
      dialogRef.afterClosed().subscribe((product) => {
        if (product) {
          //this.router.navigate(['/products', product.id, product.name]);
        }
      });
    });
  }

  //on 19th Nov Guna 2020
  name = "Phapa";
  response;
  razorpayResponse;
  showModal = false;
  
  public usrTpe: any;
  public proceed() {
    this.usrTpe = "member";
    // console.log("MOBILE:"+this.globals.mob);
    this.RAZORPAY_OPTIONS.amount = this.service.service_fee + "00";
    //this.RAZORPAY_OPTIONS.description = this.product.org_id;
    this.RAZORPAY_OPTIONS.prefill.contact = this.globals.mob;
    // this.showPopup();
    this.RAZORPAY_OPTIONS["handler"] = this.razorPaySuccessHandler.bind(this);
    let razorpay = new Razorpay(this.RAZORPAY_OPTIONS);
    razorpay.open();
    // razorpay.on('payment.success', function(resp) {
    //   console.log(JSON.stringify(resp));
    //   alert(resp.razorpay_payment_id),
    //   alert(resp.razorpay_order_id),
    //   alert(resp.razorpay_signature)
    //  // binding this object to both success and dismiss handler
    // });
    razorpay.on("payment.error", function (resp) {
      // console.log(JSON.stringify(resp));
      // this.razorpayResponse = resp;
      alert(resp.error.description);
    }); // will pass error object to error handler
  }

  public razorPaySuccessHandler(response) {
    //  console.log(response);
    this.razorpayResponse = response;
    this.showModal = true;
    this.cd.detectChanges();
    this.savepaymentSuccessResponse(response);
    // document.getElementById('razorpay-response').style.display = 'block';
  }
  paymentRes: any;
  serPayRes: any;
  public savepaymentSuccessResponse(pres) {
    //console.log(  "Presponse is",pres)
    pres.Member_id = this.member_id;
    pres.org_id = this.service.organization_idfk;
    pres.amount = this.service.service_fee;
    pres.status = "success";
    pres.userType = this.usrTpe;
    //pres.sponsorID = this.SponserID;
    //pres.presponse=rpres;

    let orgPaymentData = {
      Member_id: this.member_id,
      org_id: this.service.organization_idfk,
      amount: this.service.service_fee,
      status: "success",
      userType: this.usrTpe,
      sponsor_id: "",
      payment_id: pres.razorpay_payment_id,
      payment_source: "service_payment",
      transaction_id: this.Joindresupt.TransactionID,
    };

    // let serPaymentData = {
    //   "transaction_id": this.Joindresupt.TransactionID,
    //   "payment_id": pres.razorpay_payment_id,
    //   "error_description": "Only on success",
    //   "status": "Success",
    //   "acknowledgement": "acknowledgement"
    // }

    let serPaymentData = {
      transaction_id: this.Joindresupt.TransactionID,
      payment_id: pres.razorpay_payment_id,
      org_id: this.service.organization_idfk,
      amount: this.service.service_fee,
      org_mem_idfk: this.member_id,
      error_description: "Only on success",
      payment_source: "service_payment",
      status: "Success",
      user_id: this.member_id,
      acknowledgement: "acknowledgement",
    };

    this.payService
      .saveServicePaymentResponse(serPaymentData)
      .subscribe((result: any) => {
        this.serPayRes = result;
        alert(this.serPayRes.message);

        this.ngOnInit();
      });

    // this.payService.savepaymentResponse(orgPaymentData).subscribe((result: any) => {
    //   this.paymentRes = result;
    //   // console.log(this.paymentRes);
    //   alert(this.paymentRes.message);
    //   //  this.OrganizationDetials(pres.org_id);
    //   this.ngOnInit();
    //   //this.jnproduct = Object.assign({}, ...this.joinedproduct.data);
    // });
  }

  //on 4th Jan '2020 //guna
  checkJoindorNot(id) {
    const formData = new FormData();
    formData.append("Icon_member_id", this.member_id);
    formData.append("org_id", id);

    this.orgservices.IconcheckJoindorNot(formData).subscribe((result: any) => {
      this.joinedproduct = result;
      this.jnproduct = Object.assign({}, ...this.joinedproduct.data);
    });
  }
}
