import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AppConfigService, DomainConfig } from "src/app/app-config.service";
import { Globals } from "src/app/globals";
import { PaymentService } from "src/app/services/payment.service";
import { ExternalLibraryService } from "src/app/util";

declare let Razorpay: any;
@Component({
  selector: "app-razorpayment",
  templateUrl: "./razorpayment.component.html",
  styleUrls: ["./razorpayment.component.scss"],
})
export class RazorpaymentComponent implements OnInit {
  //on 25th
  public currentConfig: DomainConfig;
  private RAZORPAY_OPTIONS
  public isLoggedIn = this.globals.enable;
  public member_id = this.globals.MemberID;
  public Mobile_Number = this.globals.mob;

  constructor(
    private appConfigService: AppConfigService,
    private globals: Globals,
    private cd: ChangeDetectorRef,
    private payService: PaymentService,
    private razorpayService: ExternalLibraryService
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
    this.RAZORPAY_OPTIONS = {
      key: this.currentConfig.rpayAuthKey,
      amount: "",
      name: this.currentConfig.rpayOrganizationName,
      order_id: "",
      description: "Organisation Joining",
      currency_code: "INR",
      netbanking: true,
      // "image": "https://livestatic.novopay.in/resources/img/nodeapp/img/Logo_NP.jpg",
      prefill: {
        name: "",
        email: this.currentConfig.rpayOrganizationEmail,
        contact: "",
        method: "",
      },
      modal: {},
      theme: {
        color: "#0096C5",
      },
    };
  }

  ngOnInit(): void {
    //payment
    this.razorpayService
      .lazyLoadLibrary("https://checkout.razorpay.com/v1/checkout.js")
      .subscribe();
  }

  //on 30th May Guna Payment gateway
  name = "Phapa";
  response;
  razorpayResponse;
  showModal = false;
  
  public usrTpe: any;
  public proceed() {
    this.usrTpe = "member";
    // console.log("MOBILE:"+this.globals.mob);
    this.RAZORPAY_OPTIONS.amount = 100 + "00";
    //this.RAZORPAY_OPTIONS.description = this.product.org_id;
    this.RAZORPAY_OPTIONS.prefill.contact = this.globals.mob;
    // this.showPopup();
    this.RAZORPAY_OPTIONS["handler"] = this.razorPaySuccessHandler.bind(this);
    let razorpay = new Razorpay(this.RAZORPAY_OPTIONS);
    razorpay.open();
    // razorpay.on('payment.success', function(resp) {
    //   console.log(JSON.stringify(resp));
    //   alert(resp.razorpay_payment_id),
    //   alert(resp.razorpay_order_id),
    //   alert(resp.razorpay_signature)
    //  // binding this object to both success and dismiss handler
    // });
    razorpay.on("payment.error", function (resp) {
      // console.log(JSON.stringify(resp));
      // this.razorpayResponse = resp;
      alert(resp.error.description);
    }); // will pass error object to error handler
  }

  public razorPaySuccessHandler(response) {
    //  console.log(response);
    this.razorpayResponse = response;
    this.showModal = true;
    this.cd.detectChanges();
    this.savepaymentSuccessResponse(response);
    // document.getElementById('razorpay-response').style.display = 'block';
  }
  paymentRes: any;
  public savepaymentSuccessResponse(pres) {
    pres.Member_id = this.member_id;
    pres.org_id = 1111;
    //pres.amount = this.grandTotal;
    pres.amount = 100;
    pres.status = "success";
    pres.userType = this.usrTpe;
    pres.sponsorID = 2;
    //pres.presponse=rpres;
    this.payService.savepaymentResponse(pres).subscribe((result: any) => {
      this.paymentRes = result;
      // console.log(this.paymentRes);
      alert(this.paymentRes.message);
      //  this.OrganizationDetials(pres.org_id);
      this.ngOnInit();
      //this.jnproduct = Object.assign({}, ...this.joinedproduct.data);
    });
  }
}
