import { Component, OnInit, Input, EventEmitter } from "@angular/core";
import { BrowserModule, DomSanitizer } from "@angular/platform-browser";
import { AppConfigService, DomainConfig } from "src/app/app-config.service";

@Component({
  selector: "app-news-more",
  templateUrl: "./news-more.component.html",
  styleUrls: ["./news-more.component.scss"],
})
export class NewsMoreComponent implements OnInit {
  @Input() content: any;
  @Input() limit: number;
  @Input() completeWords: boolean;

  public currentConfig:DomainConfig;
  public URLpath;

  isContentToggled: boolean;
  nonEditedContent: string;

  constructor(
    private appConfigService: AppConfigService,
    private sanitizer: DomSanitizer
  ) {
    this.currentConfig=this.appConfigService.currentConfig;
    this.URLpath=this.currentConfig.dev_url_prefix; 
  }

  ngOnInit() {
    this.nonEditedContent = this.content;
    this.content = this.formatContent(this.content);
  }

  toggleContent() {
    this.isContentToggled = !this.isContentToggled;
    this.content = this.isContentToggled
      ? this.nonEditedContent
      : this.formatContent(this.content);
  }

  formatContent(content: string) {
    if (this.completeWords) {
      this.limit = content.substr(0, this.limit).lastIndexOf(" ");
    }

    if (this.limit > 150) {
      return `${content.substr(0, this.limit)}...`;
    } else {
      this.isContentToggled = true;
      return `${content.substr(0, this.limit)}`;
    }
  }
}
