import { Component, OnInit, Input } from '@angular/core';
import { InterestService } from 'src/app/services/interest.service';
import { Globals } from 'src/app/globals';
import { FormBuilder } from '@angular/forms';
import { MastersService } from 'src/app/services/masters.service';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings, Settings } from 'src/app/app.settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EducationHistoryPopupComponent } from './education-history-popup/education-history-popup.component';

@Component({
  selector: 'app-member-dependents',
  templateUrl: './member-dependents.component.html',
  styleUrls: ['./member-dependents.component.scss']
})
export class MemberDependentsComponent implements OnInit {
  @Input('member_id') member_id: any;
  constructor(public intService: InterestService,private formBuilder: FormBuilder, private globals: Globals, private masterlist: MastersService, public dialog: MatDialog, public appSettings: AppSettings, public snackBar: MatSnackBar,) { }
 public memberDependents: any;
 public isLoggedIn = this.globals.enable;
 public settings: Settings;
  ngOnInit(): void {
    this.getMemberStudentDependents();
  }

  getMemberStudentDependents () {
    this.intService.getMemberStudentDependents(this.member_id).subscribe(result => {
      
      this.memberDependents = result['dependents'];
    });


  }


  openEducationPref(memId,DepId) {
    if (this.isLoggedIn != 'true') {
      this.snackBar.open('Please login!', '×', { panelClass: 'success', verticalPosition: 'top', horizontalPosition: 'end', duration: 4000 });
    }
    else {

      let dialogRef = this.dialog.open(EducationHistoryPopupComponent, {
        data: {memberid:memId,dependent_id:DepId},
        panelClass: 'product-dialog',
       // direction: (this.settings.rtl) ? 'rtl' : 'ltr'
      });
      dialogRef.afterClosed().subscribe(Sponresult => {
      });
    }
  }

}
