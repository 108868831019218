import { Component, OnInit } from "@angular/core";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Component({
  selector: "app-new-landing",
  templateUrl: "./new-landing.component.html",
  styleUrls: ["./new-landing.component.scss"],
})
export class NewLandingComponent implements OnInit {
  public currentConfig: DomainConfig;
  constructor(private appConfigService: AppConfigService) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  ngOnInit(): void {
    console.log("landing appConfig access", this.appConfigService.appConfig);
  }
}
