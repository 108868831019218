import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";

import { ReferralService } from "src/app/services/referral.service";
import { MastersService } from "src/app/services/masters.service";
import { NgForm, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { Location } from "@angular/common";
import { MatSnackBar } from "@angular/material/snack-bar";

import { HttpClient } from "@angular/common/http";
import { CscService } from "src/app/services/csc.service";
import { AppService } from "src/app/app.service";
import { InterestService } from "src/app/services/interest.service";
import { ProfileService } from "src/app/services/profile.service";
import { AppConfigService, DomainConfig } from "src/app/app-config.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  providers: [CscService, ReferralService, MastersService, ProfileService],
})
export class ProfileComponent implements OnInit {
  @ViewChild("horizontalStepper", { static: true })
  horizontalStepper: MatStepper;
  @ViewChild("verticalStepper", { static: true }) verticalStepper: MatStepper;

  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  @ViewChild("inputFile") myInputVariable: ElementRef;

  public currentConfig: DomainConfig;
  isCheckedBoolean: boolean = false;
  public URLpath;
  BasicForm: FormGroup;
  Documents: FormGroup;
  Eligibility: FormGroup;
  // Dependents: FormGroup;
  BankInfo: FormGroup;
  selDocType: any;
  ischecked: boolean = true;
  checkallTrades: boolean = true;

  checked = false;
  public document_type_id: any;
  MemberDocumentsdocuments: [];
  MemberDocumentsGroup: [];

  //on 16th may
  countries = [];
  months = [];
  years = [];
  deliveryMethods = [];
  grandTotal = 0;
  gender = [];
  DocType = [];
  communitys = [];
  memtypes = [];
  relationdepents = [];
  OccupationGroup = [];
  OccupationGroupID: any;
  occupationList: [];
  categoryList: [];
  maritalStatus: [];
  occupationsubTypeList = [];
  OccupationTypeID: any;
  private sub: any;
  public member_ID: any;
  public tempi = 0;
  startDate = new Date(2003, 0, 1);
  maxDate = new Date(2003, 0, 1);

  ReferalMember = {
    member_fullname: null,
    member_mobile_no: "",
    LastName: "",
    member_dob: "",
    gender: "",
    alternate_phone_no: "",
    pincode: "",
    country: "",
    state: "",
    district: "",
    taluk: "",
    area: "",
    local_address: "",
    occupationGroup: "",
    occupation: "",
    occupation_subtype: "",
    motherTongue: "",
    member_type: "",
    income: "",
    community: "",
    caste: "",
    ration_card_type: "",
    bank_name: "",
    account_no: "",
    ifsc_code: "",
    bank_address: "",
    maritalStatus: "",
    education: "",
    member_class_grade: "",
    p_pincode: "",
    p_country: "",
    p_state: "",
    p_district: "",
    p_taluk: "",
    p_area: "",
    p_local_address: "",
    isCheckedBoolean: "",
  };

  DependentsEdit = {
    id: null,
    name: "",
    dob: "",
    gender: "",
    education: "",
    class_grade: "",
    memberType: "",
    relationship: "",
    marital_status: "",
    dep_occupation: "",
  };

  InsurenceEdit = {
    SOM_INSURANCE_TYPE: "",
    SOM_INURANCE_NO: "",
    SOM_INSURANCE_START_DT: "",
    SOM_INSURANCE_END_DT: "",
    SOM_INSURANCE_HOLDER_NAME: "",
    SOM_INSURANCE_DESC: "",
    SOM_VEHICLE_TYPE: "",
    SOM_VEHICLE_NO: "",
    SOM_VEHICLE_MODEL: "",
  };

  // educationInfo = [
  //   { id: '1', ename: 'Primary School' },
  //   { id: '2', ename: 'Secondary School' },
  //   { id: '3', ename: 'Higher Secondary' },
  //   { id: '4', ename: 'Graduate' },
  //   { id: '5', ename: 'Post Graduate' },
  //   { id: '6', ename: 'IlLiterate' },
  // ];
  educationInfo = [];
  educationMoreInfo = [];

  startAt: any;
  areas = [];
  stepDisabled: boolean = true;

  public viewType: string = "grid";
  public nrSelect = 20;
  public viewCol: number = 25;

  DependentForm: FormGroup;
  InsurenceForm: FormGroup;
  selectedDocumentsForm: FormGroup;
  rows: FormArray;
  Insurance: FormArray;
  documents: any;
  MemberDocuments: [];
  p_areas: any;

  public HideDetails: boolean = false;

  constructor(
    private appConfigSerive: AppConfigService,
    public appService: AppService,
    public snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    private csc: CscService,
    private ReferralService: ReferralService,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private masterlist: MastersService,
    private httpClient: HttpClient,
    public intService: InterestService,
    private ProfileService: ProfileService,
    private router:Router
  ) {
    this.currentConfig = this.appConfigSerive.currentConfig;
    this.URLpath = this.currentConfig.dev_url_prefix;
  }

  ngOnInit() {
    this.selectedDocumentsForm = this.formBuilder.group({
      DOCUMENT_TYPE_IDFK: "",
      DOCUMENT_AVAILABLE: "",
      DOCUMENT_TYPE_NAME: "",
      DOCUMENT_CORRECTION_REQUIRED: "",
      DOCUMENTS: "",
    });

    this.sub = this.activatedRoute.params.subscribe((params) => {
      this.member_ID = params["id"];
      this.GetReferalMemberDetails(params["id"]);
    });

    this.appService.Data.cartList.forEach((product) => {
      this.grandTotal += product.cartCount * product.newPrice;
    });

    this.countries = this.appService.getCountries();
    this.months = this.appService.getMonths();
    this.years = this.appService.getYears();
    this.deliveryMethods = this.appService.getDeliveryMethods();

    this.BasicForm = this.formBuilder.group({
      member_fullname: ["", Validators.required],
      member_dob: "",
      LastName: "",
      gender: [""],
      alternate_phone_no: "",
      pincode: ["", Validators.required],
      country: ["", Validators.required],
      state: ["", Validators.required],
      district: ["", Validators.required],
      taluk: ["", Validators.required],
      area: "",
      local_address: "",
      p_pincode: "",
      p_country: "",
      p_state: "",
      p_district: "",
      p_taluk: "",
      p_area: "",
      p_local_address: "",
      isCheckedBoolean: "",
    });

    this.BankInfo = this.formBuilder.group({
      bank_name: "",
      account_no: "",
      ifsc_code: "",
      bank_address: "",
    });

    this.Eligibility = this.formBuilder.group({
      occupationGroup: ["", Validators.required],
      occupation: ["", Validators.required],
      occupation_subtype: [""],
      member_type: ["", Validators.required],
      income: "",
      caste: [""],
      ration_card_type: "",
      maritalStatus: "",
      education: "",
      member_class_grade: "",
      motherTongue: "",
    });

    this.AdharForm = this.formBuilder.group({
      DocumentID: ["20"],
      AdharDocument: ["", Validators.required],
    });

    this.RationForm = this.formBuilder.group({
      DocumentID: ["24"],
      RationDocument: ["", Validators.required],
    });

    this.BankForm = this.formBuilder.group({
      DocumentID: ["25"],
      BankDocument: ["", Validators.required],
    });
    this.ProfileForm = this.formBuilder.group({
      DocumentID: ["28"],
      ProfileDocument: ["", Validators.required],
    });

    this.OtherForm = this.formBuilder.group({
      DocumentID: ["", Validators.required],
      OtherDocument: ["", Validators.required],
      DocumentNumber: ["", Validators.required],
    });

    this.getGender();
    this.getSpecificDocType();
    this.getCommunitys();
    this.GetOccupationGroup();
    this.getDependentRelation();
    this.getMemberTypes();
    this.getCategoryMasterList();
    this.getMaritalStatusList();
    this.DocumentsList();
    this.EducationList();
    this.getMemberDocuments();
    this.selectedDocumentsList();
    this.getAllMemberDocumentsGroup();

    this.DependentForm = this.formBuilder.group({
      id: "",
      name: ["", Validators.required],
      gender: ["", Validators.required],
      dob: [""],
      memberType: [""],
      education: "",
      class_grade: "",
      relationship: "",
      maritalStatus: "",
      dep_occupation: "",
    });

    this.InsurenceForm = this.formBuilder.group({
      SOM_INSURANCE_TYPE: ["", Validators.required],
      SOM_INURANCE_NO: ["", Validators.required],
      SOM_INSURANCE_START_DT: [""],
      SOM_INSURANCE_END_DT: [""],
      SOM_INSURANCE_HOLDER_NAME: "",
      SOM_INSURANCE_DESC: "",
      SOM_VEHICLE_TYPE: [""],
      SOM_VEHICLE_NO: "",
      SOM_VEHICLE_MODEL: "",
    });

    // DEpendents

    this.rows = this.formBuilder.array([]);

    this.Insurance = this.formBuilder.array([]);

    this.DependentForm.addControl("rows", this.rows);

    this.InsurenceForm.addControl("Insurance", this.Insurance);

    this.ReferralService.GetReferalMemberDetails(this.member_ID).subscribe(
      (result: any) => {
        if (result.dependents.dependents != "No Dependents") {
          result.dependents.forEach((element) => {
            this.DependentsEdit.id = element.dependent_id;
            this.DependentsEdit.name = element.dependent_name;
            this.DependentsEdit.dob = element.dependent_dob;
            this.DependentsEdit.gender = element.dependent_sex;
            this.DependentsEdit.education = element.dependent_education;

            if (this.tempi == 0) {
              this.intService
                .getEducationMoreinfo(this.DependentsEdit.education)
                .subscribe((result: any) => {
                  this["a" + 0] = result;
                });
            }

            if (this.tempi == 1) {
              this.intService
                .getEducationMoreinfo(this.DependentsEdit.education)
                .subscribe((result: any) => {
                  this["a" + 1] = result;
                });
            }

            if (this.tempi == 2) {
              this.intService
                .getEducationMoreinfo(this.DependentsEdit.education)
                .subscribe((result: any) => {
                  this["a" + 2] = result;
                });
            }
            if (this.tempi == 3) {
              this.intService
                .getEducationMoreinfo(this.DependentsEdit.education)
                .subscribe((result: any) => {
                  this["a" + 3] = result;
                });
            }
            if (this.tempi == 4) {
              this.intService
                .getEducationMoreinfo(this.DependentsEdit.education)
                .subscribe((result: any) => {
                  this["a" + 4] = result;
                });
            }
            if (this.tempi == 5) {
              this.intService
                .getEducationMoreinfo(this.DependentsEdit.education)
                .subscribe((result: any) => {
                  this["a" + 5] = result;
                });
            }
            if (this.tempi == 6) {
              this.intService
                .getEducationMoreinfo(this.DependentsEdit.education)
                .subscribe((result: any) => {
                  this["a" + 6] = result;
                });
            }
            if (this.tempi == 7) {
              this.intService
                .getEducationMoreinfo(this.DependentsEdit.education)
                .subscribe((result: any) => {
                  this["a" + 7] = result;
                });
            }
            if (this.tempi == 8) {
              this.intService
                .getEducationMoreinfo(this.DependentsEdit.education)
                .subscribe((result: any) => {
                  this["a" + 8] = result;
                });
            }
            if (this.tempi == 9) {
              this.intService
                .getEducationMoreinfo(this.DependentsEdit.education)
                .subscribe((result: any) => {
                  this["a" + 9] = result;
                });
            }

            this.DependentsEdit.class_grade = element.dependent_education_info;
            this.DependentsEdit.memberType = element.dependent_membertype;
            this.DependentsEdit.dep_occupation = element.dependent_occupation;
            this.DependentsEdit.relationship = element.dependent_relation;
            this.DependentsEdit.marital_status =
              element.dependent_marital_status;
            this.rows.push(this.createItemFormGroup());

            this.tempi++;
          });
        }

        // Insurance Details Edit start
        if (result.insurence.Insurence != "Not Found.") {
          result.insurence.forEach((element) => {
            this.InsurenceEdit.SOM_INSURANCE_TYPE = element.SOM_INSURANCE_TYPE;
            this.InsurenceEdit.SOM_INURANCE_NO = element.SOM_INURANCE_NO;
            this.InsurenceEdit.SOM_INSURANCE_START_DT =
              element.SOM_INSURANCE_START_DT;
            this.InsurenceEdit.SOM_INSURANCE_END_DT =
              element.SOM_INSURANCE_END_DT;
            this.InsurenceEdit.SOM_INSURANCE_HOLDER_NAME =
              element.SOM_INSURANCE_HOLDER_NAME;
            this.InsurenceEdit.SOM_INSURANCE_DESC = element.SOM_INSURANCE_DESC;
            this.InsurenceEdit.SOM_VEHICLE_TYPE = element.SOM_VEHICLE_TYPE;
            this.InsurenceEdit.SOM_VEHICLE_NO = element.SOM_VEHICLE_NO;
            this.InsurenceEdit.SOM_VEHICLE_MODEL = element.SOM_VEHICLE_MODEL;

            this.Insurance.push(this.createItemFormGroupInsurence());
          });
        }
        // END
      }
    );

    // END
  }

  public basic: any;
  public bank: any;
  public eligibile: any;

  InsurenceType = [
    { id: "Vehicle", name: "Vehicle Insurance" },
    { id: "Health", name: "Health Insurance" },
  ];

  VehicleType = [
    { id: "2 wheeler", name: "2 wheeler" },
    { id: "3 wheeler", name: "3 wheeler" },
    { id: "4 wheeler", name: "4 wheeler" },
    { id: "6 wheeler", name: "6 wheeler" },
  ];

  VehicleModel = [
    { id: "1981", name: "1981" },
    { id: "1982", name: "1982" },
    { id: "1983", name: "1983" },
    { id: "1984", name: "1984" },
    { id: "1985", name: "1985" },
    { id: "1986", name: "1986" },
    { id: "1987", name: "1987" },
    { id: "1988", name: "1988" },
    { id: "1989", name: "1989" },
    { id: "1990", name: "1990" },
    { id: "1991", name: "1991" },
    { id: "1992", name: "1992" },
    { id: "1993", name: "1993" },
    { id: "1994", name: "1994" },
    { id: "1995", name: "1995" },
    { id: "1996", name: "1996" },
    { id: "1997", name: "1997" },
    { id: "1999", name: "1998" },
    { id: "2000", name: "2000" },
    { id: "2001", name: "2001" },
    { id: "2002", name: "2002" },
    { id: "2003", name: "2003" },
    { id: "2004", name: "2004" },
    { id: "2005", name: "2005" },
    { id: "2006", name: "2006" },
    { id: "2007", name: "2007" },
    { id: "2008", name: "2008" },
    { id: "2009", name: "2009" },
    { id: "2010", name: "2010" },
    { id: "2011", name: "2011" },
    { id: "2012", name: "2012" },
    { id: "2013", name: "2013" },
    { id: "2014", name: "2014" },
    { id: "2015", name: "2015" },
    { id: "2016", name: "2016" },
    { id: "2017", name: "2017" },
    { id: "2018", name: "2018" },
    { id: "2019", name: "2019" },
    { id: "2020", name: "2020" },
    { id: "2021", name: "2021" },
  ];

  rationType = [
    { id: "1", name: "BPL" },
    { id: "2", name: "APL" },
  ];

  AdharForm: FormGroup;
  RationForm: FormGroup;
  BankForm: FormGroup;
  OtherForm: FormGroup;
  ProfileForm: FormGroup;
  error: string;

  AdharUpload = { status: "", message: "", filePath: "" };
  RationUpload = { status: "", message: "", filePath: "" };
  BankUpload = { status: "", message: "", filePath: "" };
  ProfileUpload = { status: "", message: "", filePath: "" };
  OtherUpload = { status: "", message: "", filePath: "" };

  onChangeMemberEducation(educationId) {
    this.intService
      .getEducationMoreinfo(educationId)
      .subscribe((result: any) => {
        this.educationMoreInfo = result;
      });
  }

  basicSubmit() {
    this.basic = this.BasicForm.value;

    if (this.BasicForm.valid) {
      this.ReferralService.ReferalMemberProfileUpdate(
        this.basic,
        this.member_ID
      ).subscribe((result: any) => {
        if(result['status'] == '200'){
          this.router.navigate(['./phapa/account/dashboard']);
        } else {
          alert('Failed! try again')
        }

      });
    }
  }

  dependentsSubmit() {
    this.ReferralService.ReferalMemberDependentsUpdate(
      this.rows.value,
      this.member_ID
    ).subscribe((result: any) => {});
  }

  insurenceSubmit() {
    this.ReferralService.ReferalMemberInsurenceUpdate(
      this.Insurance.value,
      this.member_ID
    ).subscribe((result: any) => {});
  }

  BankSubmit() {
    this.bank = this.BankInfo.value;
    if (this.BankInfo.valid) {
      this.ReferralService.ReferalMemberBankUpdate(
        this.bank,
        this.member_ID
      ).subscribe((result: any) => {}); // $aa = $this->db->last_query();		// $aa = $this->db->last_query();

      // print_r($aa);
      // exit();

      // print_r($aa);
      // exit();
    }
  }

  EligibilitySubmit() {
    this.eligibile = this.Eligibility.value;
    if (this.Eligibility.valid) {
      this.ReferralService.ReferalMemberProfileUpdateEligbility(
        this.eligibile,
        this.member_ID
      ).subscribe((result: any) => {});
    }
  }

  public Submit() {
    // End
    this.horizontalStepper._steps.forEach((step) => (step.editable = false));
    this.verticalStepper._steps.forEach((step) => (step.editable = false));
    // this.appService.Data.cartList.length = 0;
    // this.appService.Data.totalPrice = 0;
    // this.appService.Data.totalCartCount = 0;h
  }

  onChangeEducation(educationId, index) {
    this.intService
      .getEducationMoreinfo(educationId)
      .subscribe((result: any) => {
        this["a" + index] = result;
        // this.getSelected();
      });
  }

  getGender() {
    this.csc.getAllGender().subscribe((result: any) => {
      this.gender = result;
    });
  }
  // get only specific documets
  getSpecificDocType() {
    this.csc.getSpecificDocType().subscribe((result: any) => {
      this.DocType = result;
    });
  }

  // get all community Details
  getCommunitys() {
    this.csc.getAllCommunity().subscribe((result: any) => {
      this.communitys = result;
    });
  }

  getMemberTypes() {
    this.csc.getAllMemberTypes().subscribe((result: any) => {
      this.memtypes = result.data;
    });
  }

  GetOccupationGroup() {
    this.ReferralService.OccupationGroup().subscribe((result: any) => {
      this.OccupationGroup = result.data;
    });
  }
  getDependentRelation() {
    this.csc.getAllRelationalDependents().subscribe((result: any) => {
      this.relationdepents = result;
    });
  }

  ChangeoccupationGroup(orgGroup) {
    if (orgGroup.value) {
      this.OccupationGroupID = orgGroup.value;
      this.ReferralService.getOccupationList(this.OccupationGroupID).subscribe(
        (data: any) => {
          this.occupationList = data.data;
        }
      );
    }
  }

  Changeoccupation(occupationType) {
    if (occupationType.value) {
      this.OccupationTypeID = occupationType.value;
      this.ReferralService.getOccupationSubList(
        this.OccupationTypeID
      ).subscribe((data: any) => {
        this.occupationsubTypeList = data.data;
      });
    }
  }
  EDITChangeoccupation(id) {
    this.ReferralService.getOccupationSubList(id).subscribe((data: any) => {
      this.occupationsubTypeList = data.data;
    });
  }

  getCategoryMasterList() {
    this.ReferralService.getCategoryMasterList().subscribe((result: any) => {
      this.categoryList = result.data;
    });
  }

  getMaritalStatusList() {
    this.ReferralService.getMaritalMasterList().subscribe((result: any) => {
      this.maritalStatus = result.data;
    });
  }

  EducationList() {
    this.masterlist.EducationList().subscribe((result: any) => {
      this.educationInfo = result.data;
    });
  }

  GetReferalMemberDetails(id) {
    this.ReferralService.GetReferalMemberDetails(id).subscribe(
      (result: any) => {
        this.ReferalMember.member_fullname = result.data.member_fullname;

        this.ReferalMember.member_mobile_no = result.data.member_mobile_no;

        this.ReferalMember.LastName = result.data.member_last_name;
        // if(result.data.member_dob == '' || result.data.member_dob == null){
        //   this.ReferalMember.member_dob = '.';
        // }else{
        this.ReferalMember.member_dob = result.data.member_dob;
        // }

        this.ReferalMember.gender = result.data.member_sex;
        this.ReferalMember.alternate_phone_no = result.data.alternative_Number;
        this.ReferalMember.pincode = result.data.member_local_add_pincode;
        this.ReferalMember.country = result.data.country;
        this.ReferalMember.state = result.data.reg_state;
        this.ReferalMember.district = result.data.member_local_add_distric;
        this.ReferalMember.taluk = result.data.member_local_add_taluk;
        this.onChangePincodeTrigger(this.ReferalMember.pincode);
        this.ReferalMember.area = result.data.area_name;
        this.ReferalMember.local_address = result.data.member_local_add;
        this.ReferalMember.occupationGroup = result.data.occupation_group_id;
        this.EDITChangeoccupationGroup(this.ReferalMember.occupationGroup);
        this.ReferalMember.occupation = result.data.member_occupation;
        this.EDITChangeoccupation(this.ReferalMember.occupation);
        this.ReferalMember.occupation_subtype = result.data.occupation_subtype;
        this.ReferalMember.member_type = result.data.memTypeIDFK;
        this.ReferalMember.income = result.data.member_income_id;
        this.ReferalMember.community = result.data.community_idfk;
        this.ReferalMember.caste = result.data.cast_idfk;
        this.ReferalMember.ration_card_type = result.data.ration_card_type;
        this.ReferalMember.bank_name = result.data.bank_name;
        this.ReferalMember.account_no = result.data.accountno;
        this.ReferalMember.ifsc_code = result.data.bank_ifsc_code;
        this.ReferalMember.bank_address = result.data.bank_address;
        this.ReferalMember.maritalStatus = result.data.member_marital_status;
        this.ReferalMember.education = result.data.member_education_level;
        this.onChangeMemberEducation(this.ReferalMember.education);
        this.ReferalMember.member_class_grade =
          result.data.member_eduLevelMoreInfo;
        this.ReferalMember.motherTongue = result.data.member_mothertongue;

        this.ReferalMember.p_pincode = result.data.member_pemnt_add_pincode;
        this.ReferalMember.p_country = result.data.country;
        this.ReferalMember.p_state = result.data.member_pemnt_add_state;
        this.ReferalMember.p_district = result.data.member_pemnt_add_district;
        this.ReferalMember.p_taluk = result.data.member_pemnt_add_taluk;
        this.onPincodeValidate(this.ReferalMember.p_pincode);
        this.ReferalMember.p_area = result.data.parmanent_area_name;

        this.ReferalMember.p_local_address =
          result.data.member_permanent_address;

        //this.startAt = new Date(this.ReferalMember.member_dob);

        // Dependents foreach

        if (result.documents.documents != "No Docuemets") {
          this.MemberDocuments = result.documents;
          result.documents.forEach((element) => {
            if (element.document_id == 20) {
              this.AdharUpload.status = "success";
              this.AdharUpload.filePath = element.filePath;
            }

            if (element.document_id == 24) {
              this.RationUpload.status = "success";
              this.RationUpload.filePath = element.filePath;
            }
            if (element.document_id == 25) {
              this.BankUpload.status = "success";
              this.BankUpload.filePath = element.filePath;
            }
            if (element.document_id == 28) {
              this.ProfileUpload.status = "success";
              this.ProfileUpload.filePath = element.filePath;
            }
          });
          //console.log(this.MemberDocuments);
        }
      }
    );
  }

  EDITChangeoccupationGroup(id) {
    this.ReferralService.getOccupationList(id).subscribe((data: any) => {
      this.occupationList = data.data;
    });
  }

  onChangePincodeTrigger(val) {
    if (val != "") {
      this.csc.PincodeValidate(val).subscribe((result: any) => {
        this.areas = result.area;
      });
    }
  }

  onChangeTalukTriggerPermanent(taluk) {
    if (taluk) {
      this.csc.getALLArea(taluk).subscribe((data: any) => {
        this.p_areas = data;
      });
    } else {
      this.p_areas = null;
    }
  }

  onSelectedFileAdhar(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.AdharForm.get("AdharDocument").setValue(file);
    }
  }

  onSelectedFileRation(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.RationForm.get("RationDocument").setValue(file);
    }
  }
  onSelectedFileBank(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.BankForm.get("BankDocument").setValue(file);
    }
  }
  onSelectedFileProfile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.ProfileForm.get("ProfileDocument").setValue(file);
    }
  }

  onSelectedOtherDocument(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.OtherForm.get("OtherDocument").setValue(file);
    }
  }

  onSubmit(type) {
    const formData = new FormData();

    if (type == "Adhar") {
      formData.append("DocumentID", this.AdharForm.get("DocumentID").value);
      formData.append("MemberID", this.member_ID);
      formData.append(
        "AdharDocument",
        this.AdharForm.get("AdharDocument").value
      );
    } else if (type == "Ration") {
      formData.append("DocumentID", this.RationForm.get("DocumentID").value);
      formData.append("MemberID", this.member_ID);
      formData.append(
        "AdharDocument",
        this.RationForm.get("RationDocument").value
      );
    } else if (type == "Bank") {
      formData.append("DocumentID", this.BankForm.get("DocumentID").value);
      formData.append("MemberID", this.member_ID);
      formData.append("AdharDocument", this.BankForm.get("BankDocument").value);
    } else {
      formData.append("DocumentID", this.ProfileForm.get("DocumentID").value);
      formData.append("MemberID", this.member_ID);
      formData.append(
        "AdharDocument",
        this.ProfileForm.get("ProfileDocument").value
      );
    }

    this.ReferralService.upload(formData).subscribe(
      (res: any) => {
        if (type == "Adhar") {
          this.AdharUpload = res;
        } else if (type == "Ration") {
          this.RationUpload = res;
        } else if (type == "Bank") {
          this.BankUpload = res;
        } else {
          this.ProfileUpload = res;
        }
      },
      (err) => (this.error = err)
    );
  }

  onOtherDocumentSubmit(values: NgForm, formDirective: FormGroupDirective) {
    const formData = new FormData();
    if (this.OtherForm.valid) {
      formData.append("DocumentID", this.OtherForm.get("DocumentID").value);
      formData.append("MemberID", this.member_ID);
      formData.append(
        "AdharDocument",
        this.OtherForm.get("OtherDocument").value
      );
      formData.append(
        "DocumentNumber",
        this.OtherForm.get("DocumentNumber").value
      );

      this.ReferralService.upload(formData).subscribe(
        (res: any) => {
          this.OtherUpload = res;
          this.snackBar.open("Document uploaded Successfully!", "×", {
            panelClass: "success",
            verticalPosition: "top",
            horizontalPosition: "end",
            duration: 3000,
          });

          this.ReferralService.GetReferalMemberDocuments(
            this.member_ID
          ).subscribe((result: any) => {
            this.MemberDocuments = result.documents;
            this.OtherForm.reset();
            this.myInputVariable.nativeElement.value = "";

            formDirective.resetForm();
          });
        },
        (err) => (this.error = err)
      );
    }
  }

  onAddRow() {
    if (this.rows.length >= 10) {
      alert("You can enter maximum 10 rows of history only");
      return false;
    }

    this.DependentsEdit.id = "";
    this.DependentsEdit.name = "";
    this.DependentsEdit.gender = "";
    this.DependentsEdit.dob = "";
    this.DependentsEdit.memberType = "";
    this.DependentsEdit.education = "";
    this.DependentsEdit.class_grade = "";
    this.DependentsEdit.relationship = "";
    this.DependentsEdit.marital_status = "";
    this.DependentsEdit.dep_occupation = "";

    this.rows.push(this.createItemFormGroup());
  }

  onAddRowInsurence() {
    this.InsurenceEdit.SOM_INSURANCE_TYPE = "";
    this.InsurenceEdit.SOM_INURANCE_NO = "";
    this.InsurenceEdit.SOM_INSURANCE_START_DT = "";
    this.InsurenceEdit.SOM_INSURANCE_END_DT = "";
    this.InsurenceEdit.SOM_INSURANCE_HOLDER_NAME = "";
    this.InsurenceEdit.SOM_INSURANCE_DESC = "";
    this.InsurenceEdit.SOM_VEHICLE_TYPE = "";
    this.InsurenceEdit.SOM_VEHICLE_NO = "";
    this.InsurenceEdit.SOM_VEHICLE_MODEL = "";
    this.Insurance.push(this.createItemFormGroupInsurence());
  }

  onRemoveRow(rowIndex: number, dependentID) {
    if (dependentID.value != "") {
      this.ReferralService.GetReferalMemberDetailsDelete(
        dependentID.value
      ).subscribe((result: any) => {});
    }

    this.rows.removeAt(rowIndex);
  }

  onRemoveRowInsurence(rowIndex: number, InsurenceNumber) {
    const formData = new FormData();
    formData.append("InsurenceNumber", InsurenceNumber.value);
    formData.append("id", this.member_ID);

    if (InsurenceNumber.value != "") {
      this.ReferralService.DeleteInsurenceMember(formData).subscribe(
        (result: any) => {}
      );
    }
    this.Insurance.removeAt(rowIndex);
  }

  createItemFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: this.DependentsEdit.id,
      name: [this.DependentsEdit.name, Validators.required],
      gender: [this.DependentsEdit.gender, Validators.required],
      dob: [this.DependentsEdit.dob],
      // aadhar: this.DependentsEdit.aadhar,
      memberType: [this.DependentsEdit.memberType],
      education: this.DependentsEdit.education,
      class_grade: this.DependentsEdit.class_grade,
      relationship: this.DependentsEdit.relationship,
      marital_status: this.DependentsEdit.marital_status,
      dep_occupation: this.DependentsEdit.dep_occupation,
    });
  }

  createItemFormGroupInsurence(): FormGroup {
    return this.formBuilder.group({
      SOM_INSURANCE_TYPE: [
        this.InsurenceEdit.SOM_INSURANCE_TYPE,
        Validators.required,
      ],
      SOM_INURANCE_NO: [
        this.InsurenceEdit.SOM_INURANCE_NO,
        Validators.required,
      ],
      SOM_INSURANCE_START_DT: [this.InsurenceEdit.SOM_INSURANCE_START_DT],
      SOM_INSURANCE_END_DT: [this.InsurenceEdit.SOM_INSURANCE_END_DT],
      SOM_INSURANCE_HOLDER_NAME: [this.InsurenceEdit.SOM_INSURANCE_HOLDER_NAME],
      SOM_INSURANCE_DESC: this.InsurenceEdit.SOM_INSURANCE_DESC,
      SOM_VEHICLE_TYPE: this.InsurenceEdit.SOM_VEHICLE_TYPE,
      SOM_VEHICLE_NO: this.InsurenceEdit.SOM_VEHICLE_NO,
      SOM_VEHICLE_MODEL: this.InsurenceEdit.SOM_VEHICLE_MODEL,
    });
  }

  //to go back by guna
  backClicked() {
    this._location.back();
  }
  //17th May
  charsSpecialCharRestrictions(event) {
    var regExp = /[a-z-=+_.;!@#$%^&*()~|}{}"'/,<>`\[\]\s]/i;
    var value = String.fromCharCode(event.which) || event.key;
    if (regExp.test(value)) {
      event.preventDefault();
      return false;
    }
  }
  // prevent special characters
  textFieldNumAndSpecialCharRestrictions(event) {
    //  var k;
    //  k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    //  return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    var regExp = /[0-9-=+_;!@#$%^&*()~|}{}"'/,<>`\[\]]/i;
    var value = String.fromCharCode(event.which) || event.key;
    if (regExp.test(value)) {
      event.preventDefault();
      return false;
    }
  }

  numFieldSpecialCharRestrictions(event) {
    var regExp = /[a-z-=+_.;!@#$%^&*()~|}{}"'/,<>`\[\]\s]/i;
    var value = String.fromCharCode(event.which) || event.key;
    if (regExp.test(value)) {
      event.preventDefault();
      return false;
    }
  }
  SpecialCharRestrictions(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
  onIndivusalSelectedOtherDocument(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.selectedDocumentsForm.get("DOCUMENTS").setValue(file);
    }
  }

  DocumentsList() {
    this.csc.DocumentsList().subscribe((result: any) => {
      this.documents = result;
    });
  }
  selectedDocumentsList() {
    this.csc.selectedDocumentsList().subscribe((result: any) => {
      this.selDocType = result;
    });
  }
  singleCheckbox(event) {
    if (event.target.checked == true) {
      this.ischecked = true;
    } else {
      if (this.ischecked && this.checkallTrades) {
        event.target.checked = true;
      }
    }
  }

  getMemberDocuments() {
    this.csc.getAllMemberDocuments().subscribe((result: any) => {
      this.MemberDocumentsdocuments = result.documents;
    });
  }
  getAllMemberDocumentsGroup() {
    this.csc.getAllMemberDocumentsGroup().subscribe((result: any) => {
      this.MemberDocumentsGroup = result;
    });
  }

  SameAsAbove() {
    if (!this.isCheckedBoolean) {
      this.p_areas = null;
      this.ReferalMember.p_pincode = this.ReferalMember.pincode;
      this.ReferalMember.p_country = this.ReferalMember.country;
      this.ReferalMember.p_state = this.ReferalMember.state;
      this.ReferalMember.p_district = this.ReferalMember.district;
      this.ReferalMember.p_taluk = this.ReferalMember.taluk;
      this.ReferalMember.p_area = this.ReferalMember.area;
      this.ReferalMember.p_local_address = this.ReferalMember.local_address;

      this.p_areas = this.areas;
    } else {
      this.ReferalMember.p_pincode = "";
      this.ReferalMember.p_country = "";
      this.ReferalMember.p_state = "";
      this.ReferalMember.p_district = "";
      this.ReferalMember.p_taluk = "";
      this.ReferalMember.p_area = "";
      this.ReferalMember.p_local_address = "";
    }
  }

  onPincodeValidate(val) {
    if (val != "") {
      this.csc.PincodeValidate(val).subscribe((result: any) => {
        if (result.data) {
          this.ReferalMember.p_country = result.data.country;
          this.ReferalMember.p_state = result.data.state;
          this.ReferalMember.p_district = result.data.district;
          this.ReferalMember.p_taluk = result.data.taluk;
        }
        this.p_areas = result.area;
      });
    }
  }

  onSelectedDocumentSubmit(values: NgForm, formDirective: FormGroupDirective) {
    const formData = new FormData();
    if (this.selectedDocumentsForm.valid) {
      formData.append(
        "DOCUMENT_TYPE_IDFK",
        this.selectedDocumentsForm.get("DOCUMENT_TYPE_IDFK").value
      );
      formData.append(
        "DOCUMENT_AVAILABLE",
        this.selectedDocumentsForm.get("DOCUMENT_AVAILABLE").value
      );
      formData.append(
        "DOCUMENT_CORRECTION_REQUIRED",
        this.selectedDocumentsForm.get("DOCUMENT_CORRECTION_REQUIRED").value
      );
      formData.append(
        "DOCUMENTS",
        this.selectedDocumentsForm.get("DOCUMENTS").value
      );

      this.ProfileService.uploadDocuments(formData).subscribe(
        (res: any) => {
          // console.log(res);
          // this.csc.getInduialMemberDocuments().subscribe((result: any) => {
          //   this.MemberDocumentsdocuments = result.documents;
          // });
        },
        (err) => (this.error = err)
      );
    }
    // this.selectedDocumentsForm.reset();
    // formDirective.resetForm();
    //window.location.reload();
  }
}
