import { Component, ViewEncapsulation, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PartnerService } from 'src/app/services/partner.service';


@Component({
  selector: 'app-document-viewer-dialog',
  templateUrl: './document-viewer-dialog.component.html',
  styleUrls: ['./document-viewer-dialog.component.scss'],
  providers: [PartnerService]
})
export class DocumentViewerDialogComponent implements OnInit {
  public imglink: any;
  constructor(public dialogRef: MatDialogRef<DocumentViewerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any ) { 
    this.imglink = data;
    console.log(data);
  }

  ngOnInit(): void {
  }

}
