import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';
import { ProductsAndServicesService } from 'src/app/services/products-and-services.service';
import { Globals } from 'src/app/globals';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: any;
  @Input() type: string;
  @Input() transData: any;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count:number = 1;
  public align = 'center center';
  constructor(public appService:AppService,private globals: Globals, public snackBar: MatSnackBar,public proserv:ProductsAndServicesService) { }

  public isLoggedIn = this.globals.enable;
  public member_id = this.globals.MemberID;


  ngOnInit() {
    //  console.log("In controle");
    //  console.log(this.product);
    //  console.log(this.transData);
    if(this.product){
      if(this.product.cartCount > 0){
        this.count = this.product.cartCount;
      }
    }  
    
    this.layoutAlign(); 
   // this.getMemberCartItems();
  }

  public layoutAlign(){
    if(this.type == 'all'){
      this.align = 'space-between center';
    }
    else if(this.type == 'wish'){
      this.align = 'start center';
    }
    else{
      this.align = 'center center';
    }
  }



  // public increment(count){
  //   if(this.count < this.product.availibilityCount){
  //     this.count++;
  //     let obj = {
  //       productId: this.product.id,
  //       soldQuantity: this.count,
  //       total: this.count * this.product.newPrice
  //     }
  //     this.changeQuantity(obj);
  //   }
  //   else{
  //     this.snackBar.open('You can not choose more items than available. In stock ' + this.count + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
  //   }    
  // }
  

  public increment(){
   //console.log(this.product);
      this.count++;
      let obj = {
        productId: this.product.program_org_product_id,
        soldQuantity: this.count,
        total: this.count * this.product.Product_price
      }

      
      this.changeQuantity(obj);
  }

  public decrement(){
    if(this.count > 1){
      this.count--;
      let obj = {
        productId: this.product.program_org_product_id,
        soldQuantity: this.count,
        total: this.count * this.product.Product_price
      }
      this.changeQuantity(obj);
    }
  }

  // public addToCompare(product:Product){
  //   this.appService.addToCompare(product);
  // }

  public wishlistResult:any;
  public addToWishList(product){
    if (this.isLoggedIn == 'true') {
    //this.appService.addToWishList(product);
   // this.proserv.addToWishList(product);
    this.proserv.addToWishList(product).subscribe((result: any) => {
      this.wishlistResult = result;

    //  console.log(this.programsList);
    });
  } 
  else{
    alert("Please Login To Ctrate Your Wishlist!!!");
  }
  

  }
  

  public cartResult:any;
  public addToCart(product: any){

    // console.log("Transe Data issss..");
   //  console.log(this.transData);
    
    let message, status;       
 if (this.isLoggedIn == 'true') {
   let currentProduct = this.proserv.Data.cartList.filter(item=>item.SOM_PRODUCT_ID == product.program_org_product_id)[0];
    if(currentProduct){
      if((currentProduct.SOM_QUANTITY + this.count) <= this.product.availibilityCount){
        product.SOM_QUANTITY = currentProduct.SOM_QUANTITY + this.count;
      }
      else{
       
        this.snackBar.open('Product ' + this.product.product_name + ' is already added to cart ', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
         return false;
      }
    }
    else{
      product.cartCount = this.count;
    }
  //this.appService.addToCart(product);

  let memobj = {
    program_id: product.program_organization_idfk,
    member_id: this.member_id,
  }

  this.proserv.getPurchaseSerDetails(memobj).subscribe((result:any )=> {


    //console.log("Transaction id isss:"+result.data['transaction_id']);
    if(result.data['transaction_id']!=null) {

      product['transaction_id'] = result.data['transaction_id'];
      product['serviceID'] = result.data['serviceID'];

      //if values are null
      if(product.market_price==null || product.market_price==0) {
        product.market_price=product.Product_price;
      }

      let total_discount_amount = (product.market_price*product.cartCount)-(product.Product_price*product.cartCount);
      let disc_perc = ((product.market_price - product.Product_price)/product.market_price)*100
      let total_disc_perc = (((product.market_price*product.cartCount)-(product.Product_price*product.cartCount))/(product.market_price*product.cartCount))*100;



      let cartSaveData = {
        "SOM_CART_SOURCE":"W",
        "SOM_ORG_ID":product.organization_id,
        "SOM_PRODUCT_ID":product.program_org_product_id,
        "SOM_TRANSACTION_ID":product.transaction_id,
        "SOM_PROGRAM_ID":product.program_organization_idfk,
        "SOM_SERVICE_ID":product.serviceID,
        "SOM_MEMBER_ID":this.member_id,
        "SOM_QUANTITY":product.cartCount,
        "SOM_MARKET_PRICE":product.market_price,
        "SOM_DISC_AMT":product.market_price-product.Product_price,
        "SOM_DISC_PER":disc_perc,
        "SOM_PRODUCT_PRICE":product.Product_price,
        "SOM_TOTAL_AMT":product.Product_price * product.cartCount,
        "SOM_TOTAL_DISC_AMT":total_discount_amount,
        "SOM_TOTAL_DISC_PERC":total_disc_perc,
      }
      // console.log('Product to adddd');
      // console.log(product);
      // console.log('Cart Save');
      // console.log(cartSaveData);

      

      this.proserv.addTocart(cartSaveData).subscribe((result: any) => {
        //this.cartResult = JSON.stringify(result);
        //this.cartResult = result;
        
      //  console.log('Cart result is');
      //  console.log(result);



       message = 'The product ' + product.product_name + ' has been added to cart.'; 
       status = 'success';          
       this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
      
      // this.proserv.Data.totalPrice += product.Product_price * product.cartCount;
      // this.proserv.Data.totalCartCount+=product.cartCount;
      //   this.proserv.Data.cartList.forEach(crtproduct=>{
      //     this.proserv.Data.totalPrice = this.proserv.Data.totalPrice + (crtproduct.SOM_QUANTITY * parseInt(crtproduct.SOM_PRODUCT_PRICE));
      //     this.proserv.Data.totalCartCount = this.proserv.Data.totalCartCount + crtproduct.SOM_QUANTITY;
      // });
        this.getMemberCartItems();
      });
    }
    else{
      alert("Boking period ended please wait till 15th");
    }


   });
  
 }
else{
      alert("Please Login To Ctrate Your Wishlist!!!");
  }
  }

  public openProductDialog(event){
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value){
      this.onQuantityChange.emit(value);
  }

//on 28th August
memberCartData:any;
getMemberCartItems (){
  this.proserv.Data.totalPrice=0;
  this.proserv.Data.totalCartCount=0;
  this.proserv.getMemberCartItems(this.member_id).subscribe((result:any )=> {
    this.memberCartData = result.data;
    this.proserv.Data.cartList=this.memberCartData;

    //on 3rd Oct '2020
    this.proserv.Data.cartList.forEach(crtproduct=>{
      this.proserv.Data.totalPrice += crtproduct.SOM_QUANTITY * parseInt(crtproduct.SOM_PRODUCT_PRICE);
      this.proserv.Data.totalCartCount += this.proserv.Data.totalCartCount + crtproduct.SOM_QUANTITY;
  });

  // let res = this.proserv.Data.cartList.reduce(function(prev, cur) {
  //   return prev + cur.SOM_TOTAL_AMT;
  // }, 0);
  // this.proserv.Data.totalPrice = res;
 
   });
}

}