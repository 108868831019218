import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Globals } from "../globals";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Injectable({
  providedIn: "root",
})
export class ApplyserviceService {
  public currentConfig: DomainConfig;

  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
      "User-ID": this.globals.MemberID,
    }),
  };

  GetOptions = {
    headers: new HttpHeaders({
      Accept: "application/x-www-form-urlencoded",
      "User-ID": this.globals.MemberID,
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  postOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  GetServiceDetials(sodata) {
    // console.log('In Service:'+sid);
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/ServiceDetails/GetServiceDetails/",
        sodata,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //on 19th may guna
  applyOrgServices(jdata) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ChatConverstion_cntrl/WebServiceRequestforOrganizationServices",
        jdata,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //on 19th may guna
  IconHelpapplyOrgServices(jdata) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ChatConverstion_cntrl/IconHelpWebServiceRequestforOrganizationServices",
        jdata,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //on 28th May '2020
  checkAppliedorNot(sdata) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/ServiceApply/checkSrAppliedOrNot/",
        sdata,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  IconcheckAppliedorNot(sdata) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/ServiceApply/checkSrAppliedOrNotIconSupport/",
        sdata,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // Applied Services List

  MyAppliedServices() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Services_SynStatus_cntrl/ALLServicesTrancationStatus",
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  MyAppliedServicesIconMemberShow(data) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Services_SynStatus_cntrl/ALLServicesTrancationStatusIconMemberShow",
        data,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  MyAppliedServicesSingleProgram(prgId) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Services_SynStatus_cntrl/ALLServicesTrancationStatusPerticularProgram/" +
          prgId,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //on 12th
  getServiceDocumenst(sid) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/EligibilityServices/getServiceDocuments/" +
          sid,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  IcongetServiceDocument(data) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/EligibilityServices/getServiceDocumentsIconGet",
        data,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  uploadServiceDocuments(formData) {
    //console.log(JSON.stringify(formData));
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/EligibilityServices/serviceDocumentsUpload",
        formData
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
