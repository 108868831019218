import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { HomeService } from 'src/app/services/home.service';
import { AppConfigService, DomainConfig } from 'src/app/app-config.service';


@Component({
  selector: 'app-main-carousel',
  templateUrl: './main-carousel.component.html',
  styleUrls: ['./main-carousel.component.scss']
})
export class MainCarouselComponent implements OnInit {
  public currentConfig:DomainConfig;
  public base_url;
  
  @Input('slides') slides: Array<any> = [];
 
  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  
  // slides: Object;
  

  constructor(private appConfigService:AppConfigService,private homeservice : HomeService) { 
    this.currentConfig=this.appConfigService.currentConfig;
    this.base_url=this.currentConfig.dev_url_prefix;
  }

  ngOnInit() {

    // this.homeservice.GetSliders().subscribe((data) =>{
    //   this.slides = data;
    // }); 
 
   }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,     
      autoplay: {
        delay: 6000,
        disableOnInteraction: true
      },
      speed: 500,
      effect: "slide"
    }
  }

  ngOnDestroy() {
    // this.homeservice.GetSliders()
  }
}