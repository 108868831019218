import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Settings, AppSettings } from "./app.settings";

import { CookieService } from "ngx-cookie-service";
import { AppConfigService, DomainConfig } from "./app-config.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  currentConfig: DomainConfig;
  loading: boolean = false;
  public settings: Settings;
  aa: any;
  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private cookieService: CookieService,
    private appConfigService: AppConfigService
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
    // this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.settings = this.currentConfig.settings;
    //  this.cookieService.set('mahesh','maheshkumar');
    //   this.aa = this.cookieService.get('mahesh');
    //  alert(this.aa);
    // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }
}
