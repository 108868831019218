import { Injectable, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";
import { Globals } from "../globals";
import { throwError } from "rxjs";
import { Md5 } from "ts-md5/dist/md5";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Injectable({
  providedIn: "root",
})
export class MastersService {
  public currentConfig: DomainConfig;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Accept': 'application/json',
  //     'Content-Type':'application/x-www-form-urlencoded',
  //     'Client-Service':'frontend-client',
  //     'Auth-key':'simplerestapi',
  //   })
  // };

  httpHeaderOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
      "User-ID": this.globals.MemberID,
      //'User-ID' : '100001',
      // 'Authorization':'frsfsfs',
    }),
  };
  postOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  deleteOptions = {
    headers: new HttpHeaders({
      //  'Host': '/',
      //'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  GetOptions = {
    headers: new HttpHeaders({
      Accept: "application/x-www-form-urlencoded",
      "User-ID": this.globals.MemberID,
      //'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  /**
   Referral Member
   * @param formData - post data
   * @returns {Observable<any>}
   */

  getMemberType(member_id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/List_cntrl/MemberType/" +
          member_id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  EducationList() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/List_cntrl/EducationList",
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // ------------PRAVEEN ---- 25-11-2020 ------------ START ---------------

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened. Please try again later.");
  }

  getALLMemberGroup() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Masters_data_server/MemberGroupMaster",
        this.httpHeaderOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  getOccupationList(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Masters_data_server/occupationMasterList/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getAllMemberTypes() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Masters_data_server/memberTypeMaster",
        this.httpHeaderOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  getAllGender() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/Masters_data_server/GetGenderList"
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  getAllCommunity() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Masters_data_server/GetCommunityList"
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  getIncome() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Masters_data_server/getMemberIncome"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getCategory() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Masters_data_server/getMemberCategory"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  getRationCardType() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Masters_data_server/getRationCardType"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  getAgeRange() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/Masters_data_server/getAgeRange"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getALLStates_praveen() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/GeneralApi/Get_All_States_praveen"
      )
      .pipe(catchError(this.handleError));
  }
  getALLDistricts_onChange(state) {
    state = Md5.hashStr(state);
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/Get_All_Districts_praveen/" +
          state
      )
      .pipe(catchError(this.handleError));
  }
  getALLTaluks_onChange(district) {
    district = Md5.hashStr(district);
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/Get_All_Taluks_praveen/" +
          district
      )
      .pipe(catchError(this.handleError));
  }
  getALLAreas_onChange(taluk) {
    taluk = Md5.hashStr(taluk);
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/Get_All_Areas_praveen/" +
          taluk
      )
      .pipe(catchError(this.handleError));
  }

  // ------------PRAVEEN ---- 25-11-2020 ------------ END -----------------

  // ------------PRAVEEN ---- 11-01-2021 ------------ END -----------------
  getScoialSecurityCatogories() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/Masters_data_server/getAgeRange"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getMemberAttributes() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Masters_data_server/getMemberAttributes",
        this.httpHeaderOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  getOrgEligibility(orgid) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Masters_data_server/get_org_eligibility/" +
          orgid,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getSalaryRange() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/Masters_data_server/getSalaryRange"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // ------------PRAVEEN ---- 11-01-2021 ------------ END -----------------
}
