import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";
import { throwError } from "rxjs";
import { Globals } from "../globals";
import { Md5 } from "ts-md5/dist/md5";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Injectable({
  providedIn: "root",
})
export class CscService {
  public currentConfig: DomainConfig;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  // globals = {
  //   access_token : 'GroupOneSeniorToken',
  // }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
      "User-ID": this.globals.MemberID,
      //'User-ID' : '100001',
      Authorization: "frsfsfs",
    }),
  };
  GetOptions = {
    headers: new HttpHeaders({
      Accept: "application/x-www-form-urlencoded",
      "User-ID": this.globals.MemberID,
      //'User-ID' : '100001',
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };
  httpHeaderOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
      "User-ID": this.globals.MemberID,
      //'User-ID' : '100001',
      // 'Authorization':'frsfsfs',
    }),
  };
  postOptions = {
    headers: new HttpHeaders({
      // 'Host': this.currentConfig.dev_url_prefix + '/',
      // 'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      //'Client-Service':'frontend-client',
      // 'Auth-key':'simplerestapi',
      // 'User-ID' : '101511',
      // 'Auth-Key':'12345'
    }),
  };
  postProfileOptions = {
    headers: new HttpHeaders({
      // 'Host': this.currentConfig.dev_url_prefix + '/',
      // 'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
      "User-ID": this.globals.MemberID,
      //'User-ID' : '100001',
      // 'Auth-Key':'12345'
    }),
  };

  deleteOptions = {
    headers: new HttpHeaders({
      //  'Host': '/',
      //'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  getALLStates() {
    return this.http
      .get(this.currentConfig.dev_url_prefix + "Api/v1/GeneralApi/Get_All_States")
      .pipe(catchError(this.handleError));
  }
  getALLStates_praveen() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/GeneralApi/Get_All_States_praveen"
      )
      .pipe(catchError(this.handleError));
  }
  getALLDistricts_praveen(state) {
    state = Md5.hashStr(state);
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/Get_All_Districts_praveen/" +
          state
      )
      .pipe(catchError(this.handleError));
  }
  getALLTaluks_praveen(district) {
    district = Md5.hashStr(district);
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/Get_All_Taluks_praveen/" +
          district
      )
      .pipe(catchError(this.handleError));
  }
  getALLAreas_praveen(taluk) {
    taluk = Md5.hashStr(taluk);
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/Get_All_Areas_praveen/" +
          taluk
      )
      .pipe(catchError(this.handleError));
  }

  // getALLMemberGroup() {
  //   return this.http.get(this.currentConfig.dev_url_prefix+'Api/v1/List_cntrl/MemberGroupMaster', this.httpOptions).pipe(
  //     catchError(this.handleError)
  //   );
  // }
  getALLMemberGroup() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/List_cntrl/MemberGroupMaster",
        this.httpHeaderOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  getALLOccupationTypes() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/List_cntrl/occupationMasterList",
        this.httpHeaderOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  OccupationGroup() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/List_cntrl/MemberGroupMaster",
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getOccupationList(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/List_cntrl/occupationMasterListMatched/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  getAllMemberTypes() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/List_cntrl/memberTypeMaster",
        this.httpHeaderOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  // get gender details
  getAllGender() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/MemberRegistration_cntrl/GetGenderList"
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  // specific data getting
  getSpecificDocType() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/MemberRegistration_cntrl/getSpecificDocType"
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  // get all community master
  getAllCommunity() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/MemberRegistration_cntrl/GetCommunityList"
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  // get all reletional dependents
  getAllRelationalDependents() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/MemberRegistration_cntrl/GetReletionDependentList"
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  // get member profile update
  getAllMemberDetails() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/myeid/GetQuickRegistration/8904",
        this.httpOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  // get member bank details
  getAllMemberBankDetails() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/getMemberBankDetails",
        this.httpOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  // get member dependents

  getAllMemberDependentsDetails() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/getMemberDependents",
        this.httpOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  // get member documents

  getAllMemberDocuments() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/getMemberDocuments",
        this.httpOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  // get all documents group based
  getAllMemberDocumentsGroup() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/memberDocumentsBasedOnGroup",
        this.httpOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  getAllMemberProfilePic() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/getMemberProfilePic",
        this.httpOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  // getAllMemberTypes() {
  //   return this.http.get(this.currentConfig.dev_url_prefix+'Api/v1/List_cntrl/memberTypeMaster').pipe(
  //     catchError(this.handleError)
  //   );
  // }

  getALLDistrict(state) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/GeneralApi/GetAllDistrict/" + state
      )
      .pipe(catchError(this.handleError));
  }

  getALLTaluk(district) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/GeneralApi/GetAllTaluk/" + district
      )
      .pipe(catchError(this.handleError));
  }

  getALLArea(taluk) {
    return this.http
      .get(this.currentConfig.dev_url_prefix + "Api/v1/GeneralApi/GetAllArea/" + taluk)
      .pipe(catchError(this.handleError));
  }

  GetAllAreaAndPincode(pincode) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/GetAllAreaAndPincode/" +
          pincode
      )
      .pipe(catchError(this.handleError));
  }

  /**
   Filter Programs
   * @param formData - post data
   * @returns {Observable<any>}
   */
  FilterPrograms(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/FilterProgramsBasedOnConditions",
        formData,
        this.postOptions
      )
      .pipe(
        map((prog) => {
          return prog;
        })
      );
  }

  /**
    member registartion form post
   * @param formData - post data
   * @returns {Observable<any>}
   */

  userRegistration(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/QuickRegistrationAllStatesMember",
        formData,
        this.postOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  /**
    member registartion form post
   * @param formData - post data
   * @returns {Observable<any>}
   */

  memberProfileUpdate(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/MemberProfileBasicInfoUpdate  ",
        formData,
        this.postProfileOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  /**
    member registartion form post
   * @param formData - post data
   * @returns {Observable<any>}
   */

  memberAddressUpdate(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/MemberProfileAddressUpdate",
        formData,
        this.postProfileOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  /**
    member registartion form post
   * @param formData - post data
   * @returns {Observable<any>}
   */

  memberBankDetailsUpdate(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/MemberProfileBankUpdate",
        formData,
        this.postProfileOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  /**
    member registartion form post
   * @param formData - post data
   * @returns {Observable<any>}
   */

  memberEligibilityDetailsUpdate(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/MemberProfileEligibilityUpdate",
        formData,
        this.postProfileOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  /**
    member registartion form post
   * @param formData - post data
   * @returns {Observable<any>}
   */

  memberDependentsDetailsUpdate(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/MemberProfileDependentsUpdate",
        formData,
        this.postProfileOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  /**
   Pincode FilterPrograms
   * @param formData - post data
   * @returns {Observable<any>}
   */
  PincodeFilterPrograms(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/PincodeGetStateDistrictTalukArea/" +
          id
      )
      .pipe(
        map((prog) => {
          return prog;
        })
      );
  }

  /**
   Pincode Validate
   * @param formData - post data
   * @returns {Observable<any>}
   */

  PincodeValidate(pincode) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/List_cntrl/PincodeGetDetails/" +
          pincode,
        this.GetOptions
      )
      .pipe(
        map((prog) => {
          return prog;
        })
      );
  }

  DocumentsList() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/myeid/documentList",
        this.httpHeaderOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  selectedDocumentsList() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/myeid/selectedDocumentsList",
        this.httpHeaderOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened. Please try again later.");
  }

  //search filter
  /**
   Filter Programs
   * @param formData - post data
   * @returns {Observable<any>}
   */
  FilterOrganisations(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/FilterOrganisationsBasedOnConditions",
        formData,
        this.postOptions
      )
      .pipe(
        map((prog) => {
          return prog;
        })
      );
  }

  // messages get details api
  getAllMemberAllMessages() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/getMemberAllMessages",
        this.httpOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  // get all notification for member
  getAllMemberAllNotifications() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/getMemberAllNotifications",
        this.httpOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  // get all service request for member
  getAllMemberAllServiceRequest(page) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/getMemberAllServiceAppliedList/" +
          page,
        this.httpOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  // get all support for member
  getAllMemberAllSupport(page) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/getMemberAllSupport/" +
          page,
        this.httpOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  // get all support for member
  getAllMemberAllNotificationCount() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/getALlMemberNotificationCount",
        this.httpOptions
      )
      .pipe(
        map((token) => {
          return token;
        })
      );
  }
  //on 20th Oct

  getPincodeDetTillTaluk(pincode) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/getPincodeDetTillTaluk/" +
          pincode
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
