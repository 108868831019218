import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  Inject,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Settings, AppSettings } from "../app.settings";
import { AppService } from "../app.service";
import { Category, Product } from "../app.models";
import { SidenavMenuService } from "../theme/components/sidenav-menu/sidenav-menu.service";
import { Globals } from "../globals";
import { ProductsAndServicesService } from "../services/products-and-services.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { CookieService } from "ngx-cookie-service";
import { AppConfigService, DomainConfig } from "../app-config.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  providers: [SidenavMenuService],
})
export class PagesComponent implements OnInit {
  public currentConfig: DomainConfig;
  public showBackToTop: boolean = false;
  public categories: Category[];
  public category: Category;
  public sidenavMenuItems: Array<any>;
  public isLoggedIn = this.globals.enable;

  isLogged: any = this.cookieService.get("enable");

  @ViewChild("sidenav", { static: true }) sidenav: any;

  @ViewChild(MatMenuTrigger) ddTrigger: MatMenuTrigger;

  public settings: Settings;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public appSettings: AppSettings,
    public appService: AppService,
    public sidenavMenuService: SidenavMenuService,
    private globals: Globals,
    public proserv: ProductsAndServicesService,
    public router: Router,
    private cookieService: CookieService,
    private appConfigService: AppConfigService
  ) {
    // this.settings = this.appSettings.settings;
    this.currentConfig = this.appConfigService.currentConfig;
    this.settings = this.currentConfig.settingsObject;
  }
  public member_id = this.globals.MemberID;

  ngOnInit() {
    var scrollPos = 0;

    window.addEventListener("scroll", function () {
      if (window.scrollY == 0) {
        const toolbar = document.querySelector(".mat-toolbar-pf");
        toolbar.setAttribute("style", "margin-top:unset;");
        toolbar.classList.remove("mat-toolbar-bb");
      }
      if (document.body.getBoundingClientRect().top > scrollPos) {
        //scrolling up
        // const toolbar = document.querySelector('.mat-toolbar-pf');
        // toolbar.setAttribute("style", "margin-top:unset;");
      } else {
        //scrolling up
        const toolbar = document.querySelector(".mat-toolbar-pf");
        toolbar.setAttribute("style", "margin-top:-90px;");
        toolbar.classList.add("mat-toolbar-bb");
      }
      scrollPos = document.body.getBoundingClientRect().top;
    });

    this.getCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    this.getMemberCartItems();

    //      this.proserv.Data.cartList.forEach(crtproduct=>{
    //     this.proserv.Data.totalPrice = this.proserv.Data.totalPrice + (crtproduct.cartCount * parseInt(crtproduct.Product_price));
    //     this.proserv.Data.totalCartCount = this.proserv.Data.totalCartCount + crtproduct.cartCount;
    // });
  }

  public getCategories() {
    this.appService.getCategories().subscribe((data) => {
      this.categories = data;
      this.category = data[0];
      this.appService.Data.categories = data;
    });
  }

  public changeCategory(event) {
    if (event.target) {
      this.category = this.categories.filter(
        (category) => category.name == event.target.innerText
      )[0];
    }
    if (window.innerWidth < 960) {
      this.stopClickPropagate(event);
    }
  }

  deltedres: any;
  public remove(product) {
    const index: number = this.proserv.Data.cartList.indexOf(product);
    if (index !== -1) {
      // this.appService.Data.cartList.splice(index, 1);
      // this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice*product.cartCount;
      // this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.cartCount;
      // this.appService.resetProductCartCount(product);
      this.proserv
        .removeCartItem(product.SOM_CART_ID)
        .subscribe((result: any) => {
          this.deltedres = result;
          this.getMemberCartItems();
        });
    }
  }

  // public clear(){
  //   this.appService.Data.cartList.forEach(product=>{
  //     this.appService.resetProductCartCount(product);
  //   });
  //   this.appService.Data.cartList.length = 0;
  //   this.appService.Data.totalPrice = 0;
  //   this.appService.Data.totalCartCount = 0;
  // }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  public search() {}

  public scrollToTop() {
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset / (scrollDuration / 20);
    var scrollInterval = setInterval(() => {
      if (window.pageYOffset != 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }
  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event) {
    $event.target.documentElement.scrollTop > 300
      ? (this.showBackToTop = true)
      : (this.showBackToTop = false);
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(
      this.sidenavMenuService.getSidenavMenuItems()
    );
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

  pincodeget(data) {
    this.router.navigate(["/phapa/programs", data]);
  }

  //on 21st August
  memberCartData: any;
  getMemberCartItems() {
    this.proserv.Data.totalPrice = 0;
    this.proserv.Data.totalCartCount = 0;
    //this.proserv.Data.cartList=0;
    this.proserv.getMemberCartItems(this.member_id).subscribe((result: any) => {
      this.memberCartData = result.data;
      this.proserv.Data.cartList = this.memberCartData;
      //  console.log("cartList");
      //  console.log(this.proserv.Data.cartList);
      this.proserv.Data.cartList.forEach((crtproduct) => {
        this.proserv.Data.totalPrice +=
          crtproduct.SOM_QUANTITY * parseInt(crtproduct.SOM_PRODUCT_PRICE);
        this.proserv.Data.totalCartCount +=
          this.proserv.Data.totalCartCount + crtproduct.SOM_QUANTITY;
      });

      // let res = this.proserv.Data.cartList.reduce(function(prev, cur) {
      //   return prev + cur.SOM_TOTAL_AMT;
      // }, 0);
      // this.proserv.Data.totalPrice = res;
    });
  }

  public clear() {
    //this.appService.Data.wishList.length = 0;
    this.proserv.clearCartlist(this.member_id).subscribe((result: any) => {
      // console.log(this.deltedres);
      this.getMemberCartItems();
    });
  }

  Logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll();
    this.cookieService.delete("enable");
    this.cookieService.delete("Name");
    this.cookieService.delete("PartnerID");
    this.cookieService.delete("MemberID");
    this.cookieService.delete("MemID");
    this.cookieService.delete("mob");
    this.cookieService.delete("UserImg");
    this.cookieService.delete("Icon");
    this.cookieService.deleteAll("/phapa");
    this.cookieService.delete("orgAdmin");
    this.cookieService.deleteAll("/phapa/admin");
    this.cookieService.deleteAll("/");
    // this.router.navigateByUrl("");
    // this.router.navigate(["/"]);
    this.document.location.href = this.currentConfig.landingUrl;
  }

  showToolbar() {
    const toolbar = document.querySelector(".mat-toolbar-pf");
    toolbar.setAttribute("style", "margin-top:unset;");
    toolbar.classList.remove("mat-toolbar-bb");
  }
}
