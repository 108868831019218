import { Injectable, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { Globals } from "./../../globals";
import { AppConfigService, DomainConfig } from "src/app/app-config.service";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  currentConfig: DomainConfig;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  postOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  deleteOptions = {
    headers: new HttpHeaders({
      //  'Host': '/',
      //'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  GetOptions = {
    headers: new HttpHeaders({
      Accept: "application/x-www-form-urlencoded",
      "User-ID": this.globals.MemberID,
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  /**
   Organization Users Count 
   * @param formData - post data
   * @returns {Observable<any>}
   */

  OrganizationUserCount(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/OrganizationAllUsersCount",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  // dashboard organisation based services
  OrganizationServiceLinked(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/occupationMasterListMatched/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  /**
   Organization Contact Person 
   * @param formData - post data
   * @returns {Observable<any>}
   */

  OrganizationLinked(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/OrganizationContactPersonList",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  OrganizationMonthlyReport(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/OrganizationMonthyReport",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  ServicesStepsProgressReport(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/SingleServicesReportCountGraph",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  OrganizationMemStatusReport(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/OrganizationMemStatusReport",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  OrganizationOccupationReport(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/OrganizationOccupationReport",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  OrganizationOccupationTypeReport(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/OrganizationOccupationTypeReport",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  OrganizationOccupationSubTypeReport(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/OrganizationOccupationSubTypeReport",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  OrganizationYearReport(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/OrganizationYearlyReport",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  getPaymentDetails(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/getpaymentDetailsReport",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  getServiceTransactionReport(formData) {
    //console.log('In Service');
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/getServiceTransactionReport",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getMemberstList(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/Org_Admin_cntrl/getMemberstList",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  ServicesReportCount(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/ServicesReportCount",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
