import { Component, OnInit, ViewChild,ElementRef, Input } from '@angular/core';
import { InterestService } from 'src/app/services/interest.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, FormGroupDirective  } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { MastersService } from 'src/app/services/masters.service';
import { UserService } from '../../../services/user.service';
import { ReferralService } from '../../../services/referral.service';
import { CscService } from 'src/app/services/csc.service';
import {CookieService} from 'ngx-cookie-service';
import { forkJoin } from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { exit } from 'process';
import {MatTableDataSource} from '@angular/material/table';




@Component({
  selector: 'app-job-interest',
  templateUrl: './job-interest.component.html',
  styleUrls: ['./job-interest.component.scss']
})
export class JobInterestComponent implements OnInit {

  locationForm: FormGroup;
  experienceForm: FormGroup;
  @Input('member_id') member_id: any;
//@Input('MDmember_ID') MDmember_ID:any;
  //public member_id: any;
  jobPreferenceForm: FormGroup;

  expericeArray:FormArray;
   
  show = false;
  buttonName = 'Show';
  hide: any;

  preferenceID:any;
  preference : any;
  post: any = '';


  jobDetailsArray ={
    PRF_PREFERENCE_ID:'',
    PRF_EXP_IN_YEARS:'',
    PRF_EXP_IN_MONTHS:'',
    PRF_MONTHLY_WAGES:'',
    PRF_DAILY_WAGES:'',
    PRF_OCCUPATION_GROUP:'',
    PRF_OCCUPATION_TYPE:'',
    PRF_OCCUPATION_SUBTYPE:'',
    PRF_LOOKING_FOR_JOB:'',
    PRF_TYPE_OF_CONTRACTOR:'',
  }

  relationdepents = [];
  educationInfo = [];
  gender = [];
  member_type = [];

  public memberJob;
  public memberExp;
  public OccupationGroup: any;
  public maxValue: number = 12;
  public minValue: number = 10;

  public contractortype : boolean = false;
  

  public occupationList: any;
  public occupationSubTypeList:any;
  public occupationSubTypeList1:any;
  public socialSecurityCategories :any;
  public socialSecurityAttributes: any;
  public socialSecurityUncheckedInt = [];
  public socialSecurityMemberData:any;
  location: FormArray;
  experience :FormArray;

  locationData = {
    id: this.jobDetailsArray.PRF_PREFERENCE_ID,
    PRF_STATE: '',
    PRF_DISTRICT: '',
    PRF_TALUK: '',
  };
  experienceData = {
    ids: '',
    employer_name: '',
    employer_phone_number: '',
    emp_work_site_address: '',
    experience_in_years: '',
    experience_in_months:'',
    project_name: '',
    type_of_project: '',
    team_size: '',
    from_date: '',
    to_date: '',
    paid_record: '',
    completion_certificate: '',
    project_cost: '',
    looking_for_job: '',
  };

  constructor(
    public intService:InterestService,
    private formBuilder: FormBuilder,
    private globals: Globals,
    private ms:MastersService,
    private UserService: UserService,
    private rfl: ReferralService,
    private csc: CscService,
    private masterlist: MastersService,
    private cookieService: CookieService,

  ) {   }
 
  housingForm: FormGroup;

  states: any;
  formData : any;
  districts: {};
  taluks: {};
  areas: {};
 

  
  //public member_ID = this.cookieService .get('MemID');
  
  @ViewChild (FormGroupDirective) formDirective: FormGroupDirective;


  public tempi = 0;

  ngOnInit(): void { 
    this.GetOccupationGroup();
    this.getStates(); //search filter
    // this.getAgeRange(); 
    this.MembersJobDetails();
    this.MembersExperienceDetails();






    
 
    this.locationForm = this.formBuilder.group({
      id:this.locationPrefId,
      PRF_STATE: [''],
      PRF_DISTRICT: [''],
      PRF_TALUK: [''],
    });

    this.experienceForm = this.formBuilder.group({
      ids: '',
      employer_name: [''],
      employer_phone_number: new FormControl('', [Validators.required, Validators.maxLength(12), Validators.minLength(10)]),
      emp_work_site_address: [''],
      experience_in_years: [''],
      experience_in_months:[''],
      project_name: [''],
      type_of_project: [''],
      team_size:[''],
      from_date:[''],
      to_date:[''],
      paid_record:[''],
      completion_certificate:[''],
      project_cost:[''],
      looking_for_job:[''],

    });
    
    this.preference = (function() {
      var nextID = 1;
      return function(preference, args) {
        this.preferenceID = nextID++;
     
      };
    });

    this.location = this.formBuilder.array([]);
    this.locationForm.addControl('rows1', this.location);

    this.experience = this.formBuilder.array([]);
    this.experienceForm.addControl('rows', this.experience);

    

    
    this.jobPreferenceForm = this.formBuilder.group({
      'PRF_EXP_IN_YEARS':'',
      'PRF_OCCUPATION_GROUP': '',
      'PRF_OCCUPATION_TYPE': '',
      'PRF_OCCUPATION_SUBTYPE':'',
      'PRF_DAILY_WAGES': '',
      'PRF_MONTHLY_WAGES': '',
      'PRF_EXP_IN_MONTHS': '',
      'PRF_LOOKING_FOR_JOB':'',
      'PRF_TYPE_OF_CONTRACTOR':'',
     
    });


  

// DEPENDENTS --------------------------- END  -----------
  } // ********************* END OF NgOnInit ****************************************


  validateAllFormFields(formGroup: FormGroup) 
  {         
    Object.keys(formGroup.controls).
    forEach(field => 
      {  
        const control = formGroup.get(field);      
           
        if (control instanceof FormControl) 
        {            
          
          console.dir(control)    
          control.markAsTouched({ onlySelf: true });
        } 
        else if (control instanceof FormGroup) 
        {        
           
          this.validateAllFormFields(control);       
        }
      }
    );
}

  onAddRow() {
    
    if(this.location.length>=10){
      alert('You can enter maximum 10 locations only');
      return false;
    }
    this.locationPrefId++;
    this.locationData.id = this.locationPrefId;
    this.locationData.PRF_STATE = '';
    this.locationData.PRF_DISTRICT = '';
    this.locationData.PRF_TALUK = '';

    this.location.push(this.createItemFormGroup());
  }
  createItemFormGroup(): FormGroup {
    return this.formBuilder.group({
      
      id: this.locationData.id,
      PRF_STATE: this.locationData.PRF_STATE,
      PRF_DISTRICT: this.locationData.PRF_DISTRICT,
      PRF_TALUK: this.locationData.PRF_TALUK,
    });
  }
  // 2nd multi row add
  onAddExperienceRow() {
    
  

    this.experienceData.ids = '';
    this.experienceData.employer_name = '';
    this.experienceData.employer_phone_number = '';
    this.experienceData.emp_work_site_address = '';
    this.experienceData.experience_in_years = '';
    this.experienceData.experience_in_months = '';
    this.experienceData.project_name = '';
    this.experienceData.type_of_project = '';
    this.experienceData.team_size = '';
    this.experienceData.from_date = '';
    this.experienceData.to_date = '';
    this.experienceData.paid_record = '';
    this.experienceData.completion_certificate = '';
    this.experienceData.project_cost = '';
    this.experienceData.looking_for_job = '';

    this.experience.push(this.createExpirenceFormGroups());
  }
  createExpirenceFormGroups(): FormGroup {
    return this.formBuilder.group({
      ids: this.experienceData.ids,
      employer_name: this.experienceData.employer_name,
      employer_phone_number: this.experienceData.employer_phone_number,
      emp_work_site_address: this.experienceData.emp_work_site_address,
      experience_in_years: this.experienceData.experience_in_years,
      experience_in_months: this.experienceData.experience_in_months,
      project_name: this.experienceData.project_name,
      type_of_project: this.experienceData.type_of_project,
      team_size: this.experienceData.team_size,
      from_date: this.experienceData.from_date,
      to_date: this.experienceData.to_date,
      paid_record: this.experienceData.paid_record,
      completion_certificate: this.experienceData.completion_certificate,
      project_cost: this.experienceData.project_cost,
      looking_for_job: this.experienceData.looking_for_job,
    });
  }

  GetOccupationGroup() {
    this.rfl.OccupationGroup().subscribe((result: any) => {
      this.OccupationGroup = result.data;
      this.occupationList = "";
    });

  }
  ChangeoccupationGroup(orgGroup) {
    if (orgGroup) {
      this.OccupationGroupID = orgGroup;
      this.rfl.getOccupationList(this.OccupationGroupID).subscribe(
        (data: any) => {
          this.occupationList = data.data;

         
          
        }
      );
    }
  }
  // occupations sub type
  ChangeoccupationSubGroup(orgSubGroup) {
    if (orgSubGroup) {
      this.OccupationSubTypeID = orgSubGroup;

      if(this.OccupationSubTypeID =='216'){
        this.contractortype =true
       // console.log("This is Id",this.contractortype);
      }else{
        this.contractortype =false
      }
      this.rfl.getOccupationSubList(this.OccupationSubTypeID).subscribe(
        (data: any) => {
          this.occupationSubTypeList = data.data;
          // console.log(this.occupationSubTypeList);
          
          
        
          
          
        }
      );
    }
  }

  onExperienceRemoveRow(rowExpIndex: number, expID) {
   
    if (expID.value == '') {
      this.experience.removeAt(rowExpIndex);
      
     
    }
  }
  onRemoveRow(rowIndex: number, locId) {

    if (locId.value != '') {
      this.location.removeAt(rowIndex);
      this.locationPrefId--;
     
    }
  }

  
  
    // prevent special characters
    textFieldNumAndSpecialCharRestrictions(event) {
      var regExp = /[0-9-=+_;!@#$%^&*()~|}{}"'/,<>`\[\]]/i;
      var value = String.fromCharCode(event.which) || event.key;
      if (regExp.test(value)) {
        event.preventDefault();
        return false;
      }
    }
    numFieldSpecialCharRestrictions(event) {
      var regExp = /[a-z-=+_.;!@#$%^&*()~|}{}"'/,<>`\[\]\s]/i;
      var value = String.fromCharCode(event.which) || event.key;
      if (regExp.test(value)) {
        event.preventDefault();
        return false;
      }
    }
    // On change of occupation group ----------------------------------
  OccupationGroupID: any;
  OccupationSubTypeID:any;


  
    // On change of occupation group ----------------------------------
  
  public locationPrefId :any = 1;
  

  public MembersJobDetails(){
      let meData = {
        "member_id":this.member_id,
      }
     
      this.intService.MembersJobDetails(meData).subscribe((result: any) => {
        
         this.memberJob =result.data[0];
       
      
         this.GetOccupationGroup();
       
          this.jobDetailsArray.PRF_EXP_IN_YEARS = this.memberJob.PRF_EXP_IN_YEARS;
          this.jobDetailsArray.PRF_EXP_IN_MONTHS = this.memberJob.PRF_EXP_IN_MONTHS;
          this.jobDetailsArray.PRF_MONTHLY_WAGES = this.memberJob.PRF_MONTHLY_WAGES;
          this.jobDetailsArray.PRF_DAILY_WAGES = this.memberJob.PRF_DAILY_WAGES;
          this.jobDetailsArray.PRF_LOOKING_FOR_JOB = this.memberJob.PRF_LOOKING_FOR_JOB;
          this.jobDetailsArray.PRF_TYPE_OF_CONTRACTOR = this.memberJob.PRF_TYPE_OF_CONTRACTOR;
          this.jobDetailsArray.PRF_OCCUPATION_GROUP = this.memberJob.PRF_OCCUPATION_GROUP;
         
          this.ChangeoccupationGroup(this.jobDetailsArray.PRF_OCCUPATION_GROUP);
          this.jobDetailsArray.PRF_OCCUPATION_TYPE = this.memberJob.PRF_OCCUPATION_TYPE;
         // console.log("Inside get function ID is:",this.jobDetailsArray.PRF_OCCUPATION_TYPE);
          if(this.memberJob.PRF_OCCUPATION_TYPE =='216'){
            this.contractortype = true
           // console.log("Inside get function:",this.contractortype);
          }
          else{
            this.contractortype = false
           // console.log("Inside get function:",this.contractortype);
          }

          this.ChangeoccupationSubGroup(this.jobDetailsArray.PRF_OCCUPATION_TYPE);
          this.jobDetailsArray.PRF_OCCUPATION_SUBTYPE = this.memberJob.PRF_OCCUPATION_SUBTYPE;

         if(result.data.length >0) {
          result.data.forEach((element) => {

            this.locationPrefId = element.PRF_PREFERENCE_ID;
            this.locationData.id = element.PRF_PREFERENCE_ID;
            this.locationData.PRF_STATE = element.PRF_STATE;

            if(this.locationPrefId !=null || this.locationPrefId!='') 
            {
            if(this.tempi == 0){
              this.csc.getALLDistrict(this.locationData.PRF_STATE).subscribe((result: any) => {
                this['d'+0] = result;
                
              });
            }
                 
            if(this.tempi == 1){
              this.csc.getALLDistrict(this.locationData.PRF_STATE).subscribe((result: any) => {
                this['d'+1] = result;
                
              });
            }
    
            if(this.tempi == 2){
              this.csc.getALLDistrict(this.locationData.PRF_STATE).subscribe((result: any) => {
                this['d'+2] = result;
                
              });
            }
            if(this.tempi == 3){
              this.csc.getALLDistrict(this.locationData.PRF_STATE).subscribe((result: any) => {
                this['d'+3] = result;
                
              });
            }
            if(this.tempi == 4){
              this.csc.getALLDistrict(this.locationData.PRF_STATE).subscribe((result: any) => {
                this['d'+4] = result;
                
              });
            }
            if(this.tempi == 5){
              this.csc.getALLDistrict(this.locationData.PRF_STATE).subscribe((result: any) => {
                this['d'+5] = result;
                
              });
            }
            if(this.tempi == 6){
              this.csc.getALLDistrict(this.locationData.PRF_STATE).subscribe((result: any) => {
                this['d'+6] = result;
                
              });
            }
            if(this.tempi == 7){
              this.csc.getALLDistrict(this.locationData.PRF_STATE).subscribe((result: any) => {
                this['d'+7] = result;
                
              });
            }
            if(this.tempi == 8){
              this.csc.getALLDistrict(this.locationData.PRF_STATE).subscribe((result: any) => {
                this['d'+8] = result;
                
              });
            }
            if(this.tempi == 9){
              this.csc.getALLDistrict(this.locationData.PRF_STATE).subscribe((result: any) => {
                this['d'+9] = result;
                
              });
            }

            this.locationData.PRF_DISTRICT = element.PRF_DISTRICT;
           // this.onChangeDistrict(element.PRF_DISTRICT,this.locationData.id);
           if(this.tempi == 0){
    
              
            this.csc.getALLTaluk(this.locationData.PRF_DISTRICT).subscribe((result: any) => {
              this['t'+0] = result;
              
            });
          }
           if(this.tempi == 1){
            this.csc.getALLTaluk(this.locationData.PRF_DISTRICT).subscribe((result: any) => {
              this['t'+1] = result;
              
            });
          }
  
          if(this.tempi == 2){
            this.csc.getALLTaluk(this.locationData.PRF_DISTRICT).subscribe((result: any) => {
              this['t'+2] = result;
              
            });
          }
          if(this.tempi == 3){
            this.csc.getALLTaluk(this.locationData.PRF_DISTRICT).subscribe((result: any) => {
              this['t'+3] = result;
              
            });
          }
          if(this.tempi == 4){
            this.csc.getALLTaluk(this.locationData.PRF_DISTRICT).subscribe((result: any) => {
              this['t'+4] = result;
              
            });
          }
          if(this.tempi == 5){
            this.csc.getALLTaluk(this.locationData.PRF_DISTRICT).subscribe((result: any) => {
              this['t'+5] = result;
              
            });
          }
          if(this.tempi == 6){
            this.csc.getALLTaluk(this.locationData.PRF_DISTRICT).subscribe((result: any) => {
              this['t'+6] = result;
              
            });
          }
          if(this.tempi == 7){
            this.csc.getALLTaluk(this.locationData.PRF_DISTRICT).subscribe((result: any) => {
              this['t'+7] = result;
              
            });
          }
          if(this.tempi == 8){
            this.csc.getALLTaluk(this.locationData.PRF_DISTRICT).subscribe((result: any) => {
              this['t'+8] = result;
              
            });
          }
          if(this.tempi == 9){
            this.csc.getALLTaluk(this.locationData.PRF_DISTRICT).subscribe((result: any) => {
              this['t'+9] = result;
              
            });
          }
            this.locationData.PRF_TALUK = element.PRF_TALUK;
            this.location.push(this.createItemFormGroup());
            this.tempi++;
        }

          });
        

        }
      
          });
      }

      public experiencePrefId :any = 0;
      // get all member experience details
      public MembersExperienceDetails(){
        let meData = {
          "member_id":this.member_id,
        }
        this.intService.MembersExperienceDetails(meData).subscribe((result: any) => {
        
          this.memberExp =result.data[0];
          
         
          result.data.forEach((element) => {
            //this.experiencePrefId = element.EXPERIENCE_ID;
              this.experienceData.ids = element.EXPERIENCE_ID;
            this.experienceData.employer_name = element.EMPLOYER_NAME;
            
            this.experienceData.employer_phone_number = element.EMPLOYER_PHONE_NUMBER;
            this.experienceData.emp_work_site_address = element.EMP_WORK_SITE_ADDRESS;
            this.experienceData.experience_in_years = element.EXPERIENCE_IN_YEARS;
            this.experienceData.experience_in_months = element.EXPERIENCE_IN_MONTHS;  
            this.experienceData.project_name = element.PROJECT_NAME;
            this.experienceData.type_of_project = element.TYPE_OF_PROJECT;
            this.experienceData.team_size = element.TEAM_SIZE;
            this.experienceData.from_date = element.FROM_DATE;
            this.experienceData.to_date = element.TO_DATE;
            this.experienceData.paid_record = element.PAID_RECORD;
            this.experienceData.completion_certificate = element.COMPLETION_CERTIFICATE;
            this.experienceData.project_cost = element.PROJECT_COST;
            this.experienceData.looking_for_job = element.LOOKING_FOR_JOB;

            this.experience.push(this.createExpirenceFormGroups());

          });
      });
    }

   getStates() {
    this.csc.getALLStates().subscribe(result => {
      this.states = result;
      
    });
  }

  onChangeState(state,index) {
  
    this.csc.getALLDistrict(state).subscribe((data: any) => {
      this['d' + index] = data;
    });
  }

  onChangeDistrict(district,index) {
   
    this.csc.getALLTaluk(district).subscribe((data: any) => {
      this['t' + index] = data;
    });
  }

  submitForm () {
    let locInfo = JSON.stringify(this.locationForm.value.rows1);


   console.log(this.locationForm.value);
    let prealues = JSON.stringify(this.jobPreferenceForm.value);
    

   let jobPreData =   {
     "PRF_MEMBER_ID":this.member_id,
     "PRF_values":prealues,
     "LOCATION_INFO":locInfo,
     "PRF_STATUS":"Y",
     "PRF_DELETE_STATUS":0,
     "PRF_CREATED_BY":this.member_id
   }
   this.intService.saveJobDetailsPreference(jobPreData).subscribe((result: any) => {
      let message = result['data'].presult;
  

      let strJob = message.substring(0,3);
      if(strJob=='OK-') {
        alert("Job Preference Saved Successful");
        window.location.reload()
      }
      else {
        alert("Ooops, somthing went wrong!! Please try later");
      }
 
   
   });
 }

 submitMemberExpeienceForm () {
  let expInfo = JSON.stringify(this.experienceForm.value.rows);

  
  let expirenceData =   {
    "member_idfk":this.member_id,
   "Experience_INFO":expInfo,
    "delete_status":0,
    "created_by":this.member_id
  }
  this.intService.saveExpeienceDetails(expirenceData).subscribe((result: any) => {
    let message = result['data'].presult;
    let strJob = message.substring(0,3);
    if(strJob=='OK-') {
      alert("Member Experience Saved Successful");
      window.location.reload()
    }
    else {
      alert("Ooops, somthing went wrong!! Please try later");
    }

 
 });


 }

   
}
