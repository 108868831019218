import { Component, OnInit, Input } from '@angular/core';
import { InterestService } from 'src/app/services/interest.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-group-purchase-interest',
  templateUrl: './group-purchase-interest.component.html',
  styleUrls: ['./group-purchase-interest.component.scss']
})
export class GroupPurchaseInterestComponent implements OnInit {
  @Input('member_id') member_id: any;

  form: FormGroup;
   public puchaseCategories :any;
   public purchaseInterests: any;
   public purchaseUncheckedInt= [];
   public memberPurchaseData:any;

  
  // public member_id= this.globals.MemberID;

  constructor(public intService:InterestService,private formBuilder: FormBuilder,private globals: Globals) {

    this.form = this.formBuilder.group({
      checkArray: this.formBuilder.array([], [])
    })

   // this.addCheckboxes();
   }


  ngOnInit(): void {

   this.getGroupPurchaseCategores();
 
  //this.getSelected();

  }
  ngOnDestroy() {
    
  }



  getGroupPurchaseCategores() {
    this.intService.getGroupPurchaseCategores().subscribe(result => {
     // console.log("Result is:",result);
      this.puchaseCategories= result;
      this.getGroupPurchasePreferences();

      // console.log("Categories:",this.puchaseCategories);
    });

  }


  onCheckboxChange(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
   // const unCheckArray: FormArray = this.form.get('unCheckArray') as FormArray;
  
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
      
    } else {
      this.purchaseUncheckedInt.push(e.target.value);
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {     
          checkArray.removeAt(i);
          return;
        }
        i++;
      });


    }
  }


  //public purArray = [];
  submitForm() {
  
    this.purchaseInterests =this.form.value.checkArray;
   /// this.purchaseUncheckedInt =this.form.value.unCheckArray;
    //console.log(this.member_id);
   

  let mid= this.member_id;
  let  pdata = {
    "MEMBER_ID":mid,
    "CREATED_BY":mid,
    "PRODUCT_TYPE_ID_ARY":this.purchaseInterests,
    "UNCHECKED_ARY":this.purchaseUncheckedInt,
  
  }


    this.intService.saveGpurchaseInterest(pdata).subscribe(result => {
     let message = result['data'].presult;
     let strFirstThree = message.substring(0,3);
     if(strFirstThree=='OK-') {
       alert("Preference updated");
     }
     else {
       alert("Ooops, somthing went wrong!! Please try later");
     }

    });

  }

  

  getGroupPurchasePreferences() {
    this.intService.getGroupPurchasePreferences(this.member_id).subscribe(result => {
     // console.log("member preferences are:",result);
      this.memberPurchaseData = result;
      this.getSelected();
    });
  }


  getSelected() {
    

    for(let i=0;i<this.memberPurchaseData.length; i++){
      for(let j=0;j<this.puchaseCategories.length;j++){
        if(this.puchaseCategories[j].product_type_id === this.memberPurchaseData[i].gpp_product_type_id) {
          this.puchaseCategories[j].checked = true;
          //console.log("Matched: "+this.memberPurchaseData[i].gpp_product_type_id);

        }
      }
    }
  }
  


}
