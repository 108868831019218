import {Injectable, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class Globals  implements OnInit {
  access_token = this.cookieService.get('access');
  Name = this.cookieService.get('Name');
  PartnerID = this.cookieService.get('PartnerID');
  MemberID = this.cookieService.get('MemberID');
  enable = this.cookieService.get('enable');
  mob = this.cookieService.get('mob');
  UserImg = this.cookieService.get('UserImg');
  isSponsor = this.cookieService.get('isSponsor')
  orgAdmin = this.cookieService.get('orgAdmin');
  MemID = this.cookieService.get('MemID');
  Icons = this.cookieService.get('Icon');
  
  constructor(private cookieService: CookieService){ }
  
  ngOnInit() {
    if(this.cookieService.get('access') != null && this.cookieService.get('access') != undefined && this.cookieService.get('access') != ''){
      //console.log(this.userInfo);
      //this.access_token = this.cookieService.get('access');
    }
  }
}
