import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-education-history-popup',
  templateUrl: './education-history-popup.component.html',
  styleUrls: ['./education-history-popup.component.scss']
})
export class EducationHistoryPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  public member_id=this.data.memberid;
  public dependent_id=this.data.dependent_id;
  ngOnInit(): void {
  }

}
