import { Component, OnInit } from '@angular/core';
import { Settings } from 'src/app/app.settings';
import { CookieService } from 'ngx-cookie-service';
import { DashboardService } from 'src/app/admin/ApiServices/dashboard.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { MastersService } from 'src/app/services/masters.service';
import { MemberService } from 'src/app/admin/ApiServices/member.service';
import { AppSettings } from 'src/app/app.settings';
import { CscService } from 'src/app/services/csc.service';
import { ReferralService } from 'src/app/services/referral.service';
import * as XLSX from 'xlsx'; 
// declare var $: any;
// window['$'] = window['jQuery'] = $
@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.scss']
})
export class PaymentReportComponent implements OnInit {

  searchData = {
    from_date: '',
    to_date:'',
  }

  public showTable : boolean = false;
  public today1  : any;
public thirtyDays  : any;
searchForm : FormGroup;

  public orgId =  this.cookieService.get("DefaultOrganizationID");
 
  public settings:Settings;
  public orgList:any = [];
  DefaultOrganizationID: string;


  MemerID = this.cookieService.get('MemID');

  SelectedOrganizationName :any;
  public paymentDetails: any;

  public iconsMember = this.gobals.Icons;
  constructor(
    private cookieService: CookieService,
    private dash :DashboardService,
    public formBuilder: FormBuilder,
    private gobals: Globals,
    private ms:MastersService,
    private memberService : MemberService,
    public appSettings:AppSettings,
    private csc: CscService,
    private rfl: ReferralService,


  ) { }
  

  ngOnInit(): void {


    // let table = $('#excel-table').DataTable({
    //   drawCallback: () => {
    //     $('.paginate_button.next').on('click', () => {
    //         this.nextButtonClickEvent();
    //       });
    //   }
    // });

    this.searchForm = this.formBuilder.group({
      from_date: '',
      to_date: '',   
    });
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var oldmm = String(today.getMonth()).padStart(2, '0'); //January is 0!
    this.today1 = yyyy + '-' + mm + '-' + dd;
    this.thirtyDays = yyyy + '-' + oldmm + '-' + dd;
    this.searchData.from_date = this.thirtyDays;
    this.searchData.to_date = this.today1;
    this.GetOrganizationLinked();
    this.getPaymentDetails();
    this.organisationChange(this.orgId,this.SelectedOrganizationName);

  }

  GetOrganizationLinked(){
    const formData = new FormData();
    formData.append('member_id',this.MemerID);
    this.dash.OrganizationLinked(formData).subscribe((result: any) => {
    this.orgList = result;

    this.SelectedOrganizationName = this.orgList[0].ORGANIZATION_NAME; 
  
  });

  }
  getPaymentDetails () {
    const formData = new FormData();

    formData.append('member_id',this.MemerID);
    formData.append('org_id',this.orgId);
    formData.append('from_date',this.searchData.from_date);
    formData.append('to_date',this.searchData.to_date);
    this.dash.getPaymentDetails(formData).subscribe((result: any) => {
    this.paymentDetails = result;
   // console.log("Payment details:",this.paymentDetails);
    })
  }


  show_occupation () {
    if(this.showTable){
      this.showTable = false;
    }
    else{
      this.showTable = true;

    }
  }

   /*name of the excel-file which will be downloaded. */ 
 fileName= 'Payment_details.xlsx';  

 exportexcel(): void 
     {
        /* table id is passed over here */   
        let element = document.getElementById('excel-table'); 
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
        /* save to file */
        XLSX.writeFile(wb, this.fileName);
       
     }

     getDateForm () {
      var d1 = Date.parse(this.searchData.from_date);
      var d2 = Date.parse(this.searchData.to_date);

      if((d1) && (d1 <d2) ) {

      const formData = new FormData();
      formData.append('member_id',this.MemerID);
      formData.append('org_id',this.orgId);
      formData.append('from_date',this.searchData.from_date);
      formData.append('to_date',this.searchData.to_date);

    
      this.dash.getPaymentDetails(formData).subscribe((result: any) => {
      this.paymentDetails = result;
     // console.log("On date Call:",result);
   //   debugger;
    });

  }
  else{
    alert("Please give valid date");
  }
    }

    public organisation: any;
    organisationChange(organisationId,OrganisationName){
  
      this.organisation=organisationId;
      this.SelectedOrganizationName = OrganisationName;
  
    }


    public ngDoCheck() {   

      // console.log(this.organisation);
      // this.orgId = this.organisation;
       if(this.organisation != null || this.organisation != undefined || this.organisation != ''){        
       if (this.orgId !=  this.organisation ){
         
         this.orgId = this.organisation;
         this.getPaymentDetails();
       }
     }
     
     }

  // buttonInRowClick(event: any): void {
  //   event.stopPropagation();
  //   //console.log('Button in the row clicked.');
  // }

  // wholeRowClick(): void {
  //   //console.log('Whole row clicked.');
  // }

  // nextButtonClickEvent(): void {
  //   //do next particular records like  101 - 200 rows.
  //   //we are calling to api

  //  // console.log('next clicked')
  // }
  // previousButtonClickEvent(): void {
  //   //do previous particular the records like  0 - 100 rows.
  //   //we are calling to API
  // }

}
