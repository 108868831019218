import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { OrganisationsService } from 'src/app/services/organisations.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplyserviceService } from 'src/app/services/applyservice.service';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  ServicesList: [];

  @Input('Member_idTemp') Member_idTemp: any;
  @Input('member_id') member_id: any;

  constructor(
    public orgservices: OrganisationsService,
    public dialog: MatDialog,
    private myservices: ApplyserviceService
  ) {

  }

  ngOnInit(): void {

    this.getMyOrgsAndServices();
    this.MyAppliedServices();
  }

  public OrgList: any;

  public getMyOrgsAndServices() {

    const formData = new FormData();
    formData.append('Icon_member_id',this.member_id);
    this.orgservices.getMyOrgsAndServicesIconSupport(formData).subscribe((result: any) => {
      this.OrgList = result;
    });
  }


  MyAppliedServices() {

    const formData = new FormData();
    formData.append('Icon_member_id',this.member_id);
    
    this.myservices.MyAppliedServicesIconMemberShow(formData).subscribe((result: any) => {
      this.ServicesList = result.data;

    });

  }

  openDialog(orgservice) {
    // const dialogRef = this.dialog.open(MyservicesComponentServiceDialog);

    // let dialogRef = this.dialog.open(MyservicesComponentServiceDialog, {
    //   data:orgservice,
    //   panelClass: 'product-dialog',
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   //console.log(`Dialog result: ${result}`);
    // });
  }


  public notActive(): void {
    alert("Your joining process is not completed!!!. Please contact our support team.");
  }
}

// //Guna 
// @Component({
//   selector: 'app-myservices',
//   templateUrl: './view_service_details.component.html',
//   styleUrls: ['./myservices.component.scss']
// })
// export class MyservicesComponentServiceDialog implements OnInit {

//   constructor (
//     public dialogRef: MatDialogRef<MyservicesComponentServiceDialog>,
//     @Inject(MAT_DIALOG_DATA) public data: any
//   ) {
//     this.serviceDetails = data;
//   }

//   serviceDetails:any;

//   //on 10th july
//   public country: string;
//   public state: string;
//   public district: string;
//   public taluk: string;
//   public area: string;
//   public pincode: any;
//   public age: any;
//   public community: any;
//   public category: any;
//   public ration_card_type: any;
//   public occupation_group: any;
//   public occupation: any;
//   public income: any;

//   public Gender:string;


//   ngOnInit(): void {
//    //console.log(this.serviceDetails);
//    this.country = this.serviceDetails.AOO_Country;
//    this.state = this.serviceDetails.AOO_State;
//    this.district = this.serviceDetails.AOO_District;
//    this.taluk = this.serviceDetails.AOO_Taluk;
//    this.area = this.serviceDetails.AOO_area;
//    this.pincode = this.serviceDetails.AOO_pincode;
//    this.age = this.serviceDetails.age;
//    this.community = this.serviceDetails.community_name;
//    this.ration_card_type = this.serviceDetails.ration_card_type;
//    this.category = this.serviceDetails.category_name;
//    this.occupation_group = this.serviceDetails.member_group;
//    this.occupation = this.serviceDetails.occupation_name;
//    this.income = this.serviceDetails.income;
//    this.Gender = this.serviceDetails.Gender;
//   }

//   public close(): void {
//     this.dialogRef.close();
//   }


// }
