import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-otp-verify-icon',
  templateUrl: './otp-verify-icon.component.html',
  styleUrls: ['./otp-verify-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OtpVerifyIconComponent implements OnInit {

  constructor(       private UserService: UserService, public formBuilder: FormBuilder, public dialogRef: MatDialogRef<OtpVerifyIconComponent>,   @Inject(MAT_DIALOG_DATA) public data: any) { 
  }


  otp = {}
  otpstatus = {}
  fromPage: any;
  otpForm: FormGroup;

  ngOnInit(): void {
    this.otpForm = this.formBuilder.group({
      'otp': ['', Validators.compose([Validators.required, Validators.minLength(4)])],
    });

   this.dialogRef.close('Verified');
  }

  public otpFormSumbit(otp: Object): void {

    otp['phoneNumber'] = this.data;

    this.UserService.VerifyOTP(otp).subscribe((otpstatus: any) => {

      if (otpstatus.type == 'success' || otpstatus.type == 'Mobile no. already verified') {
        this.dialogRef.close('Verified');
      }
      
      else {
        this.dialogRef.close('NotVerified');    
      }
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

}
