import { Component, OnInit, Input } from '@angular/core';
import { InterestService } from 'src/app/services/interest.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { MastersService } from 'src/app/services/masters.service';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings, Settings } from 'src/app/app.settings';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-education-interest',
  templateUrl: './education-interest.component.html',
  styleUrls: ['./education-interest.component.scss']
})
export class EducationInterestComponent implements OnInit {
  @Input('member_id') member_id: any;
  @Input('dependent_id') dependent_id: any;
  educationForm: FormGroup;
  educationPrefefrence: FormGroup;
  public educationCategories: any;
  public educationInterests: any;
  public educationInfo: any;
  public getMemType: any = 0;
  public settings: Settings;
  public isLoggedIn = this.globals.enable;
  public memberEducationPrefData: any;
  public educationUncheckedInt = [];
  moreEducationList: {};


  [key: string]: {};




  public logged_lomember_id = this.globals.MemberID;
  education: FormArray;

  educationData = {
    id: '',
    QUALIFICATION: '',
    CLASS_GRADE: '',
    SYLLABUS: '',
    SCORE: '',
    PASSING_YEAR: '',
    INSTITUTE_NAME: '',
  };

  constructor(public intService: InterestService, private formBuilder: FormBuilder, private globals: Globals, private masterlist: MastersService, public dialog: MatDialog, public appSettings: AppSettings, public snackBar: MatSnackBar,
  ) {
    this.settings = this.appSettings.settings;
    this.educationPrefefrence = this.formBuilder.group({
      checkArray: this.formBuilder.array([], [])
    })
  }



  public tempi = 0;

  ngOnInit(): void {
    this.getMemberType();

    this.educationForm = this.formBuilder.group({
      id: '',
      QUALIFICATION: [''],
      CLASS_GRADE: [''],
      SYLLABUS: [''],
      SCORE: [''],
      PASSING_YEAR: [''],
      INSTITUTE_NAME: [''],

    });
    

    this.education = this.formBuilder.array([]);
    this.educationForm.addControl('rows', this.education);


    this.getEducationHistory();
    this.getEducationCategores();
    this.EducationList();
    

  }


  yearOfPass = [
    { id: 'current', name: 'Currently perceiving' },
    { id: '2000', name: '2000' },
    { id: '2001', name: '2001' },
    { id: '2002', name: '2002' },
    { id: '2003', name: '2003' },
    { id: '2004', name: '2004' },
    { id: '2005', name: '2005' },
    { id: '2006', name: '2006' },
    { id: '2007', name: '2007' },
    { id: '2008', name: '2008' },
    { id: '2009', name: '2009' },
    { id: '2010', name: '2010' },
    { id: '2011', name: '2011' },
    { id: '2012', name: '2012' },
    { id: '2013', name: '2013' },
    { id: '2014', name: '2014' },
    { id: '2015', name: '2015' },
    { id: '2016', name: '2016' },
    { id: '2017', name: '2017' },
    { id: '2018', name: '2018' },
    { id: '2019', name: '2019' },
    { id: '2020', name: '2020' },
    { id: '2021', name: '2021' },

  ];



  getMemberType() {
    this.masterlist.getMemberType(this.member_id).subscribe((result: any) => {
      // this.getMemType = result.data;
     
      if (result.data.length != 0) {
        this.getMemType = result.data[0].memberTypeDesc;
       
      }
    });

  }

  EducationList() {
    this.masterlist.EducationList().subscribe((result: any) => {

      this.educationInfo = result.data;
    });
  }


  getEducationCategores() {
    this.intService.getEducationCategores().subscribe(result => {
      //console.log("Result is:",result);
      this.educationCategories = result;

      this.getMemEducationPreferences();
    });

  }
  getMemEducationPreferences() {

    let meData = {
      "member_id":this.member_id,
      "dependent_id":this.dependent_id
    }
    
    this.intService.getMemEducationPreferences(meData).subscribe(result => {
      this.memberEducationPrefData = result;
      this.getSelected();
    });
  }
  getSelected() {
    for (let i = 0; i < this.memberEducationPrefData.length; i++) {
      for (let j = 0; j < this.educationCategories.length; j++) {
        if (this.educationCategories[j].eim_id === this.memberEducationPrefData[i].EDF_INTEREST_TYPE_ID) {
          this.educationCategories[j].checked = true;

        }
      }
    }
  }

  temp: any;



  onChangeEducation(educationId, index) {
    this.intService.getEducationMoreinfo(educationId).subscribe((result: any) => {
      this['a' + index] = result;
     // this.getSelected();
    });
  }




  onCheckboxChange(e) {
    const checkArray: FormArray = this.educationPrefefrence.get('checkArray') as FormArray;
    // const unCheckArray: FormArray = this.form.get('unCheckArray') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));

    } else {
      this.educationUncheckedInt.push(e.target.value);
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });


    }
  }


  prefSubmitForm() {


    this.educationInterests = this.educationPrefefrence.value.checkArray;
    /// this.purchaseUncheckedInt =this.form.value.unCheckArray;

    let mid = this.member_id;
    let pdata = {
      "EDF_MEMBER_ID": mid,
      "EDF_DEPENDANT_ID": this.dependent_id,
      "CREATED_BY": this.logged_lomember_id,
      "EDUCATION_TYPE_ID_ARY": this.educationInterests,
      "UNCHECKED_ARY": this.educationUncheckedInt,
      "UNCHECKED_ARY_LENGTH": this.educationUncheckedInt.length,
      "CHECKED_ARY_LENGTH": this.educationInterests.length,
    }


    this.intService.saveEducationPrefInterest(pdata).subscribe(result => {
      let message = result['data'].presult;
      let strFirstThree = message.substring(0, 3);
      if (strFirstThree == 'OK-') {
        alert("Preference updated");
      }
      else {
        alert("Ooops, somthing went wrong!! Please try later");
      }

    });
  }

  eduSubmitForm(eduFormdta: FormData) {
    const eduJsonOject = JSON.stringify(eduFormdta);
    let mid = this.member_id;
    let pdata = {
      "MEMBER_ID": mid,
      "DEPENDANT_ID":this.dependent_id,
      "CREATED_BY": this.logged_lomember_id,
      "EDUCATION_HISTORY_ARY": eduJsonOject,

    }

    this.intService.saveEducationHistory(pdata).subscribe(result => {
      let message = result['data'].presult;
      let strFirstThree = message.substring(0, 3);
      if (strFirstThree == 'OK-') {
        alert("Preference updated");
      }
      else {
        alert("Ooops, somthing went wrong!! Please try later");
      }

    });


  }

  getEducationHistory() {
    // this.intService.getEducationHistory(this.member_id).subscribe(result => {
    //   this.moreEducationList = result;
    //   this.getSelected();
    // });

    let meData = {
      "member_id":this.member_id,
      "dependent_id":this.dependent_id
    }
    
    
        this.intService.getEducationHistory(meData).subscribe((result: any) => {
          // console.log(result)
    
       
    
          if (result != '') {
    
            let kk = 0;
            result.eduresult.forEach((element) => {
    
              this.educationData.id = element.EPR_PREFERENCE_ID;
              this.educationData.QUALIFICATION = element.EPR_EDUCTION_LEVEL;
    
    
    
              // this['a'+kk];
              // this.intService.getEducationMoreinfo(this.educationData.QUALIFICATION).subscribe((result: any) => {
              //   this['a'+kk] = result;
              // //  alert(this['a'+kk]);
              // });
           
            if(this.tempi == 0){
    
              
              this.intService.getEducationMoreinfo(this.educationData.QUALIFICATION).subscribe((result: any) => {
                this['a'+0] = result;
                
              });
            }
                 
            if(this.tempi == 1){
              this.intService.getEducationMoreinfo(this.educationData.QUALIFICATION).subscribe((result: any) => {
                this['a'+1] = result;
                
              });
            }
    
            if(this.tempi == 2){
              this.intService.getEducationMoreinfo(this.educationData.QUALIFICATION).subscribe((result: any) => {
                this['a'+2] = result;
                
              });
            }
            if(this.tempi == 3){
              this.intService.getEducationMoreinfo(this.educationData.QUALIFICATION).subscribe((result: any) => {
                this['a'+3] = result;
                
              });
            }
            if(this.tempi == 4){
              this.intService.getEducationMoreinfo(this.educationData.QUALIFICATION).subscribe((result: any) => {
                this['a'+4] = result;
                
              });
            }
            if(this.tempi == 5){
              this.intService.getEducationMoreinfo(this.educationData.QUALIFICATION).subscribe((result: any) => {
                this['a'+5] = result;
                
              });
            }
            if(this.tempi == 6){
              this.intService.getEducationMoreinfo(this.educationData.QUALIFICATION).subscribe((result: any) => {
                this['a'+6] = result;
                
              });
            }
            if(this.tempi == 7){
              this.intService.getEducationMoreinfo(this.educationData.QUALIFICATION).subscribe((result: any) => {
                this['a'+7] = result;
                
              });
            }
            if(this.tempi == 8){
              this.intService.getEducationMoreinfo(this.educationData.QUALIFICATION).subscribe((result: any) => {
                this['a'+8] = result;
                
              });
            }
            if(this.tempi == 9){
              this.intService.getEducationMoreinfo(this.educationData.QUALIFICATION).subscribe((result: any) => {
                this['a'+9] = result;
                
              });
            }
         
         
    
              this.educationData.CLASS_GRADE = element.EPR_CLASS;
             
              this.educationData.SYLLABUS = element.EPR_SYLLABUS;
              this.educationData.SCORE = element.EPR_SCORE;
              this.educationData.PASSING_YEAR = element.EPR_YEAR_OF_PAASING;
              this.educationData.INSTITUTE_NAME = element.EPR_INSTITUTE_NAME;
              this.education.push(this.createItemFormGroup());
    
              this.tempi++;
              kk++;
    
            });
    
          }
    
        });



  }





  onAddRow() {

    if(this.education.length>=10){
      alert('You can enter maximum 10 rows of history only');
      return false;
    }

    this.educationData.id = '';
    this.educationData.QUALIFICATION = '';
    this.educationData.CLASS_GRADE = '';
    this.educationData.SYLLABUS = '';
    this.educationData.SCORE = '';
    this.educationData.PASSING_YEAR = '';
    this.educationData.INSTITUTE_NAME = '';

    this.education.push(this.createItemFormGroup());

  }


  onRemoveRow(rowIndex: number, dependentID) {
    if (dependentID.value == '') {
      this.education.removeAt(rowIndex);
      // this.ReferralService.GetReferalMemberDetailsDelete(dependentID.value).subscribe((result: any) => {
      // });
    }



  }

  createItemFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: this.educationData.id,
      QUALIFICATION: this.educationData.QUALIFICATION,
      CLASS_GRADE: [this.educationData.CLASS_GRADE, Validators.required],
      SYLLABUS: [this.educationData.SYLLABUS, Validators.required],
      SCORE: [this.educationData.SCORE, Validators.compose([Validators.minLength(1), Validators.maxLength(3)])],
      // aadhar: this.DependentsEdit.aadhar,
      PASSING_YEAR: this.educationData.PASSING_YEAR,
      INSTITUTE_NAME: this.educationData.INSTITUTE_NAME,
    });
  }
  


  


}

