import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Injectable({
  providedIn: "root",
})
export class NetworkService {
  public currentConfig: DomainConfig;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };
  postOptions = {
    headers: new HttpHeaders({
      // 'Host': this.currentConfig.dev_url_prefix + '/',
      // 'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };
  deleteOptions = {
    headers: new HttpHeaders({
      //  'Host': '/',
      //'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  /**
  Get Phapa Networks Organization List
  * @param null -  
  * @returns {Observable<any>}
  */
  getPhapaNetworks() {
    return this.http
      .get(this.currentConfig.dev_url_prefix + "Api/v1/GeneralApi/PhapaNetworkList")
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  /**
  Get Organization Detials
  * @param null -  
  * @returns {Observable<any>}
  */
  GetOrganizationDetials(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/OrganizationDetails/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  /**
  Get Services list and Details
  * @param null -  
  * @returns {Observable<any>}
  */
  GetOrganizationServices(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/OrganizationServices/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  /**
  Get Patners and Details
  * @param null -  
  * @returns {Observable<any>}
  */
  GetOrganizationParners(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/OrganizationPartners/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  /**
  Get Patners and Details
  * @param null -  
  * @returns {Observable<any>}
  */
  //  OrganizationComminiteeMembers(id){
  //   return this.http.get(this.currentConfig.dev_url_prefix+'Api/v1/GeneralApi/OrganizationCommunitteMembers/'+id).pipe(map(result => {
  //     return result;
  //   }));

  // }

  OrganizationComminiteeMembers(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/OrganizationCommunitteMembers/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  /**
  Get Phapa Primary  Organization List
  * @param null -  
  * @returns {Observable<any>}
  */
  getPhapaPrimaryOrganization() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/PhapaPrimaryOrganization"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  /**
  Get Phapa Primary  Organization List
  * @param null -  
  * @returns {Observable<any>}
  */

  NetworkUnderPrograms(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/ProgramsNetworkListProgramsList/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  /**
  Get Phapa Primary  Organization List
  * @param null -  
  * @returns {Observable<any>}
  */

  GetAllPrograms() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/ALLProgramsNetworkListProgramsList/"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  GetRelatedPrograms() {
    return this.http
      .get(this.currentConfig.dev_url_prefix + "Api/v1/GeneralApi/RelatedPrograms")
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  //on 28th
  PhapaNetworkListBasedonCondition() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/GeneralApi/PhapaNetworkListBasedonCondition"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //------- PRAVEEN ------- 23-01-2021----------------------------
  GetAllSponsorablePrograms() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/GeneralApi/ALLSponsorablePrograms/"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
