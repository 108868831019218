import { Injectable, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";
import { Globals } from "../globals";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Injectable({
  providedIn: "root",
})
export class IconService {
  public currentConfig: DomainConfig;

  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };
  postOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  deleteOptions = {
    headers: new HttpHeaders({
      //  'Host': '/',
      //'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  GetOptions = {
    headers: new HttpHeaders({
      Accept: "application/x-www-form-urlencoded",
      "User-ID": this.globals.MemberID,
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  getPrimaryOrganizationList(data) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/GetPrimaryOrgListDetails",
        data,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  changePrimaryOrganization(data) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/PrimaryOrganizationchange",
        data,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
