import { Injectable, EventEmitter } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Globals } from "../globals";
import { Subscription } from "rxjs/internal/Subscription";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Injectable({
  providedIn: "root",
})
export class OrganisationsService {
  public currentConfig: DomainConfig;
  invokeJoinFunction = new EventEmitter();
  subsVar: Subscription;

  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
      "User-ID": this.globals.MemberID,
    }),
  };
  postOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  GetAllOrganisationsList() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/AllOrganizationList/GetAllOrganizationsList/"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //6th May

  GetAllOrganisationCategories() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/AllOrganizationList/GetAllOrganisationCategories/"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //7th may

  GetJoinedOrganisations() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/OrganizationListjoin/listOrganizationGroupwiselist",
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  GetJoinedPrograms() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/OrganizationListjoin/OrganizationPrograms",
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  GetIconMemberJoinedPrograms(data) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/OrganizationListjoin/IconsOrganizationPrograms",
        data,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  //on 10th May
  joinProgram(id) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/ReferProgramJoinAnotherMember",
        id,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  IconHelpsjoinProgram(data) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/ReferProgramJoinAnotherMemberIconSupport",
        data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  IconHelpsSendOTP(data) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/ReferProgramJoinAnotherMemberIconSupportOTP",
        data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //11th may
  checkJoindorNot(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/OrganizationJoin/checkJoindOrNot/" +
          id,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  IconcheckJoindorNot(data) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/OrganizationJoin/checkJoindOrNotIconCheck",
        data,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //13th May

  getOrganisationEligibilies(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Organization/OrganisationEligibilities/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //19th june
  GetProgramSponsors(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/sponsorList/getProgramSponsors/" +
          id
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //27th june '2020
  public GetNetworkOrganisationsList() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/NetworkOrganisations/listNetworkOrganizations",
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //on 1st july '2020
  GetAllOrganisationGroups() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/AllOrganizationList/GetAllOrganisationGroups/"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getMyOrgsAndServices() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/EligibilityServices/MemberOrganisationServices/",
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getMyOrgsAndServicesIconSupport(data) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/EligibilityServices/MemberOrganisationServicesIConHelpServiceApplay",
        data,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  GetAllActiveOrganizations(page) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/OrganizationListjoin/listOfActiveOrganizationGroupwiselist",
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  PrimaryOrgDetails() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/Organization/PrimaryOrganization",
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  SetPrimaryOrganization(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Organization/PrimaryOrganizationSet",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
