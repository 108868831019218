import { Injectable, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { Globals } from "./../../globals";
import { AppConfigService, DomainConfig } from "src/app/app-config.service";

@Injectable({
  providedIn: "root",
})
export class MemberService {
  currentConfig: DomainConfig;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  postOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };
  postOptions1 = {
    headers: new HttpHeaders({
      Accept: "application/json",
      // 'User-ID' : this.globals.MemberID,
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  deleteOptions = {
    headers: new HttpHeaders({
      //  'Host': '/',
      //'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  // GetOptions={
  //   headers: new HttpHeaders({
  //     'Accept': 'application/x-www-form-urlencoded',
  //     'User-ID' : this.globals.MemberID,
  //     'Content-Type':'application/x-www-form-urlencoded',
  //     'Client-Service':'frontend-client',
  //     'Auth-key':'simplerestapi',
  //   })
  // }
  GetOptions = {
    headers: new HttpHeaders({
      Accept: "application/x-www-form-urlencoded",
      "User-ID": this.globals.MemberID,
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  /**
    Get ALL Members 
    
   * @param formData - post data
   * @returns {Observable<any>}
   */

  ALLMembers(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/AllOrganizationMembers",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  ApproveMembers(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/AllOrganizationMembersApprove",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  BulkApprovalMembers(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/AllOrganizationMembersApproveOneshot",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  ALLCommityMembers(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/AllOrganizationCommityMembers",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  /**
   Organization Contact Person 
   * @param formData - post data
   * @returns {Observable<any>}
   */

  OrganizationMemberDependents(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/OrganizationMembersDependents",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  // get member job details
  OrganizationMembersJobDetails(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Org_Admin_cntrl/OrganizationMembersJobDetails",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // OrgMembersExpiranceDetails(formData){
  //   return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/Products_and_services_cntrl/OrgMembersExperienceDetails', formData, this.postOptions1).pipe(map(result => {
  //     return result;
  //   }));
  // }

  OrgMembersExpiranceDetails(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Member_profile_cntrl/MemberExpeirenceDetails/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
