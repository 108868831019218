import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { Globals } from "../globals";
import { map } from "rxjs/internal/operators/map";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  public currentConfig: DomainConfig;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      //'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
      "User-ID": this.globals.MemberID,
    }),
  };

  savepaymentResponse(pres) {
    // console.log("Inside Service");
    // console.log(pres);
    let form_data = new FormData();
    for (let key in pres) {
      form_data.append(key, pres[key]);
    }

    //console.log(form_data);
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/PaymentInsert/paymentSucces/",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //on 8th Oct '2020 guna

  saveOrderPayment(pres) {
    // console.log("Inside Service");
    // console.log(pres);
    let form_data = new FormData();
    for (let key in pres) {
      form_data.append(key, pres[key]);
    }
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/PaymentInsert/saveOrderPayment/",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  saveServicePaymentResponse(saveResponse) {
    let form_data = new FormData();
    for (let key in saveResponse) {
      form_data.append(key, saveResponse[key]);
    }
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/RazorPayment/Service_Payment/",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // savepaymentFalureResponse (pres){
  //   console.log("Inside fauile Service");
  //   console.log(pres);
  //   return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/PaymentInsert/paymentFailure/',pres,this.httpOptions).pipe(map(result => {
  //     return result;
  //   }));
  // }
}
