import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AppConfigService, DomainConfig } from "src/app/app-config.service";

@Component({
  selector: "app-news-category-list",
  templateUrl: "./news-category-list.component.html",
  styleUrls: ["./news-category-list.component.scss"],
})
export class NewsCategoryListComponent {
  @Input() categories;
  @Input() categoryParentId;
  @Output() change: EventEmitter<any> = new EventEmitter();
  mainCategories;

  public currentConfig: DomainConfig;
  public URLpath;

  constructor(private appConfigService: AppConfigService) {
    this.currentConfig = this.appConfigService.currentConfig;
    this.URLpath = this.currentConfig.dev_url_prefix;
  }

  public ngDoCheck() {
    if (this.categories && !this.mainCategories) {
      this.mainCategories = this.categories;
    }
  }

  public stopClickPropagate(event: any) {
    if (window.innerWidth < 960) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public changeCategory(event, id, name) {
    this.change.emit({ event, id, name });
  }
}
