import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { NewLandingComponent } from './new-landing/new-landing.component';
//import { SignUpComponent } from './sign-up/sign-up.component';
import { NewAboutComponent } from './new-about/new-about.component';
export const routes: Routes = [
    {
        path: 'phapa',

        component: PagesComponent, children: [
            { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'programs', loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsModule), data: { breadcrumb: 'Programs' } },
            { path: 'programs/:Pincode', loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsModule), data: { breadcrumb: 'Programs' } },
            { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)},
            { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule), data: { breadcrumb: 'Compare' } },
            { path: 'wishlist', loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule), data: { breadcrumb: 'Wishlist' } },
            { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Cart' } },
            { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' } },
            { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contact' } },
            
            { path: 'brands', loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), data: { breadcrumb: 'Brands' } },
            { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), data: { breadcrumb: 'All Programs' } },
            { path: 'Organisations', loadChildren: () => import('./pages/myorganisations/myorganisations.module').then(m => m.MyorganisationsModule), data: { breadcrumb: 'Organisations' } },
           // { path: 'Organisations', loadChildren: () => import('./pages/organisations/organisations.module').then(m => m.OrganisationsModule), data: { breadcrumb: 'Organisations' } },

            { path: 'registration', loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule), data: { breadcrumb: 'Member Registartion' }},
            { path: 'programDetails', loadChildren: () => import('./pages/program-details/program-details.module').then(m => m.ProgramDetailsModule), data: { breadcrumb: 'Program Details' } },
            { path: 'sponsor', loadChildren: () => import('./pages/sponsor/sponsor.module').then(m => m.SponsorModule), data: { breadcrumb: 'Sponsor Creation' }},
            { path: 'SerApply', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), data: { breadcrumb: 'Service Apply' } }, 
            { path: 'News', loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule), data: { breadcrumb: 'News' } }, 
                       
        ],
        
    },

    {path: '', component: NewLandingComponent, data: { breadcrumb: 'Landing' }},  
    // {path: '', component: LandingpageComponent, data: { breadcrumb: 'Landing' }},  
    { path: 'sign-in', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'Sign In ' } },
    { path: 'sign-in/:OrganizationID', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'Sign In ' } },
    // {path: 'sign-up', component: SignUpComponent, data: { breadcrumb: 'Landing' }},
   { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
    { path: 'sign-up', loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpModule), data: { breadcrumb: 'Sign Up' } },
    { path: 'sign-up/:OrganizationID', loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpModule), data: { breadcrumb: 'Sign Up' } },
    // { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'About Us ' } },
    {path:'about', component: NewAboutComponent, data: { breadcrumb: 'About Us' }},
    { path: 'privacy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacypolicyModule), data: { breadcrumb: 'Privacy And Policy ' } },

    { path: '**', component: NotFoundComponent},
];



@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            initialNavigation: 'enabled', // for one load page, without reload
            relativeLinkResolution: 'legacy',
            // useHash: true 
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }