import { Injectable, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { Globals } from "../globals";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { throwError } from "rxjs";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Injectable({
  providedIn: "root",
})
export class ReferralService {
  public currentConfig: DomainConfig;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };
  postOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };
  postOptions1 = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  deleteOptions = {
    headers: new HttpHeaders({
      //  'Host': '/',
      //'Authorization': 'Bearer ' + this.globals.access_token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  GetOptions = {
    headers: new HttpHeaders({
      Accept: "application/x-www-form-urlencoded",
      "User-ID": this.globals.MemberID,
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  /**
   Referral Member
   * @param formData - post data
   * @returns {Observable<any>}
   */

  ReferralMember(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/Referral/ReferralNew",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  /**
  My Referral List List
   * @param formData - post data
   * @returns {Observable<any>}
   */

  ReferralMyList(page, formdata) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/Referral/MyReferralList/" + page,
        formdata,
        this.postOptions1
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  OccupationGroup() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/List_cntrl/MemberGroupMaster",
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getOccupationList(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/List_cntrl/occupationMasterListMatched/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getOccupationSubList(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/List_cntrl/occupationSubTypeList/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getCategoryMasterList() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/List_cntrl/CategoryMasterList",
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getMaritalMasterList() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/List_cntrl/MeritalStatus",
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  ReferalMemberProfileUpdate(basic, id) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/MyReferralMemberProfileUpdate/" +
          id,
        basic,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  ReferalMemberProfileUpdateEligbility(eligibility, id) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/MyReferralMemberProfileUpdateEligibility/" +
          id,
        eligibility,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  ReferalMemberDependentsUpdate(Dependents, id) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/MyReferralMemberFamilyDependents/" +
          id,
        Dependents,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  GetReferalMemberDetails(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/ReferalMemberDetails/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  GetReferalMemberDetailsDelete(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/MyReferralMemberFamilyDeactive/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  DeleteInsurenceMember(Data) {
    return this.http
      .post<any>(
        this.currentConfig.dev_url_prefix + "Api/v1/Referral/InsurenceDelete/",
        Data
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  GetReferalMemberDocuments(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/ReferalMemberDocuments/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  ReferalMemberInsurenceUpdate(data, id) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/InsurenceDetailsPost/" +
          id,
        data,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  ReferProgramJoinAnotherMember(data) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/ReferProgramJoinAnotherMember",
        data,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  upload(formData) {
    return this.http
      .post<any>(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/MyReferralMemberDocumentsUpload/123",
        formData,
        {
          reportProgress: true,
          observe: "events",
        }
      )
      .pipe(
        map((event) => this.getEventMessage(event, formData)),
        catchError(this.handleError)
      );
  }

  private getEventMessage(event: HttpEvent<any>, formData) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
        break;
      case HttpEventType.Response:
        return this.apiResponse(event);
        break;
      default:
        return `File "${
          formData.get("AdharDocument").name
        }" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round((100 * event.loaded) / event.total);
    return { status: "progress", message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened. Please try again later.");
  }

  public ReferalMemberBankUpdate(bank, id) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/MyReferralMemberBankUpdate/" +
          id,
        bank,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // ---------- PRAVEEN ---- 12-01-2021 ------ START-------------
  GetMemberDependentsDetails(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/getMemberDependentsDetails/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  MemberDependentsUpdate(Dependents, id) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/Interests/MemberDependents/" + id,
        Dependents,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  MemberSocialSecurityUpdate(eligibility, id) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/MemberSocialSecurityUpdate/" +
          id,
        eligibility,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  // ---------- PRAVEEN ---- 12-01-2021 ------ END-------------

  // ---------- PRAVEEN ---- 12-01-2021 ------ START-------------
  // GetMemberDependentsDetails(id){
  //   return this.http.get(this.currentConfig.dev_url_prefix+'Api/v1/Referral/getMemberDependentsDetails/'+id, this.GetOptions).pipe(map(result => {
  //     return result;
  //   }));
  // }

  covidDetailsForm(covidCareDetails, id) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/Interests/covidCareDetails/" + id,
        covidCareDetails,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  covidCareForm(covidCare, id) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/Interests/covidCare/" + id,
        covidCare,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  covidVaccinationForm(covidVaccination, id) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix + "Api/v1/Interests/covidVaccination/" + id,
        covidVaccination,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  getMemberCovidInfo(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix + "Api/v1/Referral/getMemberCovidInfo/" + id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  GetMemberCovidDetails(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/getMemberCovidDetails/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  getMemberCovidVacccinationInfo(id) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Referral/getMemberCovidVacccinationInfo/" +
          id,
        this.GetOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  // ---------- PRAVEEN ---- 12-01-2021 ------ END-------------
}
