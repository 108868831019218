import { Injectable ,OnInit} from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { AppConfigService, DomainConfig } from '../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService  {
  currentConfig:DomainConfig;
  constructor(private http: HttpClient, private appConfigService:AppConfigService ) {
    this.currentConfig=this.appConfigService.currentConfig;
   }
  
  globals = {
    access_token : 'GroupOneSeniorToken',
  }
  
  
  httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded',
      'Client-Service':'frontend-client',
      'Auth-key':'simplerestapi',
    })
  };
  postOptions={
    headers: new HttpHeaders({
      'Accept': 'application/json',
     // 'Content-Type':'application/x-www-form-urlencoded',
      'Client-Service':'frontend-client',
      'Auth-key':'simplerestapi',
    })
  };
  
  deleteOptions = {
    headers: new HttpHeaders({
      //  'Host': '/',
      //'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };

  loginOptions={
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded',
      'Client-Service':'frontend-client',
      'Auth-key':'simplerestapi',
    })
  }

 /**
   Login user
   * @param formData - post data
   * @returns {Observable<any>}
   */

  loginUser(formData){
    return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/auth/Generalsignin', formData, this.loginOptions).pipe(map(token => {
      return token;
    }));
  }

   /**
   Sign Up user
   * @param formData - post data
   * @returns {Observable<any>}
   */

  sigUpUser(formData){
    return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/auth/signup', formData, this.loginOptions).pipe(map(token => {
      return token;
    }));
  }

    /**
   Generat OTP
   * @param formData - post data
   * @returns {Observable<any>}
   */

  GenerateOTP(formData){
    return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/auth/generateOtp', formData, this.loginOptions).pipe(map(token => {
      return token;
    }));
  }

      /**
   Verify OTP
   * @param formData - post data
   * @returns {Observable<any>}
   */

  VerifyOTP(formData){
    return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/auth/OtpVerify', formData, this.loginOptions).pipe(map(otp => {
      return otp;
    }));
  }

  VerifyUSer(MobileNumber){

    return this.http.get(this.currentConfig.dev_url_prefix+'Api/v1/Forgot/CheckMobileNumberExitorNot/'+MobileNumber, this.loginOptions).pipe(map(result => {
      return result;
    }));

  }

  ChangePassword(data){

    return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/Forgot/ChangePassword/',data, this.loginOptions).pipe(map(result => {
      return result;
    }));

  }

  GetOrganizationDetails(data){
    return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/GeneralApi/GetOrganizationDetails',data,this.postOptions).pipe(map(result => {
      return result;
    }));
  }

}
