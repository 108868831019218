import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Globals } from "../globals";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Injectable({
  providedIn: "root",
})
export class InterestService {
  currentConfig: DomainConfig;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private globals: Globals
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
      // 'data':'form_data',
      "User-ID": this.globals.MemberID,
    }),
  };
  postOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "User-ID": this.globals.MemberID,
      // 'Content-Type':'application/x-www-form-urlencoded',
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  getGroupPurchaseCategores() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/purchaseCategories"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  saveGpurchaseInterest(pdata) {
    let form_data = new FormData();
    for (let key in pdata) {
      form_data.append(key, pdata[key]);
    }

    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/saveGpurchaseInterest",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getGroupPurchasePreferences(memid) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/getGroupPurchasePreferences/" +
          memid
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getAllGroupServiceTypes() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/getAllGroupServiceTypes"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  saveGServiceInterest(pdata) {
    let form_data = new FormData();
    for (let key in pdata) {
      form_data.append(key, pdata[key]);
    }

    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/saveGServiceInterest",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getGroupServicePreferences(memid) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/getGroupServicePreferences/" +
          memid
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // ----------- PRAVEEN -- 07-01-2021 --------
  getHealthcareCatogories() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/healthcareCategories"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  saveHealthcareInterest(pdata) {
    let form_data = new FormData();
    for (let key in pdata) {
      form_data.append(key, pdata[key]);
    }

    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/saveHealthcareInterest",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  getHealthcarePreferences(memid) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/getHealthcarePreferences/" +
          memid
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  //on
  getIncomeMaster() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/getIncomeMasterData"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  saveHousingPreference(housingData) {
    let form_data = new FormData();
    for (let key in housingData) {
      form_data.append(key, housingData[key]);
    }
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/saveAffordableHousingInterest",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getAHPreferences(memid) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/getAHPreferences/" +
          memid
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getEducationCategores() {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/educationCategories"
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // post job details data
  saveJobDetailsPreference(jobData) {
    let form_data = new FormData();
    for (let key in jobData) {
      form_data.append(key, jobData[key]);
    }
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/saveJobPreferenceData",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  // post member experience data
  saveExpeienceDetails(expData) {
    let form_data = new FormData();
    for (let key in expData) {
      form_data.append(key, expData[key]);
    }
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/saveExperiencePreferenceData",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  saveEducationPrefInterest(pdata) {
    let form_data = new FormData();
    for (let key in pdata) {
      form_data.append(key, pdata[key]);
    }

    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/saveEducationPrefInterest",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getMemEducationPreferences(memData) {
    let form_data = new FormData();
    for (let key in memData) {
      form_data.append(key, memData[key]);
    }
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/getMemEducationPreferences",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getEducationMoreinfo(eduId) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/getMemEducationMoreInfo/" +
          eduId
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  saveEducationHistory(pdata) {
    let form_data = new FormData();
    for (let key in pdata) {
      form_data.append(key, pdata[key]);
    }

    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/postEducationInfo",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getEducationHistory(memData) {
    let form_data = new FormData();
    for (let key in memData) {
      form_data.append(key, memData[key]);
    }

    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/getEducationHistory",
        form_data,
        this.httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getMemberStudentDependents(memid) {
    return this.http
      .get(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Interests/getMemberStudentDependentsList/" +
          memid
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  // get job details
  // get member job details
  // OrganizationMembersJobDetails(formData){
  //   let form_data = new FormData();
  //   for ( let key in formData ) {
  //     form_data.append(key, formData[key]);
  //   }
  //   return this.http.post(this.currentConfig.dev_url_prefix+'Api/v1/Org_Admin_cntrl/OrganizationMembersJobDetails',form_data,this.httpOptions).pipe(map(result => {
  //     return result;
  //   }));
  // }

  /**
   Organization Contact Person 
   * @param formData - post data
   * @returns {Observable<any>}
   */
  // get member job details
  MembersJobDetails(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Products_and_services_cntrl/MembersJobDetails",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
  MembersExperienceDetails(formData) {
    return this.http
      .post(
        this.currentConfig.dev_url_prefix +
          "Api/v1/Products_and_services_cntrl/MembersExperienceDetails",
        formData,
        this.postOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
