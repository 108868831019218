import { Component, Inject, OnInit } from "@angular/core";
import { Data, AppService } from "../../../app.service";
import { Settings, AppSettings } from "../../../app.settings";
import { CscService } from "../../../services/csc.service";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Globals } from "src/app/globals";
import { ProductsAndServicesService } from "src/app/services/products-and-services.service";
import { AppConfigService, DomainConfig } from "src/app/app-config.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-top-menu",
  templateUrl: "./top-menu.component.html",
  providers: [CscService],
})
export class TopMenuComponent implements OnInit {
  public currentConfig: DomainConfig;
  public currencies = ["USD", "EUR"];
  public currency: any;
  public flags = [
    { name: "English", image: "assets/images/flags/gb.svg" },
    { name: "German", image: "assets/images/flags/de.svg" },
    { name: "French", image: "assets/images/flags/fr.svg" },
    { name: "Russian", image: "assets/images/flags/ru.svg" },
    { name: "Turkish", image: "assets/images/flags/tr.svg" },
  ];
  public flag: any;
  public profilepic: any;
  public TotalNotifications: {};
  public allnotifications: {};
  public isLoggedIn = this.globals.enable;
  public member_id = this.globals.MemberID;
  public URLpath;

  public settings: Settings;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appConfigService: AppConfigService,
    public appSettings: AppSettings,
    public appService: AppService,
    private router: Router,
    private csc: CscService,
    private cookieService: CookieService,
    private globals: Globals,
    public proserv: ProductsAndServicesService
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
    this.URLpath = this.currentConfig.dev_url_prefix;
    this.settings = this.appSettings.settings;
  }
  public UserName: string;

  isLogged: any = this.cookieService.get("enable");

  ngOnInit() {
    this.getMemberProfilePic();
    this.getMemberNoticationCount();
    this.getMemberAllNotifications();
    //this.getWishlistData();

    this.currency = this.currencies[0];
    this.flag = this.flags[0];
    this.UserName = this.cookieService.get("Name");
  }

  public changeCurrency(currency) {
    this.currency = currency;
  }

  public changeLang(flag) {
    this.flag = flag;
  }
  getMemberProfilePic() {
    this.csc.getAllMemberProfilePic().subscribe((result: any) => {
      this.profilepic = result.profile.filePath;

      this.cookieService.set("UserImg", this.profilepic);
    });
  }
  // get total member notification count
  getMemberNoticationCount() {
    this.csc.getAllMemberAllNotificationCount().subscribe((result: any) => {
      this.TotalNotifications = result.TotalNotifications;
    });
  }
  //  get notification subject
  //  get all notifications
  getMemberAllNotifications() {
    this.csc.getAllMemberAllNotifications().subscribe((result: any) => {
      this.allnotifications = result.allnotifications;
    });
  }

  Logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll(); ///////////
    //this.cookieService.deleteAll( '/ ',  '/' );
    //this.cookieService.delete('TESTCOOKIE', '/');
    this.cookieService.delete("enable");
    this.cookieService.delete("Name");
    this.cookieService.delete("PartnerID");
    this.cookieService.delete("MemberID");
    this.cookieService.delete("MemID");
    this.cookieService.delete("mob");
    this.cookieService.delete("UserImg");
    this.cookieService.delete("Icon");
    this.UserName = "";
    this.isLogged = "";
    this.cookieService.deleteAll("/phapa");
    this.cookieService.deleteAll("/phapa/admin");
    this.cookieService.deleteAll("/");
    // this.router.navigateByUrl("");
    // this.router.navigate(["/"]);
    this.document.location.href = this.currentConfig.landingUrl;
  }

  //on 18th August
  // wishListData:any;
  //   getWishlistData (){
  //     this.proserv.getWishListData(this.member_id).subscribe((result:any )=> {
  //       this.wishListData = result.data;
  //       this.proserv.Data.wishList=this.wishListData
  //      });
  //   }
}
