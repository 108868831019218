import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ReferralService } from 'src/app/services/referral.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-otp-dialog',
  templateUrl: './otp-dialog.component.html',
  styleUrls: ['./otp-dialog.component.scss'],
  providers: [UserService,ReferralService],
  encapsulation: ViewEncapsulation.None
})
export class OtpDialogComponent implements OnInit {

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<OtpDialogComponent>,
    private UserService: UserService,
    private ReferralService: ReferralService,
    public snackBar: MatSnackBar,
    public router: Router,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.fromPage = data;
  }



  otp = {}
  otpstatus = {}
  fromPage: any;
  otpForm: FormGroup;

  ngOnInit(): void {
    this.otpForm = this.formBuilder.group({
      'otp': ['', Validators.compose([Validators.required, Validators.minLength(4)])],
    });

     this.dialogRef.close('Verified');
  }

  public otpFormSumbit(otp: Object): void {

    otp['phoneNumber'] = this.fromPage.phone.phoneNumber;
    
    this.UserService.VerifyOTP(otp).subscribe((otpstatus: any) => {

      if (otpstatus.type == 'success' || otpstatus.type == 'Mobile no. already verified') {
        this.dialogRef.close('Verified');
      }
      
      else {
        this.dialogRef.close('NotVerified');    
      }
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

}
