import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { InterestService } from 'src/app/services/interest.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, FormGroupDirective  } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { MastersService } from 'src/app/services/masters.service';
import { UserService } from '../../../services/user.service';
import { ReferralService } from '../../../services/referral.service';
import { CscService } from 'src/app/services/csc.service';
import {CookieService} from 'ngx-cookie-service';
import { forkJoin } from 'rxjs';
import { NgForm } from "@angular/forms";



@Component({
  selector: 'app-covid-network',
  templateUrl: './covid-network.component.html',
  styleUrls: ['./covid-network.component.scss']
})
export class CovidNetworkComponent implements OnInit {
  
  @Input('member_id') member_id: any;
  @Input('MDmember_ID') member_ID:any;

  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  comorbidities = new FormControl();
  comorbiditiesList = [
    {
      name: 'Daibetes',
      id: 1,
    },
    {
      name: 'Hypertension',
      id: 2,
    },
    {
      name: 'Cardiovascular',
      id: 3,
    },
    {
      name: 'Obesity',
      id: 4,
    },
  ];
  id_type = [
    {
      name: 'Aadhar Card',
      id: 1,
    },
    {
      name: 'Driving License',
      id: 2,
    },
    {
      name: 'PAN Card',
      id: 3,
    },
    {
      name: 'Passport',
      id: 4,
    },
    {
      name: 'Pension Passbook',
      id: 5,
    },
    {
      name: 'NPR Smart Card',
      id: 6,
    },
    {
      name: 'Voter ID',
      id: 7,
    },
  ];


  covidVaccinationForm: FormGroup;
  covidVaccination = {
    mem_id : '',
    vaccination_phase : '',
    phase_1_date: '',
    phase_2_date: '',
    id_type: <number> null,
    id : '',
    gender: '',
    dob: '',
    comments : '',
  };

  hide_date_1 = false;
  hide_date_2 = false;
  public minDate = new Date();
  public minEndDate = new Date();


  covidCareForm: FormGroup;
  covidInfo = {
    id: '',
    mem_id : '',
    age : '',
    height: '',
    weight: '',
    srf_id : '',
    lg_id : '',
    init_symptoms: '',
    init_oxy_sat: '',
    init_temp: '',
    comorbidities: '',
    present_medic: '',
    test_date: '',
    ct_name: '',
    ct_number: '',
    covid_status: '',
    primary_doc: '',
    doc_notes: '',
    icon_id: '',
    gender : '',
  };


  covidDetailsForm: FormGroup;
  covidDetailsArray : FormArray;
  covidDetailsEdit = {
    id: null,
    sl_no: '',
    date_time :'', 
    oxygen_saturation : '',
    symptoms : '',
    temperature :'',
    status : '',
    recommendation : '',
    doc_comments : '',
  };


  constructor(
    private formBuilder: FormBuilder,
    private globals: Globals,
    private ms:MastersService,
    private csc: CscService,
    private cookieService: CookieService,
    private rfl: ReferralService,

  ) { }

  ngOnInit(): void {

    this.covidCareForm = this.formBuilder.group({
      'id': '',
      'mem_id' : '',
      'age': '',
      'height': '',
      'weight': '',
      'srf_id' : '',
      'lg_id': '',
      'init_symptoms': '',
      'init_oxy_sat': '',
      'init_temp': '',
      'comorbidities': this.comorbidities,
      'present_medic': '',
      'test_date': '',
      'ct_name': '',
      'ct_number': '',
      'covid_status': '',
      'primary_doc': '',
      'doc_notes': '',
      'icon_id': '',
      'gender' : '',
    });

    this.covidVaccinationForm = this.formBuilder.group({
      'mem_id' : '',
      'vaccination_phase': '',
      'phase_1_date': '',
      'phase_2_date': [{value: null, disabled: true }],
      'id_type': '',
      'id' : '',
      'gender': '',
      'dob': '',
      'comments' : '',

    });

    // Covid Details --------------------------- START----------

  this.covidDetailsForm = this.formBuilder.group({
    id: '',
    // name: ['', Validators.required],
    sl_no: '',
    date_time :'', 
    oxygen_saturation : '',
    symptoms : '',
    temperature :'',
    status : '',
    recommendation : '',
    doc_comments : '',
  
  });

  this.covidDetailsArray = this.formBuilder.array([]);
  this.covidDetailsForm.addControl('rows', this.covidDetailsArray);

  this.rfl.getMemberCovidInfo(this.member_id).subscribe((result: any) => {
    console.log(result)

    if (result.covidInfo != 'No Covid Info'){
      result.covidInfo.forEach((element) => 
      {
      this.covidInfo.id = element.id;
      this.covidInfo.mem_id = element.mem_id;
      this.covidInfo.age = element.age;
      this.covidInfo.height = element.height;
      this.covidInfo.weight = element.weight;
      this.covidInfo.srf_id = element.srf_id;
      this.covidInfo.lg_id = element.local_govt_id;   
      this.covidInfo.init_symptoms = element.initial_symptoms;
      this.covidInfo.init_oxy_sat = element.initial_oxygen_saturation;
      this.covidInfo.init_temp = element.init_temp;
      this.covidInfo.comorbidities = element.comorbidities; 
      this.covidInfo.present_medic = element.present_medication; 

      let date = element.test_date.substr(0,10);
      this.covidInfo.test_date = date
      
      this.covidInfo.ct_name = element.cartaker_name; 
      this.covidInfo.ct_number = element.cartaker_number;    
      this.covidInfo.covid_status = element.covid_status; 
      this.covidInfo.primary_doc = element.primary_doctor;
      this.covidInfo.doc_notes = element.doctors_note;
      this.covidInfo.icon_id = element.covid_care_icon;
      this.covidInfo.gender = element.gender;
            
    });
    
    let comorb_array_ids = this.covidInfo.comorbidities.split(',')
    let intArray = comorb_array_ids.map(function (x) { 
      return parseInt(x, 10); 
    });
       
    this.comorbidities.setValue(intArray);
    }
  })

  this.rfl.getMemberCovidVacccinationInfo(this.member_id).subscribe((result: any) => {
    console.log(result)

    if (result.covidVaccineInfo != 'No Covid Info'){
      this.hide_date_1 = true;
      this.hide_date_2 = true;
      result.covidVaccineInfo.forEach((element) => 
      {   
      this.covidVaccination.mem_id = element.mem_id; 
      this.covidVaccination.vaccination_phase = element.vaccination_phase;
      let t = parseInt(element.id_type, 10) ;
      this.covidVaccination.id_type  = t;
      this.covidVaccination.id = element.id;
      this.covidVaccination.gender = element.gender; 
      this.covidVaccination.comments = element.comments;

      let date = element.dob.substr(0,10);
      this.covidVaccination.dob = date;
      
      if(element.phase_1_date != ''){
        let date = element.phase_1_date.substr(0,10);
        this.covidVaccination.phase_1_date = date;
      }
      else{
        this.covidVaccinationForm.controls.phase_1_date.enable();
      }
     
      if(element.phase_2_date != ''){
        let date= element.phase_2_date.substr(0,10);
        this.covidVaccination.phase_2_date = date;   
      }
      else{
        this.covidVaccinationForm.controls.phase_1_date.enable();
      }

      
      if(this.covidVaccination.vaccination_phase == '1')
    {
        this.covidVaccinationForm.controls.phase_1_date.disable();
    }
    if(this.covidVaccination.vaccination_phase == '2')
    {
        this.covidVaccinationForm.controls.phase_1_date.disable();
        this.covidVaccinationForm.controls.phase_2_date.enable();
    }

    });
       
    }
  })


  this.rfl.GetMemberCovidDetails(this.member_id).subscribe((result: any) => {
    console.log(result)

    if(result.covidDetails != "No Covid Details"){

    result.covidDetails.forEach((element) => 
    {
      this.covidDetailsEdit.id = element.id;
      this.covidDetailsEdit.sl_no = element.sl_no;

      let date = element.date_time.substr(0,10);
      this.covidDetailsEdit.date_time = date

      this.covidDetailsEdit.oxygen_saturation = element.oxygen_saturation;
      this.covidDetailsEdit.symptoms = element.symptoms;
      this.covidDetailsEdit.temperature = element.temperature;
      this.covidDetailsEdit.status = element.status;   
      this.covidDetailsEdit.recommendation = element.recommendation;
      this.covidDetailsEdit.doc_comments = element.doctor_comments;

      this.covidDetailsArray.push(this.createItemFormGroup());
    });
  }

  });

// DEPENDENTS --------------------------- END  -----------

  }




  onAddRow() 
  {
    if(this.covidDetailsArray.length>=10){
      alert('You can enter maximum 10 rows of history only');
      return false;
    }

    this.covidDetailsEdit.id =  '',
    this.covidDetailsEdit.sl_no =  '',
    this.covidDetailsEdit.date_time  = '', 
    this.covidDetailsEdit.oxygen_saturation  =  '',
    this.covidDetailsEdit.symptoms  =  '',
    this.covidDetailsEdit.temperature  = '',
    this.covidDetailsEdit.status  =  '',
    this.covidDetailsEdit.recommendation  =  '',
    this.covidDetailsEdit.doc_comments  =  '',

    this.covidDetailsArray.push(this.createItemFormGroup());
  }

  createItemFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: this.covidDetailsEdit.id,
      sl_no: [this.covidDetailsEdit.sl_no],
      date_time: [this.covidDetailsEdit.date_time],
      oxygen_saturation: [this.covidDetailsEdit.oxygen_saturation,],
      symptoms: this.covidDetailsEdit.symptoms,
      temperature: this.covidDetailsEdit.temperature,
      status: this.covidDetailsEdit.status,
      recommendation: this.covidDetailsEdit.recommendation,
      doc_comments: this.covidDetailsEdit.doc_comments,
    });
  }
 
  onRemoveRow(rowIndex: number, dependentID) {
    // console.log(dependentID)
    if (dependentID.value == '') {
      this.covidDetailsArray.removeAt(rowIndex);
      // this.ReferralService.GetReferalMemberDetailsDelete(dependentID.value).subscribe((result: any) => {
      // });
    }
  }

  covidCareSubmit(e, values: NgForm, formDirective: FormGroupDirective)
  {

      // console.log(this.covidDetailsForm.value.rows);
      // console.log(this.covidCareForm.value);
      // console.log(this.member_id);

      // return;

      // if (this.covidCareForm.valid) {
        forkJoin(
          this.rfl.covidDetailsForm(this.covidDetailsForm.value.rows, this.member_id),
          this.rfl.covidCareForm(this.covidCareForm.value, this.member_id),
        ).subscribe(([MDRes, MSSURes]) => {
          // console.log(MDRes)
          // console.log(MSSURes)
          window.location.reload();
        });
      // }  
  }

  covidVaccineSubmit(e, values: NgForm, formDirective: FormGroupDirective)
  {      
    console.log(this.covidVaccinationForm.value);
    // return;

        forkJoin(
          this.rfl.covidVaccinationForm(this.covidVaccinationForm.value, this.member_id)
        ).subscribe(([MDRes]) => {
          // console.log(MDRes)
          // console.log(MSSURes)
          window.location.reload();
        });
  }


  hide_show_date(value){
    console.log(value)
    if(value == 1)
    {
      this.hide_date_1 = true;
      this.hide_date_2 = false;
      this.covidVaccinationForm.get('phase_2_date').setValue(null);
      this.covidVaccinationForm.controls.phase_2_date.disable();

    }
    if(value == 2)
    {
      this.hide_date_1 = true;
      this.hide_date_2 = true;
    }
  }

  inputEvent(date){
    console.log(date)
    this.covidVaccinationForm.get('phase_2_date').setValue(null);
    this.covidVaccinationForm.controls.phase_2_date.disable();
    let first_vccn_date = new Date(date);

    this.minEndDate = new Date(Number(first_vccn_date))
    this.minEndDate.setDate(first_vccn_date.getDate() + 35)

    console.log(this.minEndDate);

    this.covidVaccinationForm.controls.phase_2_date.enable();
  }



}
