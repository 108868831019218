import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { AgmCoreModule } from "@agm/core";

import { OverlayContainer, Overlay } from "@angular/cdk/overlay";
import { MAT_MENU_SCROLL_STRATEGY } from "@angular/material/menu";
import { CustomOverlayContainer } from "./theme/utils/custom-overlay-container";
import { menuScrollStrategy } from "./theme/utils/scroll-strategy";

import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app.routing";

import { AppComponent } from "./app.component";
import { PagesComponent } from "./pages/pages.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { TopMenuComponent } from "./theme/components/top-menu/top-menu.component";
import { MenuComponent } from "./theme/components/menu/menu.component";
import { SidenavMenuComponent } from "./theme/components/sidenav-menu/sidenav-menu.component";
import { BreadcrumbComponent } from "./theme/components/breadcrumb/breadcrumb.component";
import { AppSettings } from "./app.settings";
import { AppService } from "./app.service";
import { AppInterceptor } from "./theme/utils/app-interceptor";
import { OptionsComponent } from "./theme/components/options/options.component";
import { FooterComponent } from "./theme/components/footer/footer.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { LandingpageComponent } from "./landingpage/landingpage.component";
import { MatChipsModule } from "@angular/material/chips";
// import {MatAutocompleteModule} from '@angular/material/autocomplete';
// import { NewsComponent } from './pages/news/news.component';

//import { SignUpComponent } from './sign-up/sign-up.component';

import { SwiperModule } from "ngx-swiper-wrapper";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { AngularMaterialModule } from "./material/material.module";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import * as $ from "jquery";
import { NewLandingComponent } from "./new-landing/new-landing.component";
import { NewAboutComponent } from "./new-about/new-about.component";
import { AppConfigService } from "./app-config.service";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBxqRaQQTfN1DO4AvLnS9XHfXNQ_gdklL0",
    }),
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    SwiperModule,
    AngularMaterialModule,
    // MatAutocompleteModule,
    MatChipsModule,
    MatCheckboxModule,
    MatButtonModule,
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    TopMenuComponent,
    MenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent,
    LandingpageComponent,
    NewLandingComponent,
    NewAboutComponent,
    // NewsComponent
  ],
  providers: [
    AppSettings,
    AppService,
    CookieService,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: menuScrollStrategy,
      deps: [Overlay],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return async () => {
          return await appConfigService.loadAppConfig();
        };
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
