import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { InterestService } from 'src/app/services/interest.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, FormGroupDirective  } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { MastersService } from 'src/app/services/masters.service';
import { UserService } from '../../../services/user.service';
import { ReferralService } from '../../../services/referral.service';
import { CscService } from 'src/app/services/csc.service';
import {CookieService} from 'ngx-cookie-service';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-social-security',
  templateUrl: './social-security.component.html',
  styleUrls: ['./social-security.component.scss']
})
export class SocialSecurityComponent implements OnInit {

  @Input('member_id') member_id: any;
  @Input('MDmember_ID') member_ID:any;
  //public member_id: any;

  socialSecurityForm: FormGroup;
  DependentForm: FormGroup;
  DependentFormArray : FormArray;
  post: any = '';
  DependentsEdit = {
    id: null,
    name: '',
    dob: '',
    gender: '',
    education: '',
    class_grade: '',
    relationship: '',
    memberType: '',
    maritalStatus: '',
    occupation:'',
  };
  relationdepents = [];
  educationInfo = [];
  gender = [];
  member_type = [];
  maritalStatus=[];

  public tempi = 0;
  public socialSecurityCategories :any;
  public socialSecurityAttributes: any;
  public socialSecurityUncheckedInt = [];
  public socialSecurityMemberData:any;

  constructor(
    public intService:InterestService,
    private formBuilder: FormBuilder,
    private globals: Globals,
    private ms:MastersService,
    private UserService: UserService,
    private rfl: ReferralService,
    private csc: CscService,
    private masterlist: MastersService,
    private cookieService: CookieService,
    private ReferralService: ReferralService,

  ) { }

  //public member_ID = this.cookieService .get('MemID');
  
  @ViewChild (FormGroupDirective) formDirective: FormGroupDirective;


socialSecurityArray = [
  { 
    id : 'OCCUPATION_GROUP_ID',
    OCCUPATION_GROUP_ID : [],
    name : 'MEMBER_GROUP',
    value : ''
  },
  { 
    id : 'MEMBER_OCCUPATION', 
    OCCUPATION_SECTOR : [], 
    name : 'OCCUPATION_NAME', 
    value : '' 
  },
  { 
    id : 'MEMTYPEIDFK', 
    MEMTYPEIDFK : [], 
    name : 'MEMBERTYPEDESC', 
    value : '' 
  },
  { 
    id : 'MEMBER_SEX', 
    MEMBER_SEX : [], 
    name : 'GENDER_NAME', 
    value : '' 
  },
  { 
    id : 'AGE_ID', 
    AGE_ID : [], 
    name : 'AGE', 
    value : '' 
  },
  { 
    id : 'MEMBER_INCOME_ID', 
    MEMBER_INCOME_ID : [], 
    name : 'INCOME_DESC', 
    value : '' 
  },
  { 
    id : 'MEMBER_CATEGORY_ID', 
    MEMBER_CATEGORY_ID : [],  
    name : 'CATEGORY_NAME', 
    value : ''
  },
  { 
    id : 'RATION_CARD_TYPE', 
    RATION_CARD_TYPE :[], 
    name : 'RATION_CARD_NAME', 
    value : ''
  },
  { 
    id : 'COMMUNITY_IDFK', 
    COMMUNITY_IDFK : [], 
    name : 'COMMUNITY_NAME', 
    value : '' 
  },
];


  ngOnInit(): void {  // ******************** NgOnInit **** START **************

    // console.log("member md id :" + this.member_ID)
    this.getMemGroup();
    this.getMemberTypes();
    this.getGender();
    // this.getAgeRange(); 
    this.getIncome();
    this.getCategory();
    this.getCommunity();
    this.getRationCardType();
    this.getDependentRelation();
    this.EducationList();
    
    this.socialSecurityForm = this.formBuilder.group({
      'member_group': '',
      'occupationType': '',
      'member_type': '',
      'income' : '',
      'community': '',
      'gender': '',
      'ageRange': '',
      'category': '',
      'rationCardType': '',
    });

    //this.member_id = this.globals.MemberID;
    

    // GET MEMBERS ATTRIBUTES
    this.getMemberAttributes();
    this.getMaritalStatusList();

// DEPENDENTS --------------------------- START----------

  this.DependentForm = this.formBuilder.group({
    id: '',
    name: ['', Validators.required],
    gender: ['', Validators.required],
    dob: '',
    //aadhar: ['', Validators.compose([Validators.minLength(12), Validators.maxLength(12)])],
    aadhar: '',
    education: '',
    class_grade:'',
    relationship: '',
    memberType : '',
    maritalStatus : '',
    occupation :'',
  
  });

  this.DependentFormArray = this.formBuilder.array([]);
  this.DependentForm.addControl('rows', this.DependentFormArray);

  this.rfl.GetMemberDependentsDetails(this.member_id).subscribe((result: any) => {
    // console.log(result)
    if(result.dependents.dependents != 'No Dependents'){
    result.dependents.forEach((element) => {
      this.DependentsEdit.id = element.dependent_id;
      this.DependentsEdit.name = element.dependent_name;
      this.DependentsEdit.dob = element.dependent_dob;
      this.DependentsEdit.gender = element.dependent_sex;
      this.DependentsEdit.education = element.dependent_education;
      this.DependentsEdit.relationship = element.dependent_relation;
      this.DependentsEdit.memberType = element.dependent_membertype;


       
      if(this.tempi == 0){
    
              
        this.intService.getEducationMoreinfo(this.DependentsEdit.education).subscribe((result: any) => {
          this['a'+0] = result;
          
        });
      }
           
      if(this.tempi == 1){
        this.intService.getEducationMoreinfo(this.DependentsEdit.education).subscribe((result: any) => {
          this['a'+1] = result;
          
        });
      }

      if(this.tempi == 2){
        this.intService.getEducationMoreinfo(this.DependentsEdit.education).subscribe((result: any) => {
          this['a'+2] = result;
          
        });
      }
      if(this.tempi == 3){
        this.intService.getEducationMoreinfo(this.DependentsEdit.education).subscribe((result: any) => {
          this['a'+3] = result;
          
        });
      }
      if(this.tempi == 4){
        this.intService.getEducationMoreinfo(this.DependentsEdit.education).subscribe((result: any) => {
          this['a'+4] = result;
          
        });
      }
      if(this.tempi == 5){
        this.intService.getEducationMoreinfo(this.DependentsEdit.education).subscribe((result: any) => {
          this['a'+5] = result;
          
        });
      }
      if(this.tempi == 6){
        this.intService.getEducationMoreinfo(this.DependentsEdit.education).subscribe((result: any) => {
          this['a'+6] = result;
          
        });
      }
      if(this.tempi == 7){
        this.intService.getEducationMoreinfo(this.DependentsEdit.education).subscribe((result: any) => {
          this['a'+7] = result;
          
        });
      }
      if(this.tempi == 8){
        this.intService.getEducationMoreinfo(this.DependentsEdit.education).subscribe((result: any) => {
          this['a'+8] = result;
          
        });
      }
      if(this.tempi == 9){
        this.intService.getEducationMoreinfo(this.DependentsEdit.education).subscribe((result: any) => {
          this['a'+9] = result;
          
        });
      }
   


      this.DependentsEdit.class_grade = element.dependent_education_info;
      this.DependentsEdit.occupation = element.dependent_occupation;

      this.DependentsEdit.maritalStatus = element.dependent_marital_status;

      this.DependentFormArray.push(this.createItemFormGroup());
      this.tempi++;
    });
    // console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
    // console.log(this.DependentFormArray)
  }
  // console.log(this.DependentForm.get('rows')['controls'])

  });

// DEPENDENTS --------------------------- END  -----------
  } // ********************* END OF NgOnInit ****************************************



  onChangeEducation(educationId, index) {
    this.intService.getEducationMoreinfo(educationId).subscribe((result: any) => {
      this['a' + index] = result;
     // this.getSelected();
    });
  }


  getMaritalStatusList() {

    this.ReferralService.getMaritalMasterList().subscribe((result: any) => {
      this.maritalStatus = result.data;
    });
  }

  public Submit() {
    if (this.DependentFormArray.valid) {
      // console.log(this.DependentFormArray.value);

      forkJoin(
        this.rfl.MemberDependentsUpdate(this.DependentFormArray.value, this.member_ID),
        this.rfl.MemberSocialSecurityUpdate(this.socialSecurityForm.value, this.member_ID)
      ).subscribe(([MDRes, MSSURes]) => {
        // console.log(MDRes)
        // console.log(MSSURes)
        alert("Updated");
        this.ngOnInit();
      });
    }
  }

  
  validateAllFormFields(formGroup: FormGroup) 
  {         
    Object.keys(formGroup.controls).
    forEach(field => 
      {  
        const control = formGroup.get(field);      
           
        if (control instanceof FormControl) 
        {            
          // console.log('Instance Form Control')
          // console.dir(control)    
          control.markAsTouched({ onlySelf: true });
        } 
        else if (control instanceof FormGroup) 
        {        
          // console.log('Instance Form Group')
          // console.log(control)    
          this.validateAllFormFields(control);       
        }
      }
    );
}

  onAddRow() {

    if(this.DependentFormArray.length>=10){
      alert('You can enter maximum 10 rows of history only');
      return false;
    }

    this.DependentsEdit.id = '';
    this.DependentsEdit.name = '';
    this.DependentsEdit.gender = '';
    this.DependentsEdit.dob = '';
    this.DependentsEdit.education = '';
    this.DependentsEdit.class_grade = '';
    this.DependentsEdit.relationship = '';
    this.DependentsEdit.maritalStatus = '';
    this.DependentsEdit.memberType = '';
    this.DependentsEdit.occupation = '';

    this.DependentFormArray.push(this.createItemFormGroup());
  }
  createItemFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: this.DependentsEdit.id,
      name: [this.DependentsEdit.name, Validators.required],
      gender: [this.DependentsEdit.gender, Validators.required],
      dob: [this.DependentsEdit.dob,],
      maritalStatus: this.DependentsEdit.maritalStatus,
      education: this.DependentsEdit.education,
      class_grade: this.DependentsEdit.class_grade,
      relationship: this.DependentsEdit.relationship,
      memberType: this.DependentsEdit.memberType,
      occupation: this.DependentsEdit.occupation,

    });
  }


  getDependentRelation() {
    this.csc.getAllRelationalDependents().subscribe((result: any) => {
      this.relationdepents = result;
    });
  }
  EducationList() {
    this.masterlist.EducationList().subscribe((result: any) => {

      this.educationInfo = result.data;

    });
  }
  
 
  onRemoveRow(rowIndex: number, dependentID) {
    // console.log(dependentID)
    if (dependentID.value == '') {
      this.DependentFormArray.removeAt(rowIndex);
      // this.ReferralService.GetReferalMemberDetailsDelete(dependentID.value).subscribe((result: any) => {
      // });
    }
  }
  
    // prevent special characters
    textFieldNumAndSpecialCharRestrictions(event) {
      //  var k;  
      //  k = event.charCode;  //         k = event.keyCode;  (Both can be used)
      //  return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
      var regExp = /[0-9-=+_;!@#$%^&*()~|}{}"'/,<>`\[\]]/i;
      var value = String.fromCharCode(event.which) || event.key;
      if (regExp.test(value)) {
        event.preventDefault();
        return false;
      }
    }
    numFieldSpecialCharRestrictions(event) {
      var regExp = /[a-z-=+_.;!@#$%^&*()~|}{}"'/,<>`\[\]\s]/i;
      var value = String.fromCharCode(event.which) || event.key;
      if (regExp.test(value)) {
        event.preventDefault();
        return false;
      }
    }
    
  getMemberAttributes(){
    this.socialSecurityArray.forEach((element, index, array) => {
      array[index].value = '';
      array[index][element.id] = [];
    })
    // console.log(this.socialSecurityArray)
    this.ms.getMemberAttributes().subscribe((result:any )=> {
      let obj = result.message[0];
      // console.log("=========================================")
      // console.log(result.message[0])
      Object.keys(obj).forEach(key => {
        this.socialSecurityArray.forEach((element, index, array) => {
          if(element.id == key)
          {
            if(obj[key] && obj[key].length != 0)
            {
              let unit = [{
                  id: obj[key],
                  name: obj[element.name],
                }];
              array[index].value = obj[key];
              array[index][element.id] = unit
            }
          }
        })
      })
      // console.log('**********************   %%%%   **********************')
      // console.log(this.socialSecurityArray)
    });
  }



  getScoialSecurityCatogories() {   // GET ALL SOCIAL SECURITY CATEGORIES
    this.ms.getScoialSecurityCatogories().subscribe(result => {
      this.socialSecurityCategories= result;
      // console.log("Scoial Security Ctgrs:",this.socialSecurityCategories);
      // this.getHealthcarePreferences();
    });
  }

  SponsorEligibilityFormSumbit(data, formDirective: FormGroupDirective): void {
    this.UserService.VerifyUSer(data['phoneNumber']).subscribe(result => {
      if (result['MemberStatus'] == 'Member Exited.') 
      {
        // console.log(data);
        // this.Search(data);
      }
      else
      {
        alert('something went wrong..!!');
      }
    });

  }


  // ------- PRAVEEN ----SOCIAL SECURITY MODULE--- 25-11-2020 ------ END ----------

   
  getMemGroup(){
    // this.csc.getALLMemberGroup()
    this.ms.getALLMemberGroup().subscribe((result:any )=> {
     // this.memgroup = result.data;
     this.socialSecurityArray[0]['OCCUPATION_GROUP_ID'] = result.data;
    //  console.log(result.data)
    });
  }
    // On change of occupation group ----------------------------------
  OccupationGroupID: any;

  ChangeoccupationGroup(orgGroup) {
    if (orgGroup.value) {
      this.OccupationGroupID = orgGroup.value;
      this.ms.getOccupationList(this.OccupationGroupID).subscribe(
        (data: any) => {
          this.socialSecurityArray[1]['MEMBER_OCCUPATION'] = data.data;
        }
      );
    }
  }
    // On change of occupation group ----------------------------------
  
  getMemberTypes(){
    this.ms.getAllMemberTypes().subscribe((result:any) => {
     this.socialSecurityArray[2]['MEMTYPEIDFK'] = result.data;
     this.member_type = result.data;
    //  console.log(this.member_type)
      // console.log('$#$%#$%#$%#$%#$%#$%#$%#$%#$%')
      // console.log(this.memtypes)
    });
  }
  getGender(){
    this.ms.getAllGender().subscribe((result:any) => { 
     this.socialSecurityArray[3]['MEMBER_SEX']= result;
     this.gender = result;

    });
  }
  getAgeRange(){
   this.ms.getAgeRange().subscribe((result:any) => {
    this.socialSecurityArray[4]['AGE_ID'] = result;
   });
  } 
  getIncome(){
    this.ms.getIncome().subscribe((result:any) => {
     this.socialSecurityArray[5]['MEMBER_INCOME_ID'] = result;
    });
   }
  getCategory(){
    this.ms.getCategory().subscribe((result:any) => {
     this.socialSecurityArray[6]['MEMBER_CATEGORY_ID'] = result;
    });
   }
  getRationCardType(){
   this.ms.getRationCardType().subscribe((result:any) => {
    this.socialSecurityArray[7]['RATION_CARD_TYPE'] = result;
   });
  }  
  getCommunity(){
    this.ms.getAllCommunity().subscribe((result:any) => { 
     this.socialSecurityArray[8]['COMMUNITY_IDFK']= result;
    });
   }

  // ------- PRAVEEN ----SOCIAL SECURITY MODULE--- 25-11-2020 ------ END ----------
   
}
