import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormGroupDirective,
} from "@angular/forms";

import { NgForm } from "@angular/forms";
import { AppConfigService, DomainConfig } from "src/app/app-config.service";
import { IconService } from "src/app/services/icon.service";

@Component({
  selector: "app-primary-org-select",
  templateUrl: "./primary-org-select.component.html",
  styleUrls: ["./primary-org-select.component.scss"],
})
export class PrimaryOrgSelectComponent implements OnInit {
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  @Input("Member_idTemp") Member_idTemp: any;

  public currentConfig: DomainConfig;
  public URLpath;
  public orgDetails: any;
  EligibleOrg: [];
  OrgHistory: any;
  constructor(
    private appConfigService: AppConfigService,
    public formBuilder: FormBuilder,
    private icon: IconService
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
    this.URLpath = this.currentConfig.dev_url_prefix;
  }

  registerForm: FormGroup;

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      org_id: ["", Validators.compose([Validators.required])],
      reason: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(30),
        ]),
      ],
      iconMember_id: [this.Member_idTemp],
    });

    this.getPrimaryOrgEligibleList();
  }

  getPrimaryOrgEligibleList() {
    const formData = new FormData();

    formData.append("user_id", this.Member_idTemp);

    this.icon.getPrimaryOrganizationList(formData).subscribe((result: any) => {
      this.orgDetails = result.primaryOrgDetails;
      this.EligibleOrg = result.EligiblePrimaryOrgList;
      this.OrgHistory = result.data;
    });
  }

  public onChangePrimarySubmit(e, values: NgForm) {
    this.icon.changePrimaryOrganization(values).subscribe((result) => {
      this.getPrimaryOrgEligibleList();
    });
    this.registerForm.reset();
    //   formDirective.reset();
  }
}
