import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";
import { AppConfigService, DomainConfig } from "../app-config.service";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  public currentConfig: DomainConfig;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.currentConfig = this.appConfigService.currentConfig;
  }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Client-Service": "frontend-client",
      "Auth-key": "simplerestapi",
    }),
  };

  /**
  Get Home Banners
  * @param null -  
  * @returns {Observable<any>}
  */
  GetSliders() {
    return this.http
      .get(this.currentConfig.dev_url_prefix + "Api/v1/GeneralApi/HomeSliders")
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
